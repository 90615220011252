

/* style */
/*---------------------------
 CSS Index  
****************************

1. variables
2. typography
3. spacing
4. reset
5. forms
6. mixins
7. shortcode
8. common
9. animations
10. text-animation
11. sal animation
12. header
13. nav
14. breadcrumb
15. mobile-menu
16. button
17. swiper
18. banner
19. about
20. service
21. cta
22. business-goal
23. counterup
24. gallery
25. project
26. trusted-client
27. team
28. feature
29. testimonial
30. pricing
31. blog
32. appoinment
33. faq
34. contact
35. map-area
36. team-details
37. working-process
38. back-totop
39. preloader
40. footer
----------------------------*/

/* Default  */
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&amp;display=swap");
/* @import "~@fortawesome/fontawesome-free/css/all.css"; */

:root {
  --color-primary: #015fc9;
  --color-primary-2: #0B4DF5;
  --color-primary-3: #015fc9;
  --color-primary-4: #5264F3;
  --color-primary-5: #015fc9;
  --color-primary-6: #015fc9;
  --color-primary-alta: #f2f5f9;
  --color-secondary: #111D5E;
  --color-terciary: #90FF03;
  --color-gray: #f6f6f6;
  --color-gray-2: #f5f8fa;
  --color-subtitle: #015fc9;
  --color-body: #5D666F;
  --color-heading-1: #1C2539;
  --background-color-1: #181C25;
  --color-white: #fff;
  --color-light-heading: #181c32;
  --color-light-body: #65676b;
  --color-border-white: #00000024;
  --color-success: #3EB75E;
  --color-danger: #015fc9;
  --color-warning: #015fc9;
  --color-info: #1BA2DB;
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #3B5997;
  --color-linkedin: #0077B5;
  --color-pinterest: #015fc9;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --s-light: 300;
  --s-regular: 400;
  --s-medium: 500;
  --s-semi-bold: 600;
  --s-bold: 700;
  --s-extra-bold: 800;
  --s-black: 900;
  --transition: 0.3s;
  --font-primary: "DM Sans", sans-serif;
  --font-secondary: "Red Hat Display", sans-serif;
  --font-size-b1: 16px;
  --font-size-b2: 18px;
  --font-size-b3: 22px;
  --line-height-b1: 26px;
  --line-height-b2: 30px;
  --line-height-b3: 1.7;
  --h1: 60px;
  --h2: 48px;
  --h3: 36px;
  --h4: 30px;
  --h5: 24px;
  --h6: 20px;
}

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-size: 10px;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: auto !important;
}

body {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "DM Sans", sans-serif;
  color: var(--color-body);
  font-weight: var(--p-regular);
  position: relative;
  overflow-x: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body {
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  body {
    overflow: hidden;
  } 
}

body::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.05;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
  margin: 0;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  word-break: break-word;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.4074;
  color: var(--color-heading-1);
}

h1,
.h1 {
  font-size: var(--h1);
  line-height: 91px;
  font-weight: 900;
}

h2,
.h2 {
  font-size: var(--h2) !important;
  line-height: 1.23 ;
}

h3,
.h3 {
  font-size: var(--h3);
  line-height: 54px;
}

h4,
.h4 {
  font-size: var(--h4);
  line-height: 1.25;
}

h5,
.h5 {
  font-size: var(--h5);
  line-height: 1.24;
}

h6,
.h6 {
  font-size: var(--h6);
  line-height: 1.25;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: inherit;
}

.bg-color-tertiary h1,
.bg-color-tertiary h2,
.bg-color-tertiary h3,
.bg-color-tertiary h4,
.bg-color-tertiary h5,
.bg-color-tertiary h6,
.bg-color-tertiary .h1,
.bg-color-tertiary .h2,
.bg-color-tertiary .h3,
.bg-color-tertiary .h4,
.bg-color-tertiary .h5,
.bg-color-tertiary .h6 {
  color: #fff;
}

.bg-color-tertiary p {
  color: #6c7279;
}

.bg-color-tertiary a {
  color: #6c7279;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  h1,
  .h1 {
    font-size: 38px;
  }

  h2,
  .h2 {
    font-size: 32px;
  }

  h3,
  .h3 {
    font-size: 28px;
  }

  h4,
  .h4 {
    font-size: 24px;
  }

  h5,
  .h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {

  h1,
  .h1 {
    font-size: 34px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  h5,
  .h5 {
    font-size: 20px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: var(--s-bold);
}

h4,
.h4,
h5,
.h5 {
  font-weight: var(--s-bold);
}

h6,
.h6 {
  font-weight: var(--s-bold);
}

p {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
  color: var(--color-body);
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  p {
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 28px;
  }
}

p.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

p.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

p.has-large-font-size {
  line-height: 1.5;
  font-size: 36px;
}

p.has-medium-font-size {
  font-size: 24px;
  line-height: 36px;
}

p.has-small-font-size {
  font-size: 13px;
}

p.has-very-light-gray-color {
  color: var(--color-white);
}

p.has-background {
  padding: 20px 30px;
}

p.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

p.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

p.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

p:last-child {
  margin-bottom: 0;
}

.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

.b4 {
  font-size: var(--font-size-b4);
  line-height: var(--line-height-b4);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 20px;
  width: 100%;
}

table a,
table a:link,
table a:visited {
  text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
  color: var(--color-heading);
}

var {
  font-family: "Red Hat Display", sans-serif;
}

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
  padding-left: 18px;
}

ul {
  list-style: square;
  margin-bottom: 30px;
  padding-left: 20px;
}

ul.liststyle.bullet li {
  font-size: 18px;
  line-height: 30px;
  color: var(--color-body);
  position: relative;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  ul.liststyle.bullet li {
    padding-left: 19px;
  }
}

ul.liststyle.bullet li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: var(--color-body);
  left: 0;
  top: 10px;
}

ul.liststyle.bullet li+li {
  margin-top: 8px;
}

ul li {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-body);
  list-style: none;
}

ul li a {
  text-decoration: none;
  color: var(--color-gray);
}

ul li a:hover {
  color: var(--color-primary);
}

ul ul {
  margin-bottom: 0;
}

ol {
  margin-bottom: 30px;
}

ol li {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  color: var(--color-body);
  margin-top: 10px;
  margin-bottom: 10px;
}

ol li a {
  color: var(--color-heading);
  text-decoration: none;
}

ol li a:hover {
  color: var(--color-primary);
}

ol ul {
  padding-left: 30px;
}

.typo-title-area .title {
  margin-top: 0;
}

.paragraph-area p.disc {
  margin-bottom: 20px;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  h1 {
    font-size: 64px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 54px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px !important;
    line-height: 56px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 30px !important;
    line-height: 45px;
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 28px !important;
    line-height: 36px;
  }
}

@media only screen and (max-width: 479px) {
  h1 {
    font-size: 26px !important;
    line-height: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  h2 {
    font-size: 54px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 44px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 36px !important;
    line-height: 56px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 30px !important;
    line-height: 45px;
  }
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 26px !important;
    line-height: 36px;
  }
}

@media only screen and (max-width: 479px) {
  h2 {
    font-size: 24px !important;
    line-height: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  h3 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3 {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 30px;
    line-height: 56px;
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 30px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 575px) {
  h3 {
    font-size: 24px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 479px) {
  h3 {
    font-size: 22px;
    line-height: 30px;
  }
}

.rts-typography-style .container .style {
  margin-bottom: 40px;
}

.rts-typography-style .container .style .title {
  margin-bottom: 30px;
}

.rts-typography-style .container .style p {
  font-size: 16px;
}

/*=========================
    Section Separation 
==========================*/
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.mb_dec--25 {
  margin-bottom: -25px;
}

.mb_dec--30 {
  margin-bottom: -30px;
}

.m--0 {
  margin: 0;
}

.rts-section-gap {
  padding: 120px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-section-gap {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .rts-section-gap {
    padding: 60px 0;
  }
}

.rts-section-gapBottom {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-section-gapBottom {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .rts-section-gapBottom {
    padding-bottom: 60px;
  }
}

.rts-section-gapTop {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-section-gapTop {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .rts-section-gapTop {
    padding-top: 60px;
  }
}

.pl--0 {
  padding-left: 0 !important;
}

.pr--0 {
  padding-right: 0 !important;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.mr--0 {
  margin-right: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.ptb--5 {
  padding: 5px 0 !important;
}

.plr--5 {
  padding: 0 5px !important;
}

.pt--5 {
  padding-top: 5px !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.pl--5 {
  padding-left: 5px !important;
}

.pr--5 {
  padding-right: 5px !important;
}

.mt--5 {
  margin-top: 5px !important;
}

.mb--5 {
  margin-bottom: 5px !important;
}

.mr--5 {
  margin-right: 5px !important;
}

.ml--5 {
  margin-left: 5px !important;
}

.ptb--10 {
  padding: 10px 0 !important;
}

.plr--10 {
  padding: 0 10px !important;
}

.pt--10 {
  padding-top: 10px !important;
}

.pb--10 {
  padding-bottom: 10px !important;
}

.pl--10 {
  padding-left: 10px !important;
}

.pr--10 {
  padding-right: 10px !important;
}

.mt--10 {
  margin-top: 10px !important;
}

.mb--10 {
  margin-bottom: 10px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.ml--10 {
  margin-left: 10px !important;
}

.ptb--15 {
  padding: 15px 0 !important;
}

.plr--15 {
  padding: 0 15px !important;
}

.pt--15 {
  padding-top: 15px !important;
}

.pb--15 {
  padding-bottom: 15px !important;
}

.pl--15 {
  padding-left: 15px !important;
}

.pr--15 {
  padding-right: 15px !important;
}

.mt--15 {
  margin-top: 15px !important;
}

.mb--15 {
  margin-bottom: 15px !important;
}

.mr--15 {
  margin-right: 15px !important;
}

.ml--15 {
  margin-left: 15px !important;
}

.ptb--20 {
  padding: 20px 0 !important;
}

.plr--20 {
  padding: 0 20px !important;
}

.pt--20 {
  padding-top: 20px !important;
}

.pb--20 {
  padding-bottom: 20px !important;
}

.pl--20 {
  padding-left: 20px !important;
}

.pr--20 {
  padding-right: 20px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.ptb--25 {
  padding: 25px 0 !important;
}

.plr--25 {
  padding: 0 25px !important;
}

.pt--25 {
  padding-top: 25px !important;
}

.pb--25 {
  padding-bottom: 25px !important;
}

.pl--25 {
  padding-left: 25px !important;
}

.pr--25 {
  padding-right: 25px !important;
}

.mt--25 {
  margin-top: 25px !important;
}

.mb--25 {
  margin-bottom: 25px !important;
}

.mr--25 {
  margin-right: 25px !important;
}

.ml--25 {
  margin-left: 25px !important;
}

.ptb--30 {
  padding: 30px 0 !important;
}

.plr--30 {
  padding: 0 30px !important;
}

.pt--30 {
  padding-top: 30px !important;
}

.pb--30 {
  padding-bottom: 30px !important;
}

.pl--30 {
  padding-left: 30px !important;
}

.pr--30 {
  padding-right: 30px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.mr--30 {
  margin-right: 30px !important;
}

.ml--30 {
  margin-left: 30px !important;
}

.ptb--35 {
  padding: 35px 0 !important;
}

.plr--35 {
  padding: 0 35px !important;
}

.pt--35 {
  padding-top: 35px !important;
}

.pb--35 {
  padding-bottom: 35px !important;
}

.pl--35 {
  padding-left: 35px !important;
}

.pr--35 {
  padding-right: 35px !important;
}

.mt--35 {
  margin-top: 35px !important;
}

.mb--35 {
  margin-bottom: 35px !important;
}

.mr--35 {
  margin-right: 35px !important;
}

.ml--35 {
  margin-left: 35px !important;
}

.ptb--40 {
  padding: 40px 0 !important;
}

.plr--40 {
  padding: 0 40px !important;
}

.pt--40 {
  padding-top: 40px !important;
}

.pb--40 {
  padding-bottom: 40px !important;
}

.pl--40 {
  padding-left: 40px !important;
}

.pr--40 {
  padding-right: 40px !important;
}

.mt--40 {
  margin-top: 40px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mr--40 {
  margin-right: 40px !important;
}

.ml--40 {
  margin-left: 40px !important;
}

.ptb--45 {
  padding: 45px 0 !important;
}

.plr--45 {
  padding: 0 45px !important;
}

.pt--45 {
  padding-top: 45px !important;
}

.pb--45 {
  padding-bottom: 45px !important;
}

.pl--45 {
  padding-left: 45px !important;
}

.pr--45 {
  padding-right: 45px !important;
}

.mt--45 {
  margin-top: 45px !important;
}

.mb--45 {
  margin-bottom: 45px !important;
}

.mr--45 {
  margin-right: 45px !important;
}

.ml--45 {
  margin-left: 45px !important;
}

.ptb--50 {
  padding: 50px 0 !important;
}

.plr--50 {
  padding: 0 50px !important;
}

.pt--50 {
  padding-top: 50px !important;
}

.pb--50 {
  padding-bottom: 50px !important;
}

.pl--50 {
  padding-left: 50px !important;
}

.pr--50 {
  padding-right: 50px !important;
}

.mt--50 {
  margin-top: 50px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mr--50 {
  margin-right: 50px !important;
}

.ml--50 {
  margin-left: 50px !important;
}

.ptb--55 {
  padding: 55px 0 !important;
}

.plr--55 {
  padding: 0 55px !important;
}

.pt--55 {
  padding-top: 55px !important;
}

.pb--55 {
  padding-bottom: 55px !important;
}

.pl--55 {
  padding-left: 55px !important;
}

.pr--55 {
  padding-right: 55px !important;
}

.mt--55 {
  margin-top: 55px !important;
}

.mb--55 {
  margin-bottom: 55px !important;
}

.mr--55 {
  margin-right: 55px !important;
}

.ml--55 {
  margin-left: 55px !important;
}

.ptb--60 {
  padding: 60px 0 !important;
}

.plr--60 {
  padding: 0 60px !important;
}

.pt--60 {
  padding-top: 60px !important;
}

.pb--60 {
  padding-bottom: 60px !important;
}

.pl--60 {
  padding-left: 60px !important;
}

.pr--60 {
  padding-right: 60px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.mr--60 {
  margin-right: 60px !important;
}

.ml--60 {
  margin-left: 60px !important;
}

.ptb--65 {
  padding: 65px 0 !important;
}

.plr--65 {
  padding: 0 65px !important;
}

.pt--65 {
  padding-top: 65px !important;
}

.pb--65 {
  padding-bottom: 65px !important;
}

.pl--65 {
  padding-left: 65px !important;
}

.pr--65 {
  padding-right: 65px !important;
}

.mt--65 {
  margin-top: 65px !important;
}

.mb--65 {
  margin-bottom: 65px !important;
}

.mr--65 {
  margin-right: 65px !important;
}

.ml--65 {
  margin-left: 65px !important;
}

.ptb--70 {
  padding: 70px 0 !important;
}

.plr--70 {
  padding: 0 70px !important;
}

.pt--70 {
  padding-top: 70px !important;
}

.pb--70 {
  padding-bottom: 70px !important;
}

.pl--70 {
  padding-left: 70px !important;
}

.pr--70 {
  padding-right: 70px !important;
}

.mt--70 {
  margin-top: 70px !important;
}

.mb--70 {
  margin-bottom: 70px !important;
}

.mr--70 {
  margin-right: 70px !important;
}

.ml--70 {
  margin-left: 70px !important;
}

.ptb--75 {
  padding: 75px 0 !important;
}

.plr--75 {
  padding: 0 75px !important;
}

.pt--75 {
  padding-top: 75px !important;
}

.pb--75 {
  padding-bottom: 75px !important;
}

.pl--75 {
  padding-left: 75px !important;
}

.pr--75 {
  padding-right: 75px !important;
}

.mt--75 {
  margin-top: 75px !important;
}

.mb--75 {
  margin-bottom: 75px !important;
}

.mr--75 {
  margin-right: 75px !important;
}

.ml--75 {
  margin-left: 75px !important;
}

.ptb--80 {
  padding: 80px 0 !important;
}

.plr--80 {
  padding: 0 80px !important;
}

.pt--80 {
  padding-top: 80px !important;
}

.pb--80 {
  padding-bottom: 80px !important;
}

.pl--80 {
  padding-left: 80px !important;
}

.pr--80 {
  padding-right: 80px !important;
}

.mt--80 {
  margin-top: 80px !important;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mr--80 {
  margin-right: 80px !important;
}

.ml--80 {
  margin-left: 80px !important;
}

.ptb--85 {
  padding: 85px 0 !important;
}

.plr--85 {
  padding: 0 85px !important;
}

.pt--85 {
  padding-top: 85px !important;
}

.pb--85 {
  padding-bottom: 85px !important;
}

.pl--85 {
  padding-left: 85px !important;
}

.pr--85 {
  padding-right: 85px !important;
}

.mt--85 {
  margin-top: 85px !important;
}

.mb--85 {
  margin-bottom: 85px !important;
}

.mr--85 {
  margin-right: 85px !important;
}

.ml--85 {
  margin-left: 85px !important;
}

.ptb--90 {
  padding: 90px 0 !important;
}

.plr--90 {
  padding: 0 90px !important;
}

.pt--90 {
  padding-top: 90px !important;
}

.pb--90 {
  padding-bottom: 90px !important;
}

.pl--90 {
  padding-left: 90px !important;
}

.pr--90 {
  padding-right: 90px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}

.mr--90 {
  margin-right: 90px !important;
}

.ml--90 {
  margin-left: 90px !important;
}

.ptb--95 {
  padding: 95px 0 !important;
}

.plr--95 {
  padding: 0 95px !important;
}

.pt--95 {
  padding-top: 95px !important;
}

.pb--95 {
  padding-bottom: 95px !important;
}

.pl--95 {
  padding-left: 95px !important;
}

.pr--95 {
  padding-right: 95px !important;
}

.mt--95 {
  margin-top: 95px !important;
}

.mb--95 {
  margin-bottom: 95px !important;
}

.mr--95 {
  margin-right: 95px !important;
}

.ml--95 {
  margin-left: 95px !important;
}

.ptb--100 {
  padding: 100px 0 !important;
}

.plr--100 {
  padding: 0 100px !important;
}

.pt--100 {
  padding-top: 100px !important;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pl--100 {
  padding-left: 100px !important;
}

.pr--100 {
  padding-right: 100px !important;
}

.mt--100 {
  margin-top: 100px !important;
}

.mb--100 {
  margin-bottom: 100px !important;
}

.mr--100 {
  margin-right: 100px !important;
}

.ml--100 {
  margin-left: 100px !important;
}

.ptb--105 {
  padding: 105px 0 !important;
}

.plr--105 {
  padding: 0 105px !important;
}

.pt--105 {
  padding-top: 105px !important;
}

.pb--105 {
  padding-bottom: 105px !important;
}

.pl--105 {
  padding-left: 105px !important;
}

.pr--105 {
  padding-right: 105px !important;
}

.mt--105 {
  margin-top: 105px !important;
}

.mb--105 {
  margin-bottom: 105px !important;
}

.mr--105 {
  margin-right: 105px !important;
}

.ml--105 {
  margin-left: 105px !important;
}

.ptb--110 {
  padding: 110px 0 !important;
}

.plr--110 {
  padding: 0 110px !important;
}

.pt--110 {
  padding-top: 110px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

.pl--110 {
  padding-left: 110px !important;
}

.pr--110 {
  padding-right: 110px !important;
}

.mt--110 {
  margin-top: 110px !important;
}

.mb--110 {
  margin-bottom: 110px !important;
}

.mr--110 {
  margin-right: 110px !important;
}

.ml--110 {
  margin-left: 110px !important;
}

.ptb--115 {
  padding: 115px 0 !important;
}

.plr--115 {
  padding: 0 115px !important;
}

.pt--115 {
  padding-top: 115px !important;
}

.pb--115 {
  padding-bottom: 115px !important;
}

.pl--115 {
  padding-left: 115px !important;
}

.pr--115 {
  padding-right: 115px !important;
}

.mt--115 {
  margin-top: 115px !important;
}

.mb--115 {
  margin-bottom: 115px !important;
}

.mr--115 {
  margin-right: 115px !important;
}

.ml--115 {
  margin-left: 115px !important;
}

.ptb--120 {
  padding: 120px 0 !important;
}

.plr--120 {
  padding: 0 120px !important;
}

.pt--120 {
  padding-top: 120px !important;
}

.pb--120 {
  padding-bottom: 120px !important;
}

.pl--120 {
  padding-left: 120px !important;
}

.pr--120 {
  padding-right: 120px !important;
}

.mt--120 {
  margin-top: 120px !important;
}

.mb--120 {
  margin-bottom: 120px !important;
}

.mr--120 {
  margin-right: 120px !important;
}

.ml--120 {
  margin-left: 120px !important;
}

.ptb--125 {
  padding: 125px 0 !important;
}

.plr--125 {
  padding: 0 125px !important;
}

.pt--125 {
  padding-top: 125px !important;
}

.pb--125 {
  padding-bottom: 125px !important;
}

.pl--125 {
  padding-left: 125px !important;
}

.pr--125 {
  padding-right: 125px !important;
}

.mt--125 {
  margin-top: 125px !important;
}

.mb--125 {
  margin-bottom: 125px !important;
}

.mr--125 {
  margin-right: 125px !important;
}

.ml--125 {
  margin-left: 125px !important;
}

.ptb--130 {
  padding: 130px 0 !important;
}

.plr--130 {
  padding: 0 130px !important;
}

.pt--130 {
  padding-top: 130px !important;
}

.pb--130 {
  padding-bottom: 130px !important;
}

.pl--130 {
  padding-left: 130px !important;
}

.pr--130 {
  padding-right: 130px !important;
}

.mt--130 {
  margin-top: 130px !important;
}

.mb--130 {
  margin-bottom: 130px !important;
}

.mr--130 {
  margin-right: 130px !important;
}

.ml--130 {
  margin-left: 130px !important;
}

.ptb--135 {
  padding: 135px 0 !important;
}

.plr--135 {
  padding: 0 135px !important;
}

.pt--135 {
  padding-top: 135px !important;
}

.pb--135 {
  padding-bottom: 135px !important;
}

.pl--135 {
  padding-left: 135px !important;
}

.pr--135 {
  padding-right: 135px !important;
}

.mt--135 {
  margin-top: 135px !important;
}

.mb--135 {
  margin-bottom: 135px !important;
}

.mr--135 {
  margin-right: 135px !important;
}

.ml--135 {
  margin-left: 135px !important;
}

.ptb--140 {
  padding: 140px 0 !important;
}

.plr--140 {
  padding: 0 140px !important;
}

.pt--140 {
  padding-top: 140px !important;
}

.pb--140 {
  padding-bottom: 140px !important;
}

.pl--140 {
  padding-left: 140px !important;
}

.pr--140 {
  padding-right: 140px !important;
}

.mt--140 {
  margin-top: 140px !important;
}

.mb--140 {
  margin-bottom: 140px !important;
}

.mr--140 {
  margin-right: 140px !important;
}

.ml--140 {
  margin-left: 140px !important;
}

.ptb--145 {
  padding: 145px 0 !important;
}

.plr--145 {
  padding: 0 145px !important;
}

.pt--145 {
  padding-top: 145px !important;
}

.pb--145 {
  padding-bottom: 145px !important;
}

.pl--145 {
  padding-left: 145px !important;
}

.pr--145 {
  padding-right: 145px !important;
}

.mt--145 {
  margin-top: 145px !important;
}

.mb--145 {
  margin-bottom: 145px !important;
}

.mr--145 {
  margin-right: 145px !important;
}

.ml--145 {
  margin-left: 145px !important;
}

.ptb--150 {
  padding: 150px 0 !important;
}

.plr--150 {
  padding: 0 150px !important;
}

.pt--150 {
  padding-top: 150px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.pl--150 {
  padding-left: 150px !important;
}

.pr--150 {
  padding-right: 150px !important;
}

.mt--150 {
  margin-top: 150px !important;
}

.mb--150 {
  margin-bottom: 150px !important;
}

.mr--150 {
  margin-right: 150px !important;
}

.ml--150 {
  margin-left: 150px !important;
}

.ptb--155 {
  padding: 155px 0 !important;
}

.plr--155 {
  padding: 0 155px !important;
}

.pt--155 {
  padding-top: 155px !important;
}

.pb--155 {
  padding-bottom: 155px !important;
}

.pl--155 {
  padding-left: 155px !important;
}

.pr--155 {
  padding-right: 155px !important;
}

.mt--155 {
  margin-top: 155px !important;
}

.mb--155 {
  margin-bottom: 155px !important;
}

.mr--155 {
  margin-right: 155px !important;
}

.ml--155 {
  margin-left: 155px !important;
}

.ptb--160 {
  padding: 160px 0 !important;
}

.plr--160 {
  padding: 0 160px !important;
}

.pt--160 {
  padding-top: 160px !important;
}

.pb--160 {
  padding-bottom: 160px !important;
}

.pl--160 {
  padding-left: 160px !important;
}

.pr--160 {
  padding-right: 160px !important;
}

.mt--160 {
  margin-top: 160px !important;
}

.mb--160 {
  margin-bottom: 160px !important;
}

.mr--160 {
  margin-right: 160px !important;
}

.ml--160 {
  margin-left: 160px !important;
}

.ptb--165 {
  padding: 165px 0 !important;
}

.plr--165 {
  padding: 0 165px !important;
}

.pt--165 {
  padding-top: 165px !important;
}

.pb--165 {
  padding-bottom: 165px !important;
}

.pl--165 {
  padding-left: 165px !important;
}

.pr--165 {
  padding-right: 165px !important;
}

.mt--165 {
  margin-top: 165px !important;
}

.mb--165 {
  margin-bottom: 165px !important;
}

.mr--165 {
  margin-right: 165px !important;
}

.ml--165 {
  margin-left: 165px !important;
}

.ptb--170 {
  padding: 170px 0 !important;
}

.plr--170 {
  padding: 0 170px !important;
}

.pt--170 {
  padding-top: 170px !important;
}

.pb--170 {
  padding-bottom: 170px !important;
}

.pl--170 {
  padding-left: 170px !important;
}

.pr--170 {
  padding-right: 170px !important;
}

.mt--170 {
  margin-top: 170px !important;
}

.mb--170 {
  margin-bottom: 170px !important;
}

.mr--170 {
  margin-right: 170px !important;
}

.ml--170 {
  margin-left: 170px !important;
}

.ptb--175 {
  padding: 175px 0 !important;
}

.plr--175 {
  padding: 0 175px !important;
}

.pt--175 {
  padding-top: 175px !important;
}

.pb--175 {
  padding-bottom: 175px !important;
}

.pl--175 {
  padding-left: 175px !important;
}

.pr--175 {
  padding-right: 175px !important;
}

.mt--175 {
  margin-top: 175px !important;
}

.mb--175 {
  margin-bottom: 175px !important;
}

.mr--175 {
  margin-right: 175px !important;
}

.ml--175 {
  margin-left: 175px !important;
}

.ptb--180 {
  padding: 180px 0 !important;
}

.plr--180 {
  padding: 0 180px !important;
}

.pt--180 {
  padding-top: 180px !important;
}

.pb--180 {
  padding-bottom: 180px !important;
}

.pl--180 {
  padding-left: 180px !important;
}

.pr--180 {
  padding-right: 180px !important;
}

.mt--180 {
  margin-top: 180px !important;
}

.mb--180 {
  margin-bottom: 180px !important;
}

.mr--180 {
  margin-right: 180px !important;
}

.ml--180 {
  margin-left: 180px !important;
}

.ptb--185 {
  padding: 185px 0 !important;
}

.plr--185 {
  padding: 0 185px !important;
}

.pt--185 {
  padding-top: 185px !important;
}

.pb--185 {
  padding-bottom: 185px !important;
}

.pl--185 {
  padding-left: 185px !important;
}

.pr--185 {
  padding-right: 185px !important;
}

.mt--185 {
  margin-top: 185px !important;
}

.mb--185 {
  margin-bottom: 185px !important;
}

.mr--185 {
  margin-right: 185px !important;
}

.ml--185 {
  margin-left: 185px !important;
}

.ptb--190 {
  padding: 190px 0 !important;
}

.plr--190 {
  padding: 0 190px !important;
}

.pt--190 {
  padding-top: 190px !important;
}

.pb--190 {
  padding-bottom: 190px !important;
}

.pl--190 {
  padding-left: 190px !important;
}

.pr--190 {
  padding-right: 190px !important;
}

.mt--190 {
  margin-top: 190px !important;
}

.mb--190 {
  margin-bottom: 190px !important;
}

.mr--190 {
  margin-right: 190px !important;
}

.ml--190 {
  margin-left: 190px !important;
}

.ptb--195 {
  padding: 195px 0 !important;
}

.plr--195 {
  padding: 0 195px !important;
}

.pt--195 {
  padding-top: 195px !important;
}

.pb--195 {
  padding-bottom: 195px !important;
}

.pl--195 {
  padding-left: 195px !important;
}

.pr--195 {
  padding-right: 195px !important;
}

.mt--195 {
  margin-top: 195px !important;
}

.mb--195 {
  margin-bottom: 195px !important;
}

.mr--195 {
  margin-right: 195px !important;
}

.ml--195 {
  margin-left: 195px !important;
}

.ptb--200 {
  padding: 200px 0 !important;
}

.plr--200 {
  padding: 0 200px !important;
}

.pt--200 {
  padding-top: 200px !important;
}

.pb--200 {
  padding-bottom: 200px !important;
}

.pl--200 {
  padding-left: 200px !important;
}

.pr--200 {
  padding-right: 200px !important;
}

.mt--200 {
  margin-top: 200px !important;
}

.mb--200 {
  margin-bottom: 200px !important;
}

.mr--200 {
  margin-right: 200px !important;
}

.ml--200 {
  margin-left: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0;
  }

  .plr_lp--5 {
    padding: 0 5px;
  }

  .pt_lp--5 {
    padding-top: 5px;
  }

  .pb_lp--5 {
    padding-bottom: 5px;
  }

  .pl_lp--5 {
    padding-left: 5px;
  }

  .pr_lp--5 {
    padding-right: 5px;
  }

  .mt_lp--5 {
    margin-top: 5px;
  }

  .mb_lp--5 {
    margin-bottom: 5px;
  }

  .ptb_lp--10 {
    padding: 10px 0;
  }

  .plr_lp--10 {
    padding: 0 10px;
  }

  .pt_lp--10 {
    padding-top: 10px;
  }

  .pb_lp--10 {
    padding-bottom: 10px;
  }

  .pl_lp--10 {
    padding-left: 10px;
  }

  .pr_lp--10 {
    padding-right: 10px;
  }

  .mt_lp--10 {
    margin-top: 10px;
  }

  .mb_lp--10 {
    margin-bottom: 10px;
  }

  .ptb_lp--15 {
    padding: 15px 0;
  }

  .plr_lp--15 {
    padding: 0 15px;
  }

  .pt_lp--15 {
    padding-top: 15px;
  }

  .pb_lp--15 {
    padding-bottom: 15px;
  }

  .pl_lp--15 {
    padding-left: 15px;
  }

  .pr_lp--15 {
    padding-right: 15px;
  }

  .mt_lp--15 {
    margin-top: 15px;
  }

  .mb_lp--15 {
    margin-bottom: 15px;
  }

  .ptb_lp--20 {
    padding: 20px 0;
  }

  .plr_lp--20 {
    padding: 0 20px;
  }

  .pt_lp--20 {
    padding-top: 20px;
  }

  .pb_lp--20 {
    padding-bottom: 20px;
  }

  .pl_lp--20 {
    padding-left: 20px;
  }

  .pr_lp--20 {
    padding-right: 20px;
  }

  .mt_lp--20 {
    margin-top: 20px;
  }

  .mb_lp--20 {
    margin-bottom: 20px;
  }

  .ptb_lp--25 {
    padding: 25px 0;
  }

  .plr_lp--25 {
    padding: 0 25px;
  }

  .pt_lp--25 {
    padding-top: 25px;
  }

  .pb_lp--25 {
    padding-bottom: 25px;
  }

  .pl_lp--25 {
    padding-left: 25px;
  }

  .pr_lp--25 {
    padding-right: 25px;
  }

  .mt_lp--25 {
    margin-top: 25px;
  }

  .mb_lp--25 {
    margin-bottom: 25px;
  }

  .ptb_lp--30 {
    padding: 30px 0;
  }

  .plr_lp--30 {
    padding: 0 30px;
  }

  .pt_lp--30 {
    padding-top: 30px;
  }

  .pb_lp--30 {
    padding-bottom: 30px;
  }

  .pl_lp--30 {
    padding-left: 30px;
  }

  .pr_lp--30 {
    padding-right: 30px;
  }

  .mt_lp--30 {
    margin-top: 30px;
  }

  .mb_lp--30 {
    margin-bottom: 30px;
  }

  .ptb_lp--35 {
    padding: 35px 0;
  }

  .plr_lp--35 {
    padding: 0 35px;
  }

  .pt_lp--35 {
    padding-top: 35px;
  }

  .pb_lp--35 {
    padding-bottom: 35px;
  }

  .pl_lp--35 {
    padding-left: 35px;
  }

  .pr_lp--35 {
    padding-right: 35px;
  }

  .mt_lp--35 {
    margin-top: 35px;
  }

  .mb_lp--35 {
    margin-bottom: 35px;
  }

  .ptb_lp--40 {
    padding: 40px 0;
  }

  .plr_lp--40 {
    padding: 0 40px;
  }

  .pt_lp--40 {
    padding-top: 40px;
  }

  .pb_lp--40 {
    padding-bottom: 40px;
  }

  .pl_lp--40 {
    padding-left: 40px;
  }

  .pr_lp--40 {
    padding-right: 40px;
  }

  .mt_lp--40 {
    margin-top: 40px;
  }

  .mb_lp--40 {
    margin-bottom: 40px;
  }

  .ptb_lp--45 {
    padding: 45px 0;
  }

  .plr_lp--45 {
    padding: 0 45px;
  }

  .pt_lp--45 {
    padding-top: 45px;
  }

  .pb_lp--45 {
    padding-bottom: 45px;
  }

  .pl_lp--45 {
    padding-left: 45px;
  }

  .pr_lp--45 {
    padding-right: 45px;
  }

  .mt_lp--45 {
    margin-top: 45px;
  }

  .mb_lp--45 {
    margin-bottom: 45px;
  }

  .ptb_lp--50 {
    padding: 50px 0;
  }

  .plr_lp--50 {
    padding: 0 50px;
  }

  .pt_lp--50 {
    padding-top: 50px;
  }

  .pb_lp--50 {
    padding-bottom: 50px;
  }

  .pl_lp--50 {
    padding-left: 50px;
  }

  .pr_lp--50 {
    padding-right: 50px;
  }

  .mt_lp--50 {
    margin-top: 50px;
  }

  .mb_lp--50 {
    margin-bottom: 50px;
  }

  .ptb_lp--55 {
    padding: 55px 0;
  }

  .plr_lp--55 {
    padding: 0 55px;
  }

  .pt_lp--55 {
    padding-top: 55px;
  }

  .pb_lp--55 {
    padding-bottom: 55px;
  }

  .pl_lp--55 {
    padding-left: 55px;
  }

  .pr_lp--55 {
    padding-right: 55px;
  }

  .mt_lp--55 {
    margin-top: 55px;
  }

  .mb_lp--55 {
    margin-bottom: 55px;
  }

  .ptb_lp--60 {
    padding: 60px 0;
  }

  .plr_lp--60 {
    padding: 0 60px;
  }

  .pt_lp--60 {
    padding-top: 60px;
  }

  .pb_lp--60 {
    padding-bottom: 60px;
  }

  .pl_lp--60 {
    padding-left: 60px;
  }

  .pr_lp--60 {
    padding-right: 60px;
  }

  .mt_lp--60 {
    margin-top: 60px;
  }

  .mb_lp--60 {
    margin-bottom: 60px;
  }

  .ptb_lp--65 {
    padding: 65px 0;
  }

  .plr_lp--65 {
    padding: 0 65px;
  }

  .pt_lp--65 {
    padding-top: 65px;
  }

  .pb_lp--65 {
    padding-bottom: 65px;
  }

  .pl_lp--65 {
    padding-left: 65px;
  }

  .pr_lp--65 {
    padding-right: 65px;
  }

  .mt_lp--65 {
    margin-top: 65px;
  }

  .mb_lp--65 {
    margin-bottom: 65px;
  }

  .ptb_lp--70 {
    padding: 70px 0;
  }

  .plr_lp--70 {
    padding: 0 70px;
  }

  .pt_lp--70 {
    padding-top: 70px;
  }

  .pb_lp--70 {
    padding-bottom: 70px;
  }

  .pl_lp--70 {
    padding-left: 70px;
  }

  .pr_lp--70 {
    padding-right: 70px;
  }

  .mt_lp--70 {
    margin-top: 70px;
  }

  .mb_lp--70 {
    margin-bottom: 70px;
  }

  .ptb_lp--75 {
    padding: 75px 0;
  }

  .plr_lp--75 {
    padding: 0 75px;
  }

  .pt_lp--75 {
    padding-top: 75px;
  }

  .pb_lp--75 {
    padding-bottom: 75px;
  }

  .pl_lp--75 {
    padding-left: 75px;
  }

  .pr_lp--75 {
    padding-right: 75px;
  }

  .mt_lp--75 {
    margin-top: 75px;
  }

  .mb_lp--75 {
    margin-bottom: 75px;
  }

  .ptb_lp--80 {
    padding: 80px 0;
  }

  .plr_lp--80 {
    padding: 0 80px;
  }

  .pt_lp--80 {
    padding-top: 80px;
  }

  .pb_lp--80 {
    padding-bottom: 80px;
  }

  .pl_lp--80 {
    padding-left: 80px;
  }

  .pr_lp--80 {
    padding-right: 80px;
  }

  .mt_lp--80 {
    margin-top: 80px;
  }

  .mb_lp--80 {
    margin-bottom: 80px;
  }

  .ptb_lp--85 {
    padding: 85px 0;
  }

  .plr_lp--85 {
    padding: 0 85px;
  }

  .pt_lp--85 {
    padding-top: 85px;
  }

  .pb_lp--85 {
    padding-bottom: 85px;
  }

  .pl_lp--85 {
    padding-left: 85px;
  }

  .pr_lp--85 {
    padding-right: 85px;
  }

  .mt_lp--85 {
    margin-top: 85px;
  }

  .mb_lp--85 {
    margin-bottom: 85px;
  }

  .ptb_lp--90 {
    padding: 90px 0;
  }

  .plr_lp--90 {
    padding: 0 90px;
  }

  .pt_lp--90 {
    padding-top: 90px;
  }

  .pb_lp--90 {
    padding-bottom: 90px;
  }

  .pl_lp--90 {
    padding-left: 90px;
  }

  .pr_lp--90 {
    padding-right: 90px;
  }

  .mt_lp--90 {
    margin-top: 90px;
  }

  .mb_lp--90 {
    margin-bottom: 90px;
  }

  .ptb_lp--95 {
    padding: 95px 0;
  }

  .plr_lp--95 {
    padding: 0 95px;
  }

  .pt_lp--95 {
    padding-top: 95px;
  }

  .pb_lp--95 {
    padding-bottom: 95px;
  }

  .pl_lp--95 {
    padding-left: 95px;
  }

  .pr_lp--95 {
    padding-right: 95px;
  }

  .mt_lp--95 {
    margin-top: 95px;
  }

  .mb_lp--95 {
    margin-bottom: 95px;
  }

  .ptb_lp--100 {
    padding: 100px 0;
  }

  .plr_lp--100 {
    padding: 0 100px;
  }

  .pt_lp--100 {
    padding-top: 100px;
  }

  .pb_lp--100 {
    padding-bottom: 100px;
  }

  .pl_lp--100 {
    padding-left: 100px;
  }

  .pr_lp--100 {
    padding-right: 100px;
  }

  .mt_lp--100 {
    margin-top: 100px;
  }

  .mb_lp--100 {
    margin-bottom: 100px;
  }

  .ptb_lp--105 {
    padding: 105px 0;
  }

  .plr_lp--105 {
    padding: 0 105px;
  }

  .pt_lp--105 {
    padding-top: 105px;
  }

  .pb_lp--105 {
    padding-bottom: 105px;
  }

  .pl_lp--105 {
    padding-left: 105px;
  }

  .pr_lp--105 {
    padding-right: 105px;
  }

  .mt_lp--105 {
    margin-top: 105px;
  }

  .mb_lp--105 {
    margin-bottom: 105px;
  }

  .ptb_lp--110 {
    padding: 110px 0;
  }

  .plr_lp--110 {
    padding: 0 110px;
  }

  .pt_lp--110 {
    padding-top: 110px;
  }

  .pb_lp--110 {
    padding-bottom: 110px;
  }

  .pl_lp--110 {
    padding-left: 110px;
  }

  .pr_lp--110 {
    padding-right: 110px;
  }

  .mt_lp--110 {
    margin-top: 110px;
  }

  .mb_lp--110 {
    margin-bottom: 110px;
  }

  .ptb_lp--115 {
    padding: 115px 0;
  }

  .plr_lp--115 {
    padding: 0 115px;
  }

  .pt_lp--115 {
    padding-top: 115px;
  }

  .pb_lp--115 {
    padding-bottom: 115px;
  }

  .pl_lp--115 {
    padding-left: 115px;
  }

  .pr_lp--115 {
    padding-right: 115px;
  }

  .mt_lp--115 {
    margin-top: 115px;
  }

  .mb_lp--115 {
    margin-bottom: 115px;
  }

  .ptb_lp--120 {
    padding: 120px 0;
  }

  .plr_lp--120 {
    padding: 0 120px;
  }

  .pt_lp--120 {
    padding-top: 120px;
  }

  .pb_lp--120 {
    padding-bottom: 120px;
  }

  .pl_lp--120 {
    padding-left: 120px;
  }

  .pr_lp--120 {
    padding-right: 120px;
  }

  .mt_lp--120 {
    margin-top: 120px;
  }

  .mb_lp--120 {
    margin-bottom: 120px;
  }

  .ptb_lp--125 {
    padding: 125px 0;
  }

  .plr_lp--125 {
    padding: 0 125px;
  }

  .pt_lp--125 {
    padding-top: 125px;
  }

  .pb_lp--125 {
    padding-bottom: 125px;
  }

  .pl_lp--125 {
    padding-left: 125px;
  }

  .pr_lp--125 {
    padding-right: 125px;
  }

  .mt_lp--125 {
    margin-top: 125px;
  }

  .mb_lp--125 {
    margin-bottom: 125px;
  }

  .ptb_lp--130 {
    padding: 130px 0;
  }

  .plr_lp--130 {
    padding: 0 130px;
  }

  .pt_lp--130 {
    padding-top: 130px;
  }

  .pb_lp--130 {
    padding-bottom: 130px;
  }

  .pl_lp--130 {
    padding-left: 130px;
  }

  .pr_lp--130 {
    padding-right: 130px;
  }

  .mt_lp--130 {
    margin-top: 130px;
  }

  .mb_lp--130 {
    margin-bottom: 130px;
  }

  .ptb_lp--135 {
    padding: 135px 0;
  }

  .plr_lp--135 {
    padding: 0 135px;
  }

  .pt_lp--135 {
    padding-top: 135px;
  }

  .pb_lp--135 {
    padding-bottom: 135px;
  }

  .pl_lp--135 {
    padding-left: 135px;
  }

  .pr_lp--135 {
    padding-right: 135px;
  }

  .mt_lp--135 {
    margin-top: 135px;
  }

  .mb_lp--135 {
    margin-bottom: 135px;
  }

  .ptb_lp--140 {
    padding: 140px 0;
  }

  .plr_lp--140 {
    padding: 0 140px;
  }

  .pt_lp--140 {
    padding-top: 140px;
  }

  .pb_lp--140 {
    padding-bottom: 140px;
  }

  .pl_lp--140 {
    padding-left: 140px;
  }

  .pr_lp--140 {
    padding-right: 140px;
  }

  .mt_lp--140 {
    margin-top: 140px;
  }

  .mb_lp--140 {
    margin-bottom: 140px;
  }

  .ptb_lp--145 {
    padding: 145px 0;
  }

  .plr_lp--145 {
    padding: 0 145px;
  }

  .pt_lp--145 {
    padding-top: 145px;
  }

  .pb_lp--145 {
    padding-bottom: 145px;
  }

  .pl_lp--145 {
    padding-left: 145px;
  }

  .pr_lp--145 {
    padding-right: 145px;
  }

  .mt_lp--145 {
    margin-top: 145px;
  }

  .mb_lp--145 {
    margin-bottom: 145px;
  }

  .ptb_lp--150 {
    padding: 150px 0;
  }

  .plr_lp--150 {
    padding: 0 150px;
  }

  .pt_lp--150 {
    padding-top: 150px;
  }

  .pb_lp--150 {
    padding-bottom: 150px;
  }

  .pl_lp--150 {
    padding-left: 150px;
  }

  .pr_lp--150 {
    padding-right: 150px;
  }

  .mt_lp--150 {
    margin-top: 150px;
  }

  .mb_lp--150 {
    margin-bottom: 150px;
  }

  .ptb_lp--155 {
    padding: 155px 0;
  }

  .plr_lp--155 {
    padding: 0 155px;
  }

  .pt_lp--155 {
    padding-top: 155px;
  }

  .pb_lp--155 {
    padding-bottom: 155px;
  }

  .pl_lp--155 {
    padding-left: 155px;
  }

  .pr_lp--155 {
    padding-right: 155px;
  }

  .mt_lp--155 {
    margin-top: 155px;
  }

  .mb_lp--155 {
    margin-bottom: 155px;
  }

  .ptb_lp--160 {
    padding: 160px 0;
  }

  .plr_lp--160 {
    padding: 0 160px;
  }

  .pt_lp--160 {
    padding-top: 160px;
  }

  .pb_lp--160 {
    padding-bottom: 160px;
  }

  .pl_lp--160 {
    padding-left: 160px;
  }

  .pr_lp--160 {
    padding-right: 160px;
  }

  .mt_lp--160 {
    margin-top: 160px;
  }

  .mb_lp--160 {
    margin-bottom: 160px;
  }

  .ptb_lp--165 {
    padding: 165px 0;
  }

  .plr_lp--165 {
    padding: 0 165px;
  }

  .pt_lp--165 {
    padding-top: 165px;
  }

  .pb_lp--165 {
    padding-bottom: 165px;
  }

  .pl_lp--165 {
    padding-left: 165px;
  }

  .pr_lp--165 {
    padding-right: 165px;
  }

  .mt_lp--165 {
    margin-top: 165px;
  }

  .mb_lp--165 {
    margin-bottom: 165px;
  }

  .ptb_lp--170 {
    padding: 170px 0;
  }

  .plr_lp--170 {
    padding: 0 170px;
  }

  .pt_lp--170 {
    padding-top: 170px;
  }

  .pb_lp--170 {
    padding-bottom: 170px;
  }

  .pl_lp--170 {
    padding-left: 170px;
  }

  .pr_lp--170 {
    padding-right: 170px;
  }

  .mt_lp--170 {
    margin-top: 170px;
  }

  .mb_lp--170 {
    margin-bottom: 170px;
  }

  .ptb_lp--175 {
    padding: 175px 0;
  }

  .plr_lp--175 {
    padding: 0 175px;
  }

  .pt_lp--175 {
    padding-top: 175px;
  }

  .pb_lp--175 {
    padding-bottom: 175px;
  }

  .pl_lp--175 {
    padding-left: 175px;
  }

  .pr_lp--175 {
    padding-right: 175px;
  }

  .mt_lp--175 {
    margin-top: 175px;
  }

  .mb_lp--175 {
    margin-bottom: 175px;
  }

  .ptb_lp--180 {
    padding: 180px 0;
  }

  .plr_lp--180 {
    padding: 0 180px;
  }

  .pt_lp--180 {
    padding-top: 180px;
  }

  .pb_lp--180 {
    padding-bottom: 180px;
  }

  .pl_lp--180 {
    padding-left: 180px;
  }

  .pr_lp--180 {
    padding-right: 180px;
  }

  .mt_lp--180 {
    margin-top: 180px;
  }

  .mb_lp--180 {
    margin-bottom: 180px;
  }

  .ptb_lp--185 {
    padding: 185px 0;
  }

  .plr_lp--185 {
    padding: 0 185px;
  }

  .pt_lp--185 {
    padding-top: 185px;
  }

  .pb_lp--185 {
    padding-bottom: 185px;
  }

  .pl_lp--185 {
    padding-left: 185px;
  }

  .pr_lp--185 {
    padding-right: 185px;
  }

  .mt_lp--185 {
    margin-top: 185px;
  }

  .mb_lp--185 {
    margin-bottom: 185px;
  }

  .ptb_lp--190 {
    padding: 190px 0;
  }

  .plr_lp--190 {
    padding: 0 190px;
  }

  .pt_lp--190 {
    padding-top: 190px;
  }

  .pb_lp--190 {
    padding-bottom: 190px;
  }

  .pl_lp--190 {
    padding-left: 190px;
  }

  .pr_lp--190 {
    padding-right: 190px;
  }

  .mt_lp--190 {
    margin-top: 190px;
  }

  .mb_lp--190 {
    margin-bottom: 190px;
  }

  .ptb_lp--195 {
    padding: 195px 0;
  }

  .plr_lp--195 {
    padding: 0 195px;
  }

  .pt_lp--195 {
    padding-top: 195px;
  }

  .pb_lp--195 {
    padding-bottom: 195px;
  }

  .pl_lp--195 {
    padding-left: 195px;
  }

  .pr_lp--195 {
    padding-right: 195px;
  }

  .mt_lp--195 {
    margin-top: 195px;
  }

  .mb_lp--195 {
    margin-bottom: 195px;
  }

  .ptb_lp--200 {
    padding: 200px 0;
  }

  .plr_lp--200 {
    padding: 0 200px;
  }

  .pt_lp--200 {
    padding-top: 200px;
  }

  .pb_lp--200 {
    padding-bottom: 200px;
  }

  .pl_lp--200 {
    padding-left: 200px;
  }

  .pr_lp--200 {
    padding-right: 200px;
  }

  .mt_lp--200 {
    margin-top: 200px;
  }

  .mb_lp--200 {
    margin-bottom: 200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptb_lg--5 {
    padding: 5px 0 !important;
  }

  .plr_lg--5 {
    padding: 0 5px !important;
  }

  .pt_lg--5 {
    padding-top: 5px !important;
  }

  .pb_lg--5 {
    padding-bottom: 5px !important;
  }

  .pl_lg--5 {
    padding-left: 5px !important;
  }

  .pr_lg--5 {
    padding-right: 5px !important;
  }

  .mt_lg--5 {
    margin-top: 5px !important;
  }

  .mb_lg--5 {
    margin-bottom: 5px !important;
  }

  .ml_lg--5 {
    margin-left: 5px !important;
  }

  .ptb_lg--10 {
    padding: 10px 0 !important;
  }

  .plr_lg--10 {
    padding: 0 10px !important;
  }

  .pt_lg--10 {
    padding-top: 10px !important;
  }

  .pb_lg--10 {
    padding-bottom: 10px !important;
  }

  .pl_lg--10 {
    padding-left: 10px !important;
  }

  .pr_lg--10 {
    padding-right: 10px !important;
  }

  .mt_lg--10 {
    margin-top: 10px !important;
  }

  .mb_lg--10 {
    margin-bottom: 10px !important;
  }

  .ml_lg--10 {
    margin-left: 10px !important;
  }

  .ptb_lg--15 {
    padding: 15px 0 !important;
  }

  .plr_lg--15 {
    padding: 0 15px !important;
  }

  .pt_lg--15 {
    padding-top: 15px !important;
  }

  .pb_lg--15 {
    padding-bottom: 15px !important;
  }

  .pl_lg--15 {
    padding-left: 15px !important;
  }

  .pr_lg--15 {
    padding-right: 15px !important;
  }

  .mt_lg--15 {
    margin-top: 15px !important;
  }

  .mb_lg--15 {
    margin-bottom: 15px !important;
  }

  .ml_lg--15 {
    margin-left: 15px !important;
  }

  .ptb_lg--20 {
    padding: 20px 0 !important;
  }

  .plr_lg--20 {
    padding: 0 20px !important;
  }

  .pt_lg--20 {
    padding-top: 20px !important;
  }

  .pb_lg--20 {
    padding-bottom: 20px !important;
  }

  .pl_lg--20 {
    padding-left: 20px !important;
  }

  .pr_lg--20 {
    padding-right: 20px !important;
  }

  .mt_lg--20 {
    margin-top: 20px !important;
  }

  .mb_lg--20 {
    margin-bottom: 20px !important;
  }

  .ml_lg--20 {
    margin-left: 20px !important;
  }

  .ptb_lg--25 {
    padding: 25px 0 !important;
  }

  .plr_lg--25 {
    padding: 0 25px !important;
  }

  .pt_lg--25 {
    padding-top: 25px !important;
  }

  .pb_lg--25 {
    padding-bottom: 25px !important;
  }

  .pl_lg--25 {
    padding-left: 25px !important;
  }

  .pr_lg--25 {
    padding-right: 25px !important;
  }

  .mt_lg--25 {
    margin-top: 25px !important;
  }

  .mb_lg--25 {
    margin-bottom: 25px !important;
  }

  .ml_lg--25 {
    margin-left: 25px !important;
  }

  .ptb_lg--30 {
    padding: 30px 0 !important;
  }

  .plr_lg--30 {
    padding: 0 30px !important;
  }

  .pt_lg--30 {
    padding-top: 30px !important;
  }

  .pb_lg--30 {
    padding-bottom: 30px !important;
  }

  .pl_lg--30 {
    padding-left: 30px !important;
  }

  .pr_lg--30 {
    padding-right: 30px !important;
  }

  .mt_lg--30 {
    margin-top: 30px !important;
  }

  .mb_lg--30 {
    margin-bottom: 30px !important;
  }

  .ml_lg--30 {
    margin-left: 30px !important;
  }

  .ptb_lg--35 {
    padding: 35px 0 !important;
  }

  .plr_lg--35 {
    padding: 0 35px !important;
  }

  .pt_lg--35 {
    padding-top: 35px !important;
  }

  .pb_lg--35 {
    padding-bottom: 35px !important;
  }

  .pl_lg--35 {
    padding-left: 35px !important;
  }

  .pr_lg--35 {
    padding-right: 35px !important;
  }

  .mt_lg--35 {
    margin-top: 35px !important;
  }

  .mb_lg--35 {
    margin-bottom: 35px !important;
  }

  .ml_lg--35 {
    margin-left: 35px !important;
  }

  .ptb_lg--40 {
    padding: 40px 0 !important;
  }

  .plr_lg--40 {
    padding: 0 40px !important;
  }

  .pt_lg--40 {
    padding-top: 40px !important;
  }

  .pb_lg--40 {
    padding-bottom: 40px !important;
  }

  .pl_lg--40 {
    padding-left: 40px !important;
  }

  .pr_lg--40 {
    padding-right: 40px !important;
  }

  .mt_lg--40 {
    margin-top: 40px !important;
  }

  .mb_lg--40 {
    margin-bottom: 40px !important;
  }

  .ml_lg--40 {
    margin-left: 40px !important;
  }

  .ptb_lg--45 {
    padding: 45px 0 !important;
  }

  .plr_lg--45 {
    padding: 0 45px !important;
  }

  .pt_lg--45 {
    padding-top: 45px !important;
  }

  .pb_lg--45 {
    padding-bottom: 45px !important;
  }

  .pl_lg--45 {
    padding-left: 45px !important;
  }

  .pr_lg--45 {
    padding-right: 45px !important;
  }

  .mt_lg--45 {
    margin-top: 45px !important;
  }

  .mb_lg--45 {
    margin-bottom: 45px !important;
  }

  .ml_lg--45 {
    margin-left: 45px !important;
  }

  .ptb_lg--50 {
    padding: 50px 0 !important;
  }

  .plr_lg--50 {
    padding: 0 50px !important;
  }

  .pt_lg--50 {
    padding-top: 50px !important;
  }

  .pb_lg--50 {
    padding-bottom: 50px !important;
  }

  .pl_lg--50 {
    padding-left: 50px !important;
  }

  .pr_lg--50 {
    padding-right: 50px !important;
  }

  .mt_lg--50 {
    margin-top: 50px !important;
  }

  .mb_lg--50 {
    margin-bottom: 50px !important;
  }

  .ml_lg--50 {
    margin-left: 50px !important;
  }

  .ptb_lg--55 {
    padding: 55px 0 !important;
  }

  .plr_lg--55 {
    padding: 0 55px !important;
  }

  .pt_lg--55 {
    padding-top: 55px !important;
  }

  .pb_lg--55 {
    padding-bottom: 55px !important;
  }

  .pl_lg--55 {
    padding-left: 55px !important;
  }

  .pr_lg--55 {
    padding-right: 55px !important;
  }

  .mt_lg--55 {
    margin-top: 55px !important;
  }

  .mb_lg--55 {
    margin-bottom: 55px !important;
  }

  .ml_lg--55 {
    margin-left: 55px !important;
  }

  .ptb_lg--60 {
    padding: 60px 0 !important;
  }

  .plr_lg--60 {
    padding: 0 60px !important;
  }

  .pt_lg--60 {
    padding-top: 60px !important;
  }

  .pb_lg--60 {
    padding-bottom: 60px !important;
  }

  .pl_lg--60 {
    padding-left: 60px !important;
  }

  .pr_lg--60 {
    padding-right: 60px !important;
  }

  .mt_lg--60 {
    margin-top: 60px !important;
  }

  .mb_lg--60 {
    margin-bottom: 60px !important;
  }

  .ml_lg--60 {
    margin-left: 60px !important;
  }

  .ptb_lg--65 {
    padding: 65px 0 !important;
  }

  .plr_lg--65 {
    padding: 0 65px !important;
  }

  .pt_lg--65 {
    padding-top: 65px !important;
  }

  .pb_lg--65 {
    padding-bottom: 65px !important;
  }

  .pl_lg--65 {
    padding-left: 65px !important;
  }

  .pr_lg--65 {
    padding-right: 65px !important;
  }

  .mt_lg--65 {
    margin-top: 65px !important;
  }

  .mb_lg--65 {
    margin-bottom: 65px !important;
  }

  .ml_lg--65 {
    margin-left: 65px !important;
  }

  .ptb_lg--70 {
    padding: 70px 0 !important;
  }

  .plr_lg--70 {
    padding: 0 70px !important;
  }

  .pt_lg--70 {
    padding-top: 70px !important;
  }

  .pb_lg--70 {
    padding-bottom: 70px !important;
  }

  .pl_lg--70 {
    padding-left: 70px !important;
  }

  .pr_lg--70 {
    padding-right: 70px !important;
  }

  .mt_lg--70 {
    margin-top: 70px !important;
  }

  .mb_lg--70 {
    margin-bottom: 70px !important;
  }

  .ml_lg--70 {
    margin-left: 70px !important;
  }

  .ptb_lg--75 {
    padding: 75px 0 !important;
  }

  .plr_lg--75 {
    padding: 0 75px !important;
  }

  .pt_lg--75 {
    padding-top: 75px !important;
  }

  .pb_lg--75 {
    padding-bottom: 75px !important;
  }

  .pl_lg--75 {
    padding-left: 75px !important;
  }

  .pr_lg--75 {
    padding-right: 75px !important;
  }

  .mt_lg--75 {
    margin-top: 75px !important;
  }

  .mb_lg--75 {
    margin-bottom: 75px !important;
  }

  .ml_lg--75 {
    margin-left: 75px !important;
  }

  .ptb_lg--80 {
    padding: 80px 0 !important;
  }

  .plr_lg--80 {
    padding: 0 80px !important;
  }

  .pt_lg--80 {
    padding-top: 80px !important;
  }

  .pb_lg--80 {
    padding-bottom: 80px !important;
  }

  .pl_lg--80 {
    padding-left: 80px !important;
  }

  .pr_lg--80 {
    padding-right: 80px !important;
  }

  .mt_lg--80 {
    margin-top: 80px !important;
  }

  .mb_lg--80 {
    margin-bottom: 80px !important;
  }

  .ml_lg--80 {
    margin-left: 80px !important;
  }

  .ptb_lg--85 {
    padding: 85px 0 !important;
  }

  .plr_lg--85 {
    padding: 0 85px !important;
  }

  .pt_lg--85 {
    padding-top: 85px !important;
  }

  .pb_lg--85 {
    padding-bottom: 85px !important;
  }

  .pl_lg--85 {
    padding-left: 85px !important;
  }

  .pr_lg--85 {
    padding-right: 85px !important;
  }

  .mt_lg--85 {
    margin-top: 85px !important;
  }

  .mb_lg--85 {
    margin-bottom: 85px !important;
  }

  .ml_lg--85 {
    margin-left: 85px !important;
  }

  .ptb_lg--90 {
    padding: 90px 0 !important;
  }

  .plr_lg--90 {
    padding: 0 90px !important;
  }

  .pt_lg--90 {
    padding-top: 90px !important;
  }

  .pb_lg--90 {
    padding-bottom: 90px !important;
  }

  .pl_lg--90 {
    padding-left: 90px !important;
  }

  .pr_lg--90 {
    padding-right: 90px !important;
  }

  .mt_lg--90 {
    margin-top: 90px !important;
  }

  .mb_lg--90 {
    margin-bottom: 90px !important;
  }

  .ml_lg--90 {
    margin-left: 90px !important;
  }

  .ptb_lg--95 {
    padding: 95px 0 !important;
  }

  .plr_lg--95 {
    padding: 0 95px !important;
  }

  .pt_lg--95 {
    padding-top: 95px !important;
  }

  .pb_lg--95 {
    padding-bottom: 95px !important;
  }

  .pl_lg--95 {
    padding-left: 95px !important;
  }

  .pr_lg--95 {
    padding-right: 95px !important;
  }

  .mt_lg--95 {
    margin-top: 95px !important;
  }

  .mb_lg--95 {
    margin-bottom: 95px !important;
  }

  .ml_lg--95 {
    margin-left: 95px !important;
  }

  .ptb_lg--100 {
    padding: 100px 0 !important;
  }

  .plr_lg--100 {
    padding: 0 100px !important;
  }

  .pt_lg--100 {
    padding-top: 100px !important;
  }

  .pb_lg--100 {
    padding-bottom: 100px !important;
  }

  .pl_lg--100 {
    padding-left: 100px !important;
  }

  .pr_lg--100 {
    padding-right: 100px !important;
  }

  .mt_lg--100 {
    margin-top: 100px !important;
  }

  .mb_lg--100 {
    margin-bottom: 100px !important;
  }

  .ml_lg--100 {
    margin-left: 100px !important;
  }

  .ptb_lg--105 {
    padding: 105px 0 !important;
  }

  .plr_lg--105 {
    padding: 0 105px !important;
  }

  .pt_lg--105 {
    padding-top: 105px !important;
  }

  .pb_lg--105 {
    padding-bottom: 105px !important;
  }

  .pl_lg--105 {
    padding-left: 105px !important;
  }

  .pr_lg--105 {
    padding-right: 105px !important;
  }

  .mt_lg--105 {
    margin-top: 105px !important;
  }

  .mb_lg--105 {
    margin-bottom: 105px !important;
  }

  .ml_lg--105 {
    margin-left: 105px !important;
  }

  .ptb_lg--110 {
    padding: 110px 0 !important;
  }

  .plr_lg--110 {
    padding: 0 110px !important;
  }

  .pt_lg--110 {
    padding-top: 110px !important;
  }

  .pb_lg--110 {
    padding-bottom: 110px !important;
  }

  .pl_lg--110 {
    padding-left: 110px !important;
  }

  .pr_lg--110 {
    padding-right: 110px !important;
  }

  .mt_lg--110 {
    margin-top: 110px !important;
  }

  .mb_lg--110 {
    margin-bottom: 110px !important;
  }

  .ml_lg--110 {
    margin-left: 110px !important;
  }

  .ptb_lg--115 {
    padding: 115px 0 !important;
  }

  .plr_lg--115 {
    padding: 0 115px !important;
  }

  .pt_lg--115 {
    padding-top: 115px !important;
  }

  .pb_lg--115 {
    padding-bottom: 115px !important;
  }

  .pl_lg--115 {
    padding-left: 115px !important;
  }

  .pr_lg--115 {
    padding-right: 115px !important;
  }

  .mt_lg--115 {
    margin-top: 115px !important;
  }

  .mb_lg--115 {
    margin-bottom: 115px !important;
  }

  .ml_lg--115 {
    margin-left: 115px !important;
  }

  .ptb_lg--120 {
    padding: 120px 0 !important;
  }

  .plr_lg--120 {
    padding: 0 120px !important;
  }

  .pt_lg--120 {
    padding-top: 120px !important;
  }

  .pb_lg--120 {
    padding-bottom: 120px !important;
  }

  .pl_lg--120 {
    padding-left: 120px !important;
  }

  .pr_lg--120 {
    padding-right: 120px !important;
  }

  .mt_lg--120 {
    margin-top: 120px !important;
  }

  .mb_lg--120 {
    margin-bottom: 120px !important;
  }

  .ml_lg--120 {
    margin-left: 120px !important;
  }

  .ptb_lg--125 {
    padding: 125px 0 !important;
  }

  .plr_lg--125 {
    padding: 0 125px !important;
  }

  .pt_lg--125 {
    padding-top: 125px !important;
  }

  .pb_lg--125 {
    padding-bottom: 125px !important;
  }

  .pl_lg--125 {
    padding-left: 125px !important;
  }

  .pr_lg--125 {
    padding-right: 125px !important;
  }

  .mt_lg--125 {
    margin-top: 125px !important;
  }

  .mb_lg--125 {
    margin-bottom: 125px !important;
  }

  .ml_lg--125 {
    margin-left: 125px !important;
  }

  .ptb_lg--130 {
    padding: 130px 0 !important;
  }

  .plr_lg--130 {
    padding: 0 130px !important;
  }

  .pt_lg--130 {
    padding-top: 130px !important;
  }

  .pb_lg--130 {
    padding-bottom: 130px !important;
  }

  .pl_lg--130 {
    padding-left: 130px !important;
  }

  .pr_lg--130 {
    padding-right: 130px !important;
  }

  .mt_lg--130 {
    margin-top: 130px !important;
  }

  .mb_lg--130 {
    margin-bottom: 130px !important;
  }

  .ml_lg--130 {
    margin-left: 130px !important;
  }

  .ptb_lg--135 {
    padding: 135px 0 !important;
  }

  .plr_lg--135 {
    padding: 0 135px !important;
  }

  .pt_lg--135 {
    padding-top: 135px !important;
  }

  .pb_lg--135 {
    padding-bottom: 135px !important;
  }

  .pl_lg--135 {
    padding-left: 135px !important;
  }

  .pr_lg--135 {
    padding-right: 135px !important;
  }

  .mt_lg--135 {
    margin-top: 135px !important;
  }

  .mb_lg--135 {
    margin-bottom: 135px !important;
  }

  .ml_lg--135 {
    margin-left: 135px !important;
  }

  .ptb_lg--140 {
    padding: 140px 0 !important;
  }

  .plr_lg--140 {
    padding: 0 140px !important;
  }

  .pt_lg--140 {
    padding-top: 140px !important;
  }

  .pb_lg--140 {
    padding-bottom: 140px !important;
  }

  .pl_lg--140 {
    padding-left: 140px !important;
  }

  .pr_lg--140 {
    padding-right: 140px !important;
  }

  .mt_lg--140 {
    margin-top: 140px !important;
  }

  .mb_lg--140 {
    margin-bottom: 140px !important;
  }

  .ml_lg--140 {
    margin-left: 140px !important;
  }

  .ptb_lg--145 {
    padding: 145px 0 !important;
  }

  .plr_lg--145 {
    padding: 0 145px !important;
  }

  .pt_lg--145 {
    padding-top: 145px !important;
  }

  .pb_lg--145 {
    padding-bottom: 145px !important;
  }

  .pl_lg--145 {
    padding-left: 145px !important;
  }

  .pr_lg--145 {
    padding-right: 145px !important;
  }

  .mt_lg--145 {
    margin-top: 145px !important;
  }

  .mb_lg--145 {
    margin-bottom: 145px !important;
  }

  .ml_lg--145 {
    margin-left: 145px !important;
  }

  .ptb_lg--150 {
    padding: 150px 0 !important;
  }

  .plr_lg--150 {
    padding: 0 150px !important;
  }

  .pt_lg--150 {
    padding-top: 150px !important;
  }

  .pb_lg--150 {
    padding-bottom: 150px !important;
  }

  .pl_lg--150 {
    padding-left: 150px !important;
  }

  .pr_lg--150 {
    padding-right: 150px !important;
  }

  .mt_lg--150 {
    margin-top: 150px !important;
  }

  .mb_lg--150 {
    margin-bottom: 150px !important;
  }

  .ml_lg--150 {
    margin-left: 150px !important;
  }

  .ptb_lg--155 {
    padding: 155px 0 !important;
  }

  .plr_lg--155 {
    padding: 0 155px !important;
  }

  .pt_lg--155 {
    padding-top: 155px !important;
  }

  .pb_lg--155 {
    padding-bottom: 155px !important;
  }

  .pl_lg--155 {
    padding-left: 155px !important;
  }

  .pr_lg--155 {
    padding-right: 155px !important;
  }

  .mt_lg--155 {
    margin-top: 155px !important;
  }

  .mb_lg--155 {
    margin-bottom: 155px !important;
  }

  .ml_lg--155 {
    margin-left: 155px !important;
  }

  .ptb_lg--160 {
    padding: 160px 0 !important;
  }

  .plr_lg--160 {
    padding: 0 160px !important;
  }

  .pt_lg--160 {
    padding-top: 160px !important;
  }

  .pb_lg--160 {
    padding-bottom: 160px !important;
  }

  .pl_lg--160 {
    padding-left: 160px !important;
  }

  .pr_lg--160 {
    padding-right: 160px !important;
  }

  .mt_lg--160 {
    margin-top: 160px !important;
  }

  .mb_lg--160 {
    margin-bottom: 160px !important;
  }

  .ml_lg--160 {
    margin-left: 160px !important;
  }

  .ptb_lg--165 {
    padding: 165px 0 !important;
  }

  .plr_lg--165 {
    padding: 0 165px !important;
  }

  .pt_lg--165 {
    padding-top: 165px !important;
  }

  .pb_lg--165 {
    padding-bottom: 165px !important;
  }

  .pl_lg--165 {
    padding-left: 165px !important;
  }

  .pr_lg--165 {
    padding-right: 165px !important;
  }

  .mt_lg--165 {
    margin-top: 165px !important;
  }

  .mb_lg--165 {
    margin-bottom: 165px !important;
  }

  .ml_lg--165 {
    margin-left: 165px !important;
  }

  .ptb_lg--170 {
    padding: 170px 0 !important;
  }

  .plr_lg--170 {
    padding: 0 170px !important;
  }

  .pt_lg--170 {
    padding-top: 170px !important;
  }

  .pb_lg--170 {
    padding-bottom: 170px !important;
  }

  .pl_lg--170 {
    padding-left: 170px !important;
  }

  .pr_lg--170 {
    padding-right: 170px !important;
  }

  .mt_lg--170 {
    margin-top: 170px !important;
  }

  .mb_lg--170 {
    margin-bottom: 170px !important;
  }

  .ml_lg--170 {
    margin-left: 170px !important;
  }

  .ptb_lg--175 {
    padding: 175px 0 !important;
  }

  .plr_lg--175 {
    padding: 0 175px !important;
  }

  .pt_lg--175 {
    padding-top: 175px !important;
  }

  .pb_lg--175 {
    padding-bottom: 175px !important;
  }

  .pl_lg--175 {
    padding-left: 175px !important;
  }

  .pr_lg--175 {
    padding-right: 175px !important;
  }

  .mt_lg--175 {
    margin-top: 175px !important;
  }

  .mb_lg--175 {
    margin-bottom: 175px !important;
  }

  .ml_lg--175 {
    margin-left: 175px !important;
  }

  .ptb_lg--180 {
    padding: 180px 0 !important;
  }

  .plr_lg--180 {
    padding: 0 180px !important;
  }

  .pt_lg--180 {
    padding-top: 180px !important;
  }

  .pb_lg--180 {
    padding-bottom: 180px !important;
  }

  .pl_lg--180 {
    padding-left: 180px !important;
  }

  .pr_lg--180 {
    padding-right: 180px !important;
  }

  .mt_lg--180 {
    margin-top: 180px !important;
  }

  .mb_lg--180 {
    margin-bottom: 180px !important;
  }

  .ml_lg--180 {
    margin-left: 180px !important;
  }

  .ptb_lg--185 {
    padding: 185px 0 !important;
  }

  .plr_lg--185 {
    padding: 0 185px !important;
  }

  .pt_lg--185 {
    padding-top: 185px !important;
  }

  .pb_lg--185 {
    padding-bottom: 185px !important;
  }

  .pl_lg--185 {
    padding-left: 185px !important;
  }

  .pr_lg--185 {
    padding-right: 185px !important;
  }

  .mt_lg--185 {
    margin-top: 185px !important;
  }

  .mb_lg--185 {
    margin-bottom: 185px !important;
  }

  .ml_lg--185 {
    margin-left: 185px !important;
  }

  .ptb_lg--190 {
    padding: 190px 0 !important;
  }

  .plr_lg--190 {
    padding: 0 190px !important;
  }

  .pt_lg--190 {
    padding-top: 190px !important;
  }

  .pb_lg--190 {
    padding-bottom: 190px !important;
  }

  .pl_lg--190 {
    padding-left: 190px !important;
  }

  .pr_lg--190 {
    padding-right: 190px !important;
  }

  .mt_lg--190 {
    margin-top: 190px !important;
  }

  .mb_lg--190 {
    margin-bottom: 190px !important;
  }

  .ml_lg--190 {
    margin-left: 190px !important;
  }

  .ptb_lg--195 {
    padding: 195px 0 !important;
  }

  .plr_lg--195 {
    padding: 0 195px !important;
  }

  .pt_lg--195 {
    padding-top: 195px !important;
  }

  .pb_lg--195 {
    padding-bottom: 195px !important;
  }

  .pl_lg--195 {
    padding-left: 195px !important;
  }

  .pr_lg--195 {
    padding-right: 195px !important;
  }

  .mt_lg--195 {
    margin-top: 195px !important;
  }

  .mb_lg--195 {
    margin-bottom: 195px !important;
  }

  .ml_lg--195 {
    margin-left: 195px !important;
  }

  .ptb_lg--200 {
    padding: 200px 0 !important;
  }

  .plr_lg--200 {
    padding: 0 200px !important;
  }

  .pt_lg--200 {
    padding-top: 200px !important;
  }

  .pb_lg--200 {
    padding-bottom: 200px !important;
  }

  .pl_lg--200 {
    padding-left: 200px !important;
  }

  .pr_lg--200 {
    padding-right: 200px !important;
  }

  .mt_lg--200 {
    margin-top: 200px !important;
  }

  .mb_lg--200 {
    margin-bottom: 200px !important;
  }

  .ml_lg--200 {
    margin-left: 200px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb_md--0 {
    padding: 0 !important;
  }

  .pl_md--0 {
    padding-left: 0 !important;
  }

  .pr_md--0 {
    padding-right: 0 !important;
  }

  .pt_md--0 {
    padding-top: 0 !important;
  }

  .pb_md--0 {
    padding-bottom: 0 !important;
  }

  .mr_md--0 {
    margin-right: 0 !important;
  }

  .ml_md--0 {
    margin-left: 0 !important;
  }

  .mt_md--0 {
    margin-top: 0 !important;
  }

  .mb_md--0 {
    margin-bottom: 0 !important;
  }

  .ptb_md--250 {
    padding: 250px 0 !important;
  }

  .ptb_md--5 {
    padding: 5px 0 !important;
  }

  .plr_md--5 {
    padding: 0 5px !important;
  }

  .pt_md--5 {
    padding-top: 5px !important;
  }

  .pb_md--5 {
    padding-bottom: 5px !important;
  }

  .pl_md--5 {
    padding-left: 5px !important;
  }

  .pr_md--5 {
    padding-right: 5px !important;
  }

  .mt_md--5 {
    margin-top: 5px !important;
  }

  .mb_md--5 {
    margin-bottom: 5px !important;
  }

  .ptb_md--10 {
    padding: 10px 0 !important;
  }

  .plr_md--10 {
    padding: 0 10px !important;
  }

  .pt_md--10 {
    padding-top: 10px !important;
  }

  .pb_md--10 {
    padding-bottom: 10px !important;
  }

  .pl_md--10 {
    padding-left: 10px !important;
  }

  .pr_md--10 {
    padding-right: 10px !important;
  }

  .mt_md--10 {
    margin-top: 10px !important;
  }

  .mb_md--10 {
    margin-bottom: 10px !important;
  }

  .ptb_md--15 {
    padding: 15px 0 !important;
  }

  .plr_md--15 {
    padding: 0 15px !important;
  }

  .pt_md--15 {
    padding-top: 15px !important;
  }

  .pb_md--15 {
    padding-bottom: 15px !important;
  }

  .pl_md--15 {
    padding-left: 15px !important;
  }

  .pr_md--15 {
    padding-right: 15px !important;
  }

  .mt_md--15 {
    margin-top: 15px !important;
  }

  .mb_md--15 {
    margin-bottom: 15px !important;
  }

  .ptb_md--20 {
    padding: 20px 0 !important;
  }

  .plr_md--20 {
    padding: 0 20px !important;
  }

  .pt_md--20 {
    padding-top: 20px !important;
  }

  .pb_md--20 {
    padding-bottom: 20px !important;
  }

  .pl_md--20 {
    padding-left: 20px !important;
  }

  .pr_md--20 {
    padding-right: 20px !important;
  }

  .mt_md--20 {
    margin-top: 20px !important;
  }

  .mb_md--20 {
    margin-bottom: 20px !important;
  }

  .ptb_md--25 {
    padding: 25px 0 !important;
  }

  .plr_md--25 {
    padding: 0 25px !important;
  }

  .pt_md--25 {
    padding-top: 25px !important;
  }

  .pb_md--25 {
    padding-bottom: 25px !important;
  }

  .pl_md--25 {
    padding-left: 25px !important;
  }

  .pr_md--25 {
    padding-right: 25px !important;
  }

  .mt_md--25 {
    margin-top: 25px !important;
  }

  .mb_md--25 {
    margin-bottom: 25px !important;
  }

  .ptb_md--30 {
    padding: 30px 0 !important;
  }

  .plr_md--30 {
    padding: 0 30px !important;
  }

  .pt_md--30 {
    padding-top: 30px !important;
  }

  .pb_md--30 {
    padding-bottom: 30px !important;
  }

  .pl_md--30 {
    padding-left: 30px !important;
  }

  .pr_md--30 {
    padding-right: 30px !important;
  }

  .mt_md--30 {
    margin-top: 30px !important;
  }

  .mb_md--30 {
    margin-bottom: 30px !important;
  }

  .ptb_md--35 {
    padding: 35px 0 !important;
  }

  .plr_md--35 {
    padding: 0 35px !important;
  }

  .pt_md--35 {
    padding-top: 35px !important;
  }

  .pb_md--35 {
    padding-bottom: 35px !important;
  }

  .pl_md--35 {
    padding-left: 35px !important;
  }

  .pr_md--35 {
    padding-right: 35px !important;
  }

  .mt_md--35 {
    margin-top: 35px !important;
  }

  .mb_md--35 {
    margin-bottom: 35px !important;
  }

  .ptb_md--40 {
    padding: 40px 0 !important;
  }

  .plr_md--40 {
    padding: 0 40px !important;
  }

  .pt_md--40 {
    padding-top: 40px !important;
  }

  .pb_md--40 {
    padding-bottom: 40px !important;
  }

  .pl_md--40 {
    padding-left: 40px !important;
  }

  .pr_md--40 {
    padding-right: 40px !important;
  }

  .mt_md--40 {
    margin-top: 40px !important;
  }

  .mb_md--40 {
    margin-bottom: 40px !important;
  }

  .ptb_md--45 {
    padding: 45px 0 !important;
  }

  .plr_md--45 {
    padding: 0 45px !important;
  }

  .pt_md--45 {
    padding-top: 45px !important;
  }

  .pb_md--45 {
    padding-bottom: 45px !important;
  }

  .pl_md--45 {
    padding-left: 45px !important;
  }

  .pr_md--45 {
    padding-right: 45px !important;
  }

  .mt_md--45 {
    margin-top: 45px !important;
  }

  .mb_md--45 {
    margin-bottom: 45px !important;
  }

  .ptb_md--50 {
    padding: 50px 0 !important;
  }

  .plr_md--50 {
    padding: 0 50px !important;
  }

  .pt_md--50 {
    padding-top: 50px !important;
  }

  .pb_md--50 {
    padding-bottom: 50px !important;
  }

  .pl_md--50 {
    padding-left: 50px !important;
  }

  .pr_md--50 {
    padding-right: 50px !important;
  }

  .mt_md--50 {
    margin-top: 50px !important;
  }

  .mb_md--50 {
    margin-bottom: 50px !important;
  }

  .ptb_md--55 {
    padding: 55px 0 !important;
  }

  .plr_md--55 {
    padding: 0 55px !important;
  }

  .pt_md--55 {
    padding-top: 55px !important;
  }

  .pb_md--55 {
    padding-bottom: 55px !important;
  }

  .pl_md--55 {
    padding-left: 55px !important;
  }

  .pr_md--55 {
    padding-right: 55px !important;
  }

  .mt_md--55 {
    margin-top: 55px !important;
  }

  .mb_md--55 {
    margin-bottom: 55px !important;
  }

  .ptb_md--60 {
    padding: 60px 0 !important;
  }

  .plr_md--60 {
    padding: 0 60px !important;
  }

  .pt_md--60 {
    padding-top: 60px !important;
  }

  .pb_md--60 {
    padding-bottom: 60px !important;
  }

  .pl_md--60 {
    padding-left: 60px !important;
  }

  .pr_md--60 {
    padding-right: 60px !important;
  }

  .mt_md--60 {
    margin-top: 60px !important;
  }

  .mb_md--60 {
    margin-bottom: 60px !important;
  }

  .ptb_md--65 {
    padding: 65px 0 !important;
  }

  .plr_md--65 {
    padding: 0 65px !important;
  }

  .pt_md--65 {
    padding-top: 65px !important;
  }

  .pb_md--65 {
    padding-bottom: 65px !important;
  }

  .pl_md--65 {
    padding-left: 65px !important;
  }

  .pr_md--65 {
    padding-right: 65px !important;
  }

  .mt_md--65 {
    margin-top: 65px !important;
  }

  .mb_md--65 {
    margin-bottom: 65px !important;
  }

  .ptb_md--70 {
    padding: 70px 0 !important;
  }

  .plr_md--70 {
    padding: 0 70px !important;
  }

  .pt_md--70 {
    padding-top: 70px !important;
  }

  .pb_md--70 {
    padding-bottom: 70px !important;
  }

  .pl_md--70 {
    padding-left: 70px !important;
  }

  .pr_md--70 {
    padding-right: 70px !important;
  }

  .mt_md--70 {
    margin-top: 70px !important;
  }

  .mb_md--70 {
    margin-bottom: 70px !important;
  }

  .ptb_md--75 {
    padding: 75px 0 !important;
  }

  .plr_md--75 {
    padding: 0 75px !important;
  }

  .pt_md--75 {
    padding-top: 75px !important;
  }

  .pb_md--75 {
    padding-bottom: 75px !important;
  }

  .pl_md--75 {
    padding-left: 75px !important;
  }

  .pr_md--75 {
    padding-right: 75px !important;
  }

  .mt_md--75 {
    margin-top: 75px !important;
  }

  .mb_md--75 {
    margin-bottom: 75px !important;
  }

  .ptb_md--80 {
    padding: 80px 0 !important;
  }

  .plr_md--80 {
    padding: 0 80px !important;
  }

  .pt_md--80 {
    padding-top: 80px !important;
  }

  .pb_md--80 {
    padding-bottom: 80px !important;
  }

  .pl_md--80 {
    padding-left: 80px !important;
  }

  .pr_md--80 {
    padding-right: 80px !important;
  }

  .mt_md--80 {
    margin-top: 80px !important;
  }

  .mb_md--80 {
    margin-bottom: 80px !important;
  }

  .ptb_md--85 {
    padding: 85px 0 !important;
  }

  .plr_md--85 {
    padding: 0 85px !important;
  }

  .pt_md--85 {
    padding-top: 85px !important;
  }

  .pb_md--85 {
    padding-bottom: 85px !important;
  }

  .pl_md--85 {
    padding-left: 85px !important;
  }

  .pr_md--85 {
    padding-right: 85px !important;
  }

  .mt_md--85 {
    margin-top: 85px !important;
  }

  .mb_md--85 {
    margin-bottom: 85px !important;
  }

  .ptb_md--90 {
    padding: 90px 0 !important;
  }

  .plr_md--90 {
    padding: 0 90px !important;
  }

  .pt_md--90 {
    padding-top: 90px !important;
  }

  .pb_md--90 {
    padding-bottom: 90px !important;
  }

  .pl_md--90 {
    padding-left: 90px !important;
  }

  .pr_md--90 {
    padding-right: 90px !important;
  }

  .mt_md--90 {
    margin-top: 90px !important;
  }

  .mb_md--90 {
    margin-bottom: 90px !important;
  }

  .ptb_md--95 {
    padding: 95px 0 !important;
  }

  .plr_md--95 {
    padding: 0 95px !important;
  }

  .pt_md--95 {
    padding-top: 95px !important;
  }

  .pb_md--95 {
    padding-bottom: 95px !important;
  }

  .pl_md--95 {
    padding-left: 95px !important;
  }

  .pr_md--95 {
    padding-right: 95px !important;
  }

  .mt_md--95 {
    margin-top: 95px !important;
  }

  .mb_md--95 {
    margin-bottom: 95px !important;
  }

  .ptb_md--100 {
    padding: 100px 0 !important;
  }

  .plr_md--100 {
    padding: 0 100px !important;
  }

  .pt_md--100 {
    padding-top: 100px !important;
  }

  .pb_md--100 {
    padding-bottom: 100px !important;
  }

  .pl_md--100 {
    padding-left: 100px !important;
  }

  .pr_md--100 {
    padding-right: 100px !important;
  }

  .mt_md--100 {
    margin-top: 100px !important;
  }

  .mb_md--100 {
    margin-bottom: 100px !important;
  }

  .ptb_md--105 {
    padding: 105px 0 !important;
  }

  .plr_md--105 {
    padding: 0 105px !important;
  }

  .pt_md--105 {
    padding-top: 105px !important;
  }

  .pb_md--105 {
    padding-bottom: 105px !important;
  }

  .pl_md--105 {
    padding-left: 105px !important;
  }

  .pr_md--105 {
    padding-right: 105px !important;
  }

  .mt_md--105 {
    margin-top: 105px !important;
  }

  .mb_md--105 {
    margin-bottom: 105px !important;
  }

  .ptb_md--110 {
    padding: 110px 0 !important;
  }

  .plr_md--110 {
    padding: 0 110px !important;
  }

  .pt_md--110 {
    padding-top: 110px !important;
  }

  .pb_md--110 {
    padding-bottom: 110px !important;
  }

  .pl_md--110 {
    padding-left: 110px !important;
  }

  .pr_md--110 {
    padding-right: 110px !important;
  }

  .mt_md--110 {
    margin-top: 110px !important;
  }

  .mb_md--110 {
    margin-bottom: 110px !important;
  }

  .ptb_md--115 {
    padding: 115px 0 !important;
  }

  .plr_md--115 {
    padding: 0 115px !important;
  }

  .pt_md--115 {
    padding-top: 115px !important;
  }

  .pb_md--115 {
    padding-bottom: 115px !important;
  }

  .pl_md--115 {
    padding-left: 115px !important;
  }

  .pr_md--115 {
    padding-right: 115px !important;
  }

  .mt_md--115 {
    margin-top: 115px !important;
  }

  .mb_md--115 {
    margin-bottom: 115px !important;
  }

  .ptb_md--120 {
    padding: 120px 0 !important;
  }

  .plr_md--120 {
    padding: 0 120px !important;
  }

  .pt_md--120 {
    padding-top: 120px !important;
  }

  .pb_md--120 {
    padding-bottom: 120px !important;
  }

  .pl_md--120 {
    padding-left: 120px !important;
  }

  .pr_md--120 {
    padding-right: 120px !important;
  }

  .mt_md--120 {
    margin-top: 120px !important;
  }

  .mb_md--120 {
    margin-bottom: 120px !important;
  }

  .ptb_md--125 {
    padding: 125px 0 !important;
  }

  .plr_md--125 {
    padding: 0 125px !important;
  }

  .pt_md--125 {
    padding-top: 125px !important;
  }

  .pb_md--125 {
    padding-bottom: 125px !important;
  }

  .pl_md--125 {
    padding-left: 125px !important;
  }

  .pr_md--125 {
    padding-right: 125px !important;
  }

  .mt_md--125 {
    margin-top: 125px !important;
  }

  .mb_md--125 {
    margin-bottom: 125px !important;
  }

  .ptb_md--130 {
    padding: 130px 0 !important;
  }

  .plr_md--130 {
    padding: 0 130px !important;
  }

  .pt_md--130 {
    padding-top: 130px !important;
  }

  .pb_md--130 {
    padding-bottom: 130px !important;
  }

  .pl_md--130 {
    padding-left: 130px !important;
  }

  .pr_md--130 {
    padding-right: 130px !important;
  }

  .mt_md--130 {
    margin-top: 130px !important;
  }

  .mb_md--130 {
    margin-bottom: 130px !important;
  }

  .ptb_md--135 {
    padding: 135px 0 !important;
  }

  .plr_md--135 {
    padding: 0 135px !important;
  }

  .pt_md--135 {
    padding-top: 135px !important;
  }

  .pb_md--135 {
    padding-bottom: 135px !important;
  }

  .pl_md--135 {
    padding-left: 135px !important;
  }

  .pr_md--135 {
    padding-right: 135px !important;
  }

  .mt_md--135 {
    margin-top: 135px !important;
  }

  .mb_md--135 {
    margin-bottom: 135px !important;
  }

  .ptb_md--140 {
    padding: 140px 0 !important;
  }

  .plr_md--140 {
    padding: 0 140px !important;
  }

  .pt_md--140 {
    padding-top: 140px !important;
  }

  .pb_md--140 {
    padding-bottom: 140px !important;
  }

  .pl_md--140 {
    padding-left: 140px !important;
  }

  .pr_md--140 {
    padding-right: 140px !important;
  }

  .mt_md--140 {
    margin-top: 140px !important;
  }

  .mb_md--140 {
    margin-bottom: 140px !important;
  }

  .ptb_md--145 {
    padding: 145px 0 !important;
  }

  .plr_md--145 {
    padding: 0 145px !important;
  }

  .pt_md--145 {
    padding-top: 145px !important;
  }

  .pb_md--145 {
    padding-bottom: 145px !important;
  }

  .pl_md--145 {
    padding-left: 145px !important;
  }

  .pr_md--145 {
    padding-right: 145px !important;
  }

  .mt_md--145 {
    margin-top: 145px !important;
  }

  .mb_md--145 {
    margin-bottom: 145px !important;
  }

  .ptb_md--150 {
    padding: 150px 0 !important;
  }

  .plr_md--150 {
    padding: 0 150px !important;
  }

  .pt_md--150 {
    padding-top: 150px !important;
  }

  .pb_md--150 {
    padding-bottom: 150px !important;
  }

  .pl_md--150 {
    padding-left: 150px !important;
  }

  .pr_md--150 {
    padding-right: 150px !important;
  }

  .mt_md--150 {
    margin-top: 150px !important;
  }

  .mb_md--150 {
    margin-bottom: 150px !important;
  }

  .ptb_md--155 {
    padding: 155px 0 !important;
  }

  .plr_md--155 {
    padding: 0 155px !important;
  }

  .pt_md--155 {
    padding-top: 155px !important;
  }

  .pb_md--155 {
    padding-bottom: 155px !important;
  }

  .pl_md--155 {
    padding-left: 155px !important;
  }

  .pr_md--155 {
    padding-right: 155px !important;
  }

  .mt_md--155 {
    margin-top: 155px !important;
  }

  .mb_md--155 {
    margin-bottom: 155px !important;
  }

  .ptb_md--160 {
    padding: 160px 0 !important;
  }

  .plr_md--160 {
    padding: 0 160px !important;
  }

  .pt_md--160 {
    padding-top: 160px !important;
  }

  .pb_md--160 {
    padding-bottom: 160px !important;
  }

  .pl_md--160 {
    padding-left: 160px !important;
  }

  .pr_md--160 {
    padding-right: 160px !important;
  }

  .mt_md--160 {
    margin-top: 160px !important;
  }

  .mb_md--160 {
    margin-bottom: 160px !important;
  }

  .ptb_md--165 {
    padding: 165px 0 !important;
  }

  .plr_md--165 {
    padding: 0 165px !important;
  }

  .pt_md--165 {
    padding-top: 165px !important;
  }

  .pb_md--165 {
    padding-bottom: 165px !important;
  }

  .pl_md--165 {
    padding-left: 165px !important;
  }

  .pr_md--165 {
    padding-right: 165px !important;
  }

  .mt_md--165 {
    margin-top: 165px !important;
  }

  .mb_md--165 {
    margin-bottom: 165px !important;
  }

  .ptb_md--170 {
    padding: 170px 0 !important;
  }

  .plr_md--170 {
    padding: 0 170px !important;
  }

  .pt_md--170 {
    padding-top: 170px !important;
  }

  .pb_md--170 {
    padding-bottom: 170px !important;
  }

  .pl_md--170 {
    padding-left: 170px !important;
  }

  .pr_md--170 {
    padding-right: 170px !important;
  }

  .mt_md--170 {
    margin-top: 170px !important;
  }

  .mb_md--170 {
    margin-bottom: 170px !important;
  }

  .ptb_md--175 {
    padding: 175px 0 !important;
  }

  .plr_md--175 {
    padding: 0 175px !important;
  }

  .pt_md--175 {
    padding-top: 175px !important;
  }

  .pb_md--175 {
    padding-bottom: 175px !important;
  }

  .pl_md--175 {
    padding-left: 175px !important;
  }

  .pr_md--175 {
    padding-right: 175px !important;
  }

  .mt_md--175 {
    margin-top: 175px !important;
  }

  .mb_md--175 {
    margin-bottom: 175px !important;
  }

  .ptb_md--180 {
    padding: 180px 0 !important;
  }

  .plr_md--180 {
    padding: 0 180px !important;
  }

  .pt_md--180 {
    padding-top: 180px !important;
  }

  .pb_md--180 {
    padding-bottom: 180px !important;
  }

  .pl_md--180 {
    padding-left: 180px !important;
  }

  .pr_md--180 {
    padding-right: 180px !important;
  }

  .mt_md--180 {
    margin-top: 180px !important;
  }

  .mb_md--180 {
    margin-bottom: 180px !important;
  }

  .ptb_md--185 {
    padding: 185px 0 !important;
  }

  .plr_md--185 {
    padding: 0 185px !important;
  }

  .pt_md--185 {
    padding-top: 185px !important;
  }

  .pb_md--185 {
    padding-bottom: 185px !important;
  }

  .pl_md--185 {
    padding-left: 185px !important;
  }

  .pr_md--185 {
    padding-right: 185px !important;
  }

  .mt_md--185 {
    margin-top: 185px !important;
  }

  .mb_md--185 {
    margin-bottom: 185px !important;
  }

  .ptb_md--190 {
    padding: 190px 0 !important;
  }

  .plr_md--190 {
    padding: 0 190px !important;
  }

  .pt_md--190 {
    padding-top: 190px !important;
  }

  .pb_md--190 {
    padding-bottom: 190px !important;
  }

  .pl_md--190 {
    padding-left: 190px !important;
  }

  .pr_md--190 {
    padding-right: 190px !important;
  }

  .mt_md--190 {
    margin-top: 190px !important;
  }

  .mb_md--190 {
    margin-bottom: 190px !important;
  }

  .ptb_md--195 {
    padding: 195px 0 !important;
  }

  .plr_md--195 {
    padding: 0 195px !important;
  }

  .pt_md--195 {
    padding-top: 195px !important;
  }

  .pb_md--195 {
    padding-bottom: 195px !important;
  }

  .pl_md--195 {
    padding-left: 195px !important;
  }

  .pr_md--195 {
    padding-right: 195px !important;
  }

  .mt_md--195 {
    margin-top: 195px !important;
  }

  .mb_md--195 {
    margin-bottom: 195px !important;
  }

  .ptb_md--200 {
    padding: 200px 0 !important;
  }

  .plr_md--200 {
    padding: 0 200px !important;
  }

  .pt_md--200 {
    padding-top: 200px !important;
  }

  .pb_md--200 {
    padding-bottom: 200px !important;
  }

  .pl_md--200 {
    padding-left: 200px !important;
  }

  .pr_md--200 {
    padding-right: 200px !important;
  }

  .mt_md--200 {
    margin-top: 200px !important;
  }

  .mb_md--200 {
    margin-bottom: 200px !important;
  }
}

@media only screen and (max-width: 767px) {
  .ptb_sm--250 {
    padding: 250px 0 !important;
  }

  .ptb_sm--0 {
    padding: 0 !important;
  }

  .pl_sm--0 {
    padding-left: 0 !important;
  }

  .pr_sm--0 {
    padding-right: 0 !important;
  }

  .pt_sm--0 {
    padding-top: 0 !important;
  }

  .pb_sm--0 {
    padding-bottom: 0 !important;
  }

  .mr_sm--0 {
    margin-right: 0 !important;
  }

  .ml_sm--0 {
    margin-left: 0 !important;
  }

  .mt_sm--0 {
    margin-top: 0 !important;
  }

  .mb_sm--0 {
    margin-bottom: 0 !important;
  }

  .pt_sm--150 {
    padding-top: 150px !important;
  }

  .pb_sm--110 {
    padding-bottom: 110px !important;
  }

  .ptb_sm--5 {
    padding: 5px 0 !important;
  }

  .plr_sm--5 {
    padding: 0 5px !important;
  }

  .pt_sm--5 {
    padding-top: 5px !important;
  }

  .pb_sm--5 {
    padding-bottom: 5px !important;
  }

  .pl_sm--5 {
    padding-left: 5px !important;
  }

  .pr_sm--5 {
    padding-right: 5px !important;
  }

  .mt_sm--5 {
    margin-top: 5px !important;
  }

  .ml_sm--5 {
    margin-left: 5px !important;
  }

  .mr_sm--5 {
    margin-right: 5px !important;
  }

  .mb_sm--5 {
    margin-bottom: 5px !important;
  }

  .ptb_sm--10 {
    padding: 10px 0 !important;
  }

  .plr_sm--10 {
    padding: 0 10px !important;
  }

  .pt_sm--10 {
    padding-top: 10px !important;
  }

  .pb_sm--10 {
    padding-bottom: 10px !important;
  }

  .pl_sm--10 {
    padding-left: 10px !important;
  }

  .pr_sm--10 {
    padding-right: 10px !important;
  }

  .mt_sm--10 {
    margin-top: 10px !important;
  }

  .ml_sm--10 {
    margin-left: 10px !important;
  }

  .mr_sm--10 {
    margin-right: 10px !important;
  }

  .mb_sm--10 {
    margin-bottom: 10px !important;
  }

  .ptb_sm--15 {
    padding: 15px 0 !important;
  }

  .plr_sm--15 {
    padding: 0 15px !important;
  }

  .pt_sm--15 {
    padding-top: 15px !important;
  }

  .pb_sm--15 {
    padding-bottom: 15px !important;
  }

  .pl_sm--15 {
    padding-left: 15px !important;
  }

  .pr_sm--15 {
    padding-right: 15px !important;
  }

  .mt_sm--15 {
    margin-top: 15px !important;
  }

  .ml_sm--15 {
    margin-left: 15px !important;
  }

  .mr_sm--15 {
    margin-right: 15px !important;
  }

  .mb_sm--15 {
    margin-bottom: 15px !important;
  }

  .ptb_sm--20 {
    padding: 20px 0 !important;
  }

  .plr_sm--20 {
    padding: 0 20px !important;
  }

  .pt_sm--20 {
    padding-top: 20px !important;
  }

  .pb_sm--20 {
    padding-bottom: 20px !important;
  }

  .pl_sm--20 {
    padding-left: 20px !important;
  }

  .pr_sm--20 {
    padding-right: 20px !important;
  }

  .mt_sm--20 {
    margin-top: 20px !important;
  }

  .ml_sm--20 {
    margin-left: 20px !important;
  }

  .mr_sm--20 {
    margin-right: 20px !important;
  }

  .mb_sm--20 {
    margin-bottom: 20px !important;
  }

  .ptb_sm--25 {
    padding: 25px 0 !important;
  }

  .plr_sm--25 {
    padding: 0 25px !important;
  }

  .pt_sm--25 {
    padding-top: 25px !important;
  }

  .pb_sm--25 {
    padding-bottom: 25px !important;
  }

  .pl_sm--25 {
    padding-left: 25px !important;
  }

  .pr_sm--25 {
    padding-right: 25px !important;
  }

  .mt_sm--25 {
    margin-top: 25px !important;
  }

  .ml_sm--25 {
    margin-left: 25px !important;
  }

  .mr_sm--25 {
    margin-right: 25px !important;
  }

  .mb_sm--25 {
    margin-bottom: 25px !important;
  }

  .ptb_sm--30 {
    padding: 30px 0 !important;
  }

  .plr_sm--30 {
    padding: 0 30px !important;
  }

  .pt_sm--30 {
    padding-top: 30px !important;
  }

  .pb_sm--30 {
    padding-bottom: 30px !important;
  }

  .pl_sm--30 {
    padding-left: 30px !important;
  }

  .pr_sm--30 {
    padding-right: 30px !important;
  }

  .mt_sm--30 {
    margin-top: 30px !important;
  }

  .ml_sm--30 {
    margin-left: 30px !important;
  }

  .mr_sm--30 {
    margin-right: 30px !important;
  }

  .mb_sm--30 {
    margin-bottom: 30px !important;
  }

  .ptb_sm--35 {
    padding: 35px 0 !important;
  }

  .plr_sm--35 {
    padding: 0 35px !important;
  }

  .pt_sm--35 {
    padding-top: 35px !important;
  }

  .pb_sm--35 {
    padding-bottom: 35px !important;
  }

  .pl_sm--35 {
    padding-left: 35px !important;
  }

  .pr_sm--35 {
    padding-right: 35px !important;
  }

  .mt_sm--35 {
    margin-top: 35px !important;
  }

  .ml_sm--35 {
    margin-left: 35px !important;
  }

  .mr_sm--35 {
    margin-right: 35px !important;
  }

  .mb_sm--35 {
    margin-bottom: 35px !important;
  }

  .ptb_sm--40 {
    padding: 40px 0 !important;
  }

  .plr_sm--40 {
    padding: 0 40px !important;
  }

  .pt_sm--40 {
    padding-top: 40px !important;
  }

  .pb_sm--40 {
    padding-bottom: 40px !important;
  }

  .pl_sm--40 {
    padding-left: 40px !important;
  }

  .pr_sm--40 {
    padding-right: 40px !important;
  }

  .mt_sm--40 {
    margin-top: 40px !important;
  }

  .ml_sm--40 {
    margin-left: 40px !important;
  }

  .mr_sm--40 {
    margin-right: 40px !important;
  }

  .mb_sm--40 {
    margin-bottom: 40px !important;
  }

  .ptb_sm--45 {
    padding: 45px 0 !important;
  }

  .plr_sm--45 {
    padding: 0 45px !important;
  }

  .pt_sm--45 {
    padding-top: 45px !important;
  }

  .pb_sm--45 {
    padding-bottom: 45px !important;
  }

  .pl_sm--45 {
    padding-left: 45px !important;
  }

  .pr_sm--45 {
    padding-right: 45px !important;
  }

  .mt_sm--45 {
    margin-top: 45px !important;
  }

  .ml_sm--45 {
    margin-left: 45px !important;
  }

  .mr_sm--45 {
    margin-right: 45px !important;
  }

  .mb_sm--45 {
    margin-bottom: 45px !important;
  }

  .ptb_sm--50 {
    padding: 50px 0 !important;
  }

  .plr_sm--50 {
    padding: 0 50px !important;
  }

  .pt_sm--50 {
    padding-top: 50px !important;
  }

  .pb_sm--50 {
    padding-bottom: 50px !important;
  }

  .pl_sm--50 {
    padding-left: 50px !important;
  }

  .pr_sm--50 {
    padding-right: 50px !important;
  }

  .mt_sm--50 {
    margin-top: 50px !important;
  }

  .ml_sm--50 {
    margin-left: 50px !important;
  }

  .mr_sm--50 {
    margin-right: 50px !important;
  }

  .mb_sm--50 {
    margin-bottom: 50px !important;
  }

  .ptb_sm--55 {
    padding: 55px 0 !important;
  }

  .plr_sm--55 {
    padding: 0 55px !important;
  }

  .pt_sm--55 {
    padding-top: 55px !important;
  }

  .pb_sm--55 {
    padding-bottom: 55px !important;
  }

  .pl_sm--55 {
    padding-left: 55px !important;
  }

  .pr_sm--55 {
    padding-right: 55px !important;
  }

  .mt_sm--55 {
    margin-top: 55px !important;
  }

  .ml_sm--55 {
    margin-left: 55px !important;
  }

  .mr_sm--55 {
    margin-right: 55px !important;
  }

  .mb_sm--55 {
    margin-bottom: 55px !important;
  }

  .ptb_sm--60 {
    padding: 60px 0 !important;
  }

  .plr_sm--60 {
    padding: 0 60px !important;
  }

  .pt_sm--60 {
    padding-top: 60px !important;
  }

  .pb_sm--60 {
    padding-bottom: 60px !important;
  }

  .pl_sm--60 {
    padding-left: 60px !important;
  }

  .pr_sm--60 {
    padding-right: 60px !important;
  }

  .mt_sm--60 {
    margin-top: 60px !important;
  }

  .ml_sm--60 {
    margin-left: 60px !important;
  }

  .mr_sm--60 {
    margin-right: 60px !important;
  }

  .mb_sm--60 {
    margin-bottom: 60px !important;
  }

  .ptb_sm--65 {
    padding: 65px 0 !important;
  }

  .plr_sm--65 {
    padding: 0 65px !important;
  }

  .pt_sm--65 {
    padding-top: 65px !important;
  }

  .pb_sm--65 {
    padding-bottom: 65px !important;
  }

  .pl_sm--65 {
    padding-left: 65px !important;
  }

  .pr_sm--65 {
    padding-right: 65px !important;
  }

  .mt_sm--65 {
    margin-top: 65px !important;
  }

  .ml_sm--65 {
    margin-left: 65px !important;
  }

  .mr_sm--65 {
    margin-right: 65px !important;
  }

  .mb_sm--65 {
    margin-bottom: 65px !important;
  }

  .ptb_sm--70 {
    padding: 70px 0 !important;
  }

  .plr_sm--70 {
    padding: 0 70px !important;
  }

  .pt_sm--70 {
    padding-top: 70px !important;
  }

  .pb_sm--70 {
    padding-bottom: 70px !important;
  }

  .pl_sm--70 {
    padding-left: 70px !important;
  }

  .pr_sm--70 {
    padding-right: 70px !important;
  }

  .mt_sm--70 {
    margin-top: 70px !important;
  }

  .ml_sm--70 {
    margin-left: 70px !important;
  }

  .mr_sm--70 {
    margin-right: 70px !important;
  }

  .mb_sm--70 {
    margin-bottom: 70px !important;
  }

  .ptb_sm--75 {
    padding: 75px 0 !important;
  }

  .plr_sm--75 {
    padding: 0 75px !important;
  }

  .pt_sm--75 {
    padding-top: 75px !important;
  }

  .pb_sm--75 {
    padding-bottom: 75px !important;
  }

  .pl_sm--75 {
    padding-left: 75px !important;
  }

  .pr_sm--75 {
    padding-right: 75px !important;
  }

  .mt_sm--75 {
    margin-top: 75px !important;
  }

  .ml_sm--75 {
    margin-left: 75px !important;
  }

  .mr_sm--75 {
    margin-right: 75px !important;
  }

  .mb_sm--75 {
    margin-bottom: 75px !important;
  }

  .ptb_sm--80 {
    padding: 80px 0 !important;
  }

  .plr_sm--80 {
    padding: 0 80px !important;
  }

  .pt_sm--80 {
    padding-top: 80px !important;
  }

  .pb_sm--80 {
    padding-bottom: 80px !important;
  }

  .pl_sm--80 {
    padding-left: 80px !important;
  }

  .pr_sm--80 {
    padding-right: 80px !important;
  }

  .mt_sm--80 {
    margin-top: 80px !important;
  }

  .ml_sm--80 {
    margin-left: 80px !important;
  }

  .mr_sm--80 {
    margin-right: 80px !important;
  }

  .mb_sm--80 {
    margin-bottom: 80px !important;
  }

  .ptb_sm--85 {
    padding: 85px 0 !important;
  }

  .plr_sm--85 {
    padding: 0 85px !important;
  }

  .pt_sm--85 {
    padding-top: 85px !important;
  }

  .pb_sm--85 {
    padding-bottom: 85px !important;
  }

  .pl_sm--85 {
    padding-left: 85px !important;
  }

  .pr_sm--85 {
    padding-right: 85px !important;
  }

  .mt_sm--85 {
    margin-top: 85px !important;
  }

  .ml_sm--85 {
    margin-left: 85px !important;
  }

  .mr_sm--85 {
    margin-right: 85px !important;
  }

  .mb_sm--85 {
    margin-bottom: 85px !important;
  }

  .ptb_sm--90 {
    padding: 90px 0 !important;
  }

  .plr_sm--90 {
    padding: 0 90px !important;
  }

  .pt_sm--90 {
    padding-top: 90px !important;
  }

  .pb_sm--90 {
    padding-bottom: 90px !important;
  }

  .pl_sm--90 {
    padding-left: 90px !important;
  }

  .pr_sm--90 {
    padding-right: 90px !important;
  }

  .mt_sm--90 {
    margin-top: 90px !important;
  }

  .ml_sm--90 {
    margin-left: 90px !important;
  }

  .mr_sm--90 {
    margin-right: 90px !important;
  }

  .mb_sm--90 {
    margin-bottom: 90px !important;
  }

  .ptb_sm--95 {
    padding: 95px 0 !important;
  }

  .plr_sm--95 {
    padding: 0 95px !important;
  }

  .pt_sm--95 {
    padding-top: 95px !important;
  }

  .pb_sm--95 {
    padding-bottom: 95px !important;
  }

  .pl_sm--95 {
    padding-left: 95px !important;
  }

  .pr_sm--95 {
    padding-right: 95px !important;
  }

  .mt_sm--95 {
    margin-top: 95px !important;
  }

  .ml_sm--95 {
    margin-left: 95px !important;
  }

  .mr_sm--95 {
    margin-right: 95px !important;
  }

  .mb_sm--95 {
    margin-bottom: 95px !important;
  }

  .ptb_sm--100 {
    padding: 100px 0 !important;
  }

  .plr_sm--100 {
    padding: 0 100px !important;
  }

  .pt_sm--100 {
    padding-top: 100px !important;
  }

  .pb_sm--100 {
    padding-bottom: 100px !important;
  }

  .pl_sm--100 {
    padding-left: 100px !important;
  }

  .pr_sm--100 {
    padding-right: 100px !important;
  }

  .mt_sm--100 {
    margin-top: 100px !important;
  }

  .ml_sm--100 {
    margin-left: 100px !important;
  }

  .mr_sm--100 {
    margin-right: 100px !important;
  }

  .mb_sm--100 {
    margin-bottom: 100px !important;
  }

  .ptb_sm--105 {
    padding: 105px 0 !important;
  }

  .plr_sm--105 {
    padding: 0 105px !important;
  }

  .pt_sm--105 {
    padding-top: 105px !important;
  }

  .pb_sm--105 {
    padding-bottom: 105px !important;
  }

  .pl_sm--105 {
    padding-left: 105px !important;
  }

  .pr_sm--105 {
    padding-right: 105px !important;
  }

  .mt_sm--105 {
    margin-top: 105px !important;
  }

  .ml_sm--105 {
    margin-left: 105px !important;
  }

  .mr_sm--105 {
    margin-right: 105px !important;
  }

  .mb_sm--105 {
    margin-bottom: 105px !important;
  }

  .ptb_sm--110 {
    padding: 110px 0 !important;
  }

  .plr_sm--110 {
    padding: 0 110px !important;
  }

  .pt_sm--110 {
    padding-top: 110px !important;
  }

  .pb_sm--110 {
    padding-bottom: 110px !important;
  }

  .pl_sm--110 {
    padding-left: 110px !important;
  }

  .pr_sm--110 {
    padding-right: 110px !important;
  }

  .mt_sm--110 {
    margin-top: 110px !important;
  }

  .ml_sm--110 {
    margin-left: 110px !important;
  }

  .mr_sm--110 {
    margin-right: 110px !important;
  }

  .mb_sm--110 {
    margin-bottom: 110px !important;
  }

  .ptb_sm--115 {
    padding: 115px 0 !important;
  }

  .plr_sm--115 {
    padding: 0 115px !important;
  }

  .pt_sm--115 {
    padding-top: 115px !important;
  }

  .pb_sm--115 {
    padding-bottom: 115px !important;
  }

  .pl_sm--115 {
    padding-left: 115px !important;
  }

  .pr_sm--115 {
    padding-right: 115px !important;
  }

  .mt_sm--115 {
    margin-top: 115px !important;
  }

  .ml_sm--115 {
    margin-left: 115px !important;
  }

  .mr_sm--115 {
    margin-right: 115px !important;
  }

  .mb_sm--115 {
    margin-bottom: 115px !important;
  }

  .ptb_sm--120 {
    padding: 120px 0 !important;
  }

  .plr_sm--120 {
    padding: 0 120px !important;
  }

  .pt_sm--120 {
    padding-top: 120px !important;
  }

  .pb_sm--120 {
    padding-bottom: 120px !important;
  }

  .pl_sm--120 {
    padding-left: 120px !important;
  }

  .pr_sm--120 {
    padding-right: 120px !important;
  }

  .mt_sm--120 {
    margin-top: 120px !important;
  }

  .ml_sm--120 {
    margin-left: 120px !important;
  }

  .mr_sm--120 {
    margin-right: 120px !important;
  }

  .mb_sm--120 {
    margin-bottom: 120px !important;
  }

  .ptb_sm--125 {
    padding: 125px 0 !important;
  }

  .plr_sm--125 {
    padding: 0 125px !important;
  }

  .pt_sm--125 {
    padding-top: 125px !important;
  }

  .pb_sm--125 {
    padding-bottom: 125px !important;
  }

  .pl_sm--125 {
    padding-left: 125px !important;
  }

  .pr_sm--125 {
    padding-right: 125px !important;
  }

  .mt_sm--125 {
    margin-top: 125px !important;
  }

  .ml_sm--125 {
    margin-left: 125px !important;
  }

  .mr_sm--125 {
    margin-right: 125px !important;
  }

  .mb_sm--125 {
    margin-bottom: 125px !important;
  }

  .ptb_sm--130 {
    padding: 130px 0 !important;
  }

  .plr_sm--130 {
    padding: 0 130px !important;
  }

  .pt_sm--130 {
    padding-top: 130px !important;
  }

  .pb_sm--130 {
    padding-bottom: 130px !important;
  }

  .pl_sm--130 {
    padding-left: 130px !important;
  }

  .pr_sm--130 {
    padding-right: 130px !important;
  }

  .mt_sm--130 {
    margin-top: 130px !important;
  }

  .ml_sm--130 {
    margin-left: 130px !important;
  }

  .mr_sm--130 {
    margin-right: 130px !important;
  }

  .mb_sm--130 {
    margin-bottom: 130px !important;
  }

  .ptb_sm--135 {
    padding: 135px 0 !important;
  }

  .plr_sm--135 {
    padding: 0 135px !important;
  }

  .pt_sm--135 {
    padding-top: 135px !important;
  }

  .pb_sm--135 {
    padding-bottom: 135px !important;
  }

  .pl_sm--135 {
    padding-left: 135px !important;
  }

  .pr_sm--135 {
    padding-right: 135px !important;
  }

  .mt_sm--135 {
    margin-top: 135px !important;
  }

  .ml_sm--135 {
    margin-left: 135px !important;
  }

  .mr_sm--135 {
    margin-right: 135px !important;
  }

  .mb_sm--135 {
    margin-bottom: 135px !important;
  }

  .ptb_sm--140 {
    padding: 140px 0 !important;
  }

  .plr_sm--140 {
    padding: 0 140px !important;
  }

  .pt_sm--140 {
    padding-top: 140px !important;
  }

  .pb_sm--140 {
    padding-bottom: 140px !important;
  }

  .pl_sm--140 {
    padding-left: 140px !important;
  }

  .pr_sm--140 {
    padding-right: 140px !important;
  }

  .mt_sm--140 {
    margin-top: 140px !important;
  }

  .ml_sm--140 {
    margin-left: 140px !important;
  }

  .mr_sm--140 {
    margin-right: 140px !important;
  }

  .mb_sm--140 {
    margin-bottom: 140px !important;
  }

  .ptb_sm--145 {
    padding: 145px 0 !important;
  }

  .plr_sm--145 {
    padding: 0 145px !important;
  }

  .pt_sm--145 {
    padding-top: 145px !important;
  }

  .pb_sm--145 {
    padding-bottom: 145px !important;
  }

  .pl_sm--145 {
    padding-left: 145px !important;
  }

  .pr_sm--145 {
    padding-right: 145px !important;
  }

  .mt_sm--145 {
    margin-top: 145px !important;
  }

  .ml_sm--145 {
    margin-left: 145px !important;
  }

  .mr_sm--145 {
    margin-right: 145px !important;
  }

  .mb_sm--145 {
    margin-bottom: 145px !important;
  }

  .ptb_sm--150 {
    padding: 150px 0 !important;
  }

  .plr_sm--150 {
    padding: 0 150px !important;
  }

  .pt_sm--150 {
    padding-top: 150px !important;
  }

  .pb_sm--150 {
    padding-bottom: 150px !important;
  }

  .pl_sm--150 {
    padding-left: 150px !important;
  }

  .pr_sm--150 {
    padding-right: 150px !important;
  }

  .mt_sm--150 {
    margin-top: 150px !important;
  }

  .ml_sm--150 {
    margin-left: 150px !important;
  }

  .mr_sm--150 {
    margin-right: 150px !important;
  }

  .mb_sm--150 {
    margin-bottom: 150px !important;
  }

  .ptb_sm--155 {
    padding: 155px 0 !important;
  }

  .plr_sm--155 {
    padding: 0 155px !important;
  }

  .pt_sm--155 {
    padding-top: 155px !important;
  }

  .pb_sm--155 {
    padding-bottom: 155px !important;
  }

  .pl_sm--155 {
    padding-left: 155px !important;
  }

  .pr_sm--155 {
    padding-right: 155px !important;
  }

  .mt_sm--155 {
    margin-top: 155px !important;
  }

  .ml_sm--155 {
    margin-left: 155px !important;
  }

  .mr_sm--155 {
    margin-right: 155px !important;
  }

  .mb_sm--155 {
    margin-bottom: 155px !important;
  }

  .ptb_sm--160 {
    padding: 160px 0 !important;
  }

  .plr_sm--160 {
    padding: 0 160px !important;
  }

  .pt_sm--160 {
    padding-top: 160px !important;
  }

  .pb_sm--160 {
    padding-bottom: 160px !important;
  }

  .pl_sm--160 {
    padding-left: 160px !important;
  }

  .pr_sm--160 {
    padding-right: 160px !important;
  }

  .mt_sm--160 {
    margin-top: 160px !important;
  }

  .ml_sm--160 {
    margin-left: 160px !important;
  }

  .mr_sm--160 {
    margin-right: 160px !important;
  }

  .mb_sm--160 {
    margin-bottom: 160px !important;
  }

  .ptb_sm--165 {
    padding: 165px 0 !important;
  }

  .plr_sm--165 {
    padding: 0 165px !important;
  }

  .pt_sm--165 {
    padding-top: 165px !important;
  }

  .pb_sm--165 {
    padding-bottom: 165px !important;
  }

  .pl_sm--165 {
    padding-left: 165px !important;
  }

  .pr_sm--165 {
    padding-right: 165px !important;
  }

  .mt_sm--165 {
    margin-top: 165px !important;
  }

  .ml_sm--165 {
    margin-left: 165px !important;
  }

  .mr_sm--165 {
    margin-right: 165px !important;
  }

  .mb_sm--165 {
    margin-bottom: 165px !important;
  }

  .ptb_sm--170 {
    padding: 170px 0 !important;
  }

  .plr_sm--170 {
    padding: 0 170px !important;
  }

  .pt_sm--170 {
    padding-top: 170px !important;
  }

  .pb_sm--170 {
    padding-bottom: 170px !important;
  }

  .pl_sm--170 {
    padding-left: 170px !important;
  }

  .pr_sm--170 {
    padding-right: 170px !important;
  }

  .mt_sm--170 {
    margin-top: 170px !important;
  }

  .ml_sm--170 {
    margin-left: 170px !important;
  }

  .mr_sm--170 {
    margin-right: 170px !important;
  }

  .mb_sm--170 {
    margin-bottom: 170px !important;
  }

  .ptb_sm--175 {
    padding: 175px 0 !important;
  }

  .plr_sm--175 {
    padding: 0 175px !important;
  }

  .pt_sm--175 {
    padding-top: 175px !important;
  }

  .pb_sm--175 {
    padding-bottom: 175px !important;
  }

  .pl_sm--175 {
    padding-left: 175px !important;
  }

  .pr_sm--175 {
    padding-right: 175px !important;
  }

  .mt_sm--175 {
    margin-top: 175px !important;
  }

  .ml_sm--175 {
    margin-left: 175px !important;
  }

  .mr_sm--175 {
    margin-right: 175px !important;
  }

  .mb_sm--175 {
    margin-bottom: 175px !important;
  }

  .ptb_sm--180 {
    padding: 180px 0 !important;
  }

  .plr_sm--180 {
    padding: 0 180px !important;
  }

  .pt_sm--180 {
    padding-top: 180px !important;
  }

  .pb_sm--180 {
    padding-bottom: 180px !important;
  }

  .pl_sm--180 {
    padding-left: 180px !important;
  }

  .pr_sm--180 {
    padding-right: 180px !important;
  }

  .mt_sm--180 {
    margin-top: 180px !important;
  }

  .ml_sm--180 {
    margin-left: 180px !important;
  }

  .mr_sm--180 {
    margin-right: 180px !important;
  }

  .mb_sm--180 {
    margin-bottom: 180px !important;
  }

  .ptb_sm--185 {
    padding: 185px 0 !important;
  }

  .plr_sm--185 {
    padding: 0 185px !important;
  }

  .pt_sm--185 {
    padding-top: 185px !important;
  }

  .pb_sm--185 {
    padding-bottom: 185px !important;
  }

  .pl_sm--185 {
    padding-left: 185px !important;
  }

  .pr_sm--185 {
    padding-right: 185px !important;
  }

  .mt_sm--185 {
    margin-top: 185px !important;
  }

  .ml_sm--185 {
    margin-left: 185px !important;
  }

  .mr_sm--185 {
    margin-right: 185px !important;
  }

  .mb_sm--185 {
    margin-bottom: 185px !important;
  }

  .ptb_sm--190 {
    padding: 190px 0 !important;
  }

  .plr_sm--190 {
    padding: 0 190px !important;
  }

  .pt_sm--190 {
    padding-top: 190px !important;
  }

  .pb_sm--190 {
    padding-bottom: 190px !important;
  }

  .pl_sm--190 {
    padding-left: 190px !important;
  }

  .pr_sm--190 {
    padding-right: 190px !important;
  }

  .mt_sm--190 {
    margin-top: 190px !important;
  }

  .ml_sm--190 {
    margin-left: 190px !important;
  }

  .mr_sm--190 {
    margin-right: 190px !important;
  }

  .mb_sm--190 {
    margin-bottom: 190px !important;
  }

  .ptb_sm--195 {
    padding: 195px 0 !important;
  }

  .plr_sm--195 {
    padding: 0 195px !important;
  }

  .pt_sm--195 {
    padding-top: 195px !important;
  }

  .pb_sm--195 {
    padding-bottom: 195px !important;
  }

  .pl_sm--195 {
    padding-left: 195px !important;
  }

  .pr_sm--195 {
    padding-right: 195px !important;
  }

  .mt_sm--195 {
    margin-top: 195px !important;
  }

  .ml_sm--195 {
    margin-left: 195px !important;
  }

  .mr_sm--195 {
    margin-right: 195px !important;
  }

  .mb_sm--195 {
    margin-bottom: 195px !important;
  }

  .ptb_sm--200 {
    padding: 200px 0 !important;
  }

  .plr_sm--200 {
    padding: 0 200px !important;
  }

  .pt_sm--200 {
    padding-top: 200px !important;
  }

  .pb_sm--200 {
    padding-bottom: 200px !important;
  }

  .pl_sm--200 {
    padding-left: 200px !important;
  }

  .pr_sm--200 {
    padding-right: 200px !important;
  }

  .mt_sm--200 {
    margin-top: 200px !important;
  }

  .ml_sm--200 {
    margin-left: 200px !important;
  }

  .mr_sm--200 {
    margin-right: 200px !important;
  }

  .mb_sm--200 {
    margin-bottom: 200px !important;
  }

  .pl_sm--0 {
    padding-left: 0;
  }

  .pr_sm--0 {
    padding-right: 0;
  }

  .pt_sm--0 {
    padding-top: 0;
  }

  .pb_sm--0 {
    padding-bottom: 0;
  }

  .mr_sm--0 {
    margin-right: 0;
  }

  .ml_sm--0 {
    margin-left: 0;
  }

  .mt_sm--0 {
    margin-top: 0;
  }

  .mb_sm--0 {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0 !important;
  }

  .plr_mobile--5 {
    padding: 0 5px !important;
  }

  .pt_mobile--5 {
    padding-top: 5px !important;
  }

  .pb_mobile--5 {
    padding-bottom: 5px !important;
  }

  .pl_mobile--5 {
    padding-left: 5px !important;
  }

  .pr_mobile--5 {
    padding-right: 5px !important;
  }

  .mt_mobile--5 {
    margin-top: 5px !important;
  }

  .mb_mobile--5 {
    margin-bottom: 5px !important;
  }

  .ptb_mobile--10 {
    padding: 10px 0 !important;
  }

  .plr_mobile--10 {
    padding: 0 10px !important;
  }

  .pt_mobile--10 {
    padding-top: 10px !important;
  }

  .pb_mobile--10 {
    padding-bottom: 10px !important;
  }

  .pl_mobile--10 {
    padding-left: 10px !important;
  }

  .pr_mobile--10 {
    padding-right: 10px !important;
  }

  .mt_mobile--10 {
    margin-top: 10px !important;
  }

  .mb_mobile--10 {
    margin-bottom: 10px !important;
  }

  .ptb_mobile--15 {
    padding: 15px 0 !important;
  }

  .plr_mobile--15 {
    padding: 0 15px !important;
  }

  .pt_mobile--15 {
    padding-top: 15px !important;
  }

  .pb_mobile--15 {
    padding-bottom: 15px !important;
  }

  .pl_mobile--15 {
    padding-left: 15px !important;
  }

  .pr_mobile--15 {
    padding-right: 15px !important;
  }

  .mt_mobile--15 {
    margin-top: 15px !important;
  }

  .mb_mobile--15 {
    margin-bottom: 15px !important;
  }

  .ptb_mobile--20 {
    padding: 20px 0 !important;
  }

  .plr_mobile--20 {
    padding: 0 20px !important;
  }

  .pt_mobile--20 {
    padding-top: 20px !important;
  }

  .pb_mobile--20 {
    padding-bottom: 20px !important;
  }

  .pl_mobile--20 {
    padding-left: 20px !important;
  }

  .pr_mobile--20 {
    padding-right: 20px !important;
  }

  .mt_mobile--20 {
    margin-top: 20px !important;
  }

  .mb_mobile--20 {
    margin-bottom: 20px !important;
  }

  .ptb_mobile--25 {
    padding: 25px 0 !important;
  }

  .plr_mobile--25 {
    padding: 0 25px !important;
  }

  .pt_mobile--25 {
    padding-top: 25px !important;
  }

  .pb_mobile--25 {
    padding-bottom: 25px !important;
  }

  .pl_mobile--25 {
    padding-left: 25px !important;
  }

  .pr_mobile--25 {
    padding-right: 25px !important;
  }

  .mt_mobile--25 {
    margin-top: 25px !important;
  }

  .mb_mobile--25 {
    margin-bottom: 25px !important;
  }

  .ptb_mobile--30 {
    padding: 30px 0 !important;
  }

  .plr_mobile--30 {
    padding: 0 30px !important;
  }

  .pt_mobile--30 {
    padding-top: 30px !important;
  }

  .pb_mobile--30 {
    padding-bottom: 30px !important;
  }

  .pl_mobile--30 {
    padding-left: 30px !important;
  }

  .pr_mobile--30 {
    padding-right: 30px !important;
  }

  .mt_mobile--30 {
    margin-top: 30px !important;
  }

  .mb_mobile--30 {
    margin-bottom: 30px !important;
  }

  .ptb_mobile--35 {
    padding: 35px 0 !important;
  }

  .plr_mobile--35 {
    padding: 0 35px !important;
  }

  .pt_mobile--35 {
    padding-top: 35px !important;
  }

  .pb_mobile--35 {
    padding-bottom: 35px !important;
  }

  .pl_mobile--35 {
    padding-left: 35px !important;
  }

  .pr_mobile--35 {
    padding-right: 35px !important;
  }

  .mt_mobile--35 {
    margin-top: 35px !important;
  }

  .mb_mobile--35 {
    margin-bottom: 35px !important;
  }

  .ptb_mobile--40 {
    padding: 40px 0 !important;
  }

  .plr_mobile--40 {
    padding: 0 40px !important;
  }

  .pt_mobile--40 {
    padding-top: 40px !important;
  }

  .pb_mobile--40 {
    padding-bottom: 40px !important;
  }

  .pl_mobile--40 {
    padding-left: 40px !important;
  }

  .pr_mobile--40 {
    padding-right: 40px !important;
  }

  .mt_mobile--40 {
    margin-top: 40px !important;
  }

  .mb_mobile--40 {
    margin-bottom: 40px !important;
  }

  .ptb_mobile--45 {
    padding: 45px 0 !important;
  }

  .plr_mobile--45 {
    padding: 0 45px !important;
  }

  .pt_mobile--45 {
    padding-top: 45px !important;
  }

  .pb_mobile--45 {
    padding-bottom: 45px !important;
  }

  .pl_mobile--45 {
    padding-left: 45px !important;
  }

  .pr_mobile--45 {
    padding-right: 45px !important;
  }

  .mt_mobile--45 {
    margin-top: 45px !important;
  }

  .mb_mobile--45 {
    margin-bottom: 45px !important;
  }

  .ptb_mobile--50 {
    padding: 50px 0 !important;
  }

  .plr_mobile--50 {
    padding: 0 50px !important;
  }

  .pt_mobile--50 {
    padding-top: 50px !important;
  }

  .pb_mobile--50 {
    padding-bottom: 50px !important;
  }

  .pl_mobile--50 {
    padding-left: 50px !important;
  }

  .pr_mobile--50 {
    padding-right: 50px !important;
  }

  .mt_mobile--50 {
    margin-top: 50px !important;
  }

  .mb_mobile--50 {
    margin-bottom: 50px !important;
  }

  .ptb_mobile--55 {
    padding: 55px 0 !important;
  }

  .plr_mobile--55 {
    padding: 0 55px !important;
  }

  .pt_mobile--55 {
    padding-top: 55px !important;
  }

  .pb_mobile--55 {
    padding-bottom: 55px !important;
  }

  .pl_mobile--55 {
    padding-left: 55px !important;
  }

  .pr_mobile--55 {
    padding-right: 55px !important;
  }

  .mt_mobile--55 {
    margin-top: 55px !important;
  }

  .mb_mobile--55 {
    margin-bottom: 55px !important;
  }

  .ptb_mobile--60 {
    padding: 60px 0 !important;
  }

  .plr_mobile--60 {
    padding: 0 60px !important;
  }

  .pt_mobile--60 {
    padding-top: 60px !important;
  }

  .pb_mobile--60 {
    padding-bottom: 60px !important;
  }

  .pl_mobile--60 {
    padding-left: 60px !important;
  }

  .pr_mobile--60 {
    padding-right: 60px !important;
  }

  .mt_mobile--60 {
    margin-top: 60px !important;
  }

  .mb_mobile--60 {
    margin-bottom: 60px !important;
  }

  .ptb_mobile--65 {
    padding: 65px 0 !important;
  }

  .plr_mobile--65 {
    padding: 0 65px !important;
  }

  .pt_mobile--65 {
    padding-top: 65px !important;
  }

  .pb_mobile--65 {
    padding-bottom: 65px !important;
  }

  .pl_mobile--65 {
    padding-left: 65px !important;
  }

  .pr_mobile--65 {
    padding-right: 65px !important;
  }

  .mt_mobile--65 {
    margin-top: 65px !important;
  }

  .mb_mobile--65 {
    margin-bottom: 65px !important;
  }

  .ptb_mobile--70 {
    padding: 70px 0 !important;
  }

  .plr_mobile--70 {
    padding: 0 70px !important;
  }

  .pt_mobile--70 {
    padding-top: 70px !important;
  }

  .pb_mobile--70 {
    padding-bottom: 70px !important;
  }

  .pl_mobile--70 {
    padding-left: 70px !important;
  }

  .pr_mobile--70 {
    padding-right: 70px !important;
  }

  .mt_mobile--70 {
    margin-top: 70px !important;
  }

  .mb_mobile--70 {
    margin-bottom: 70px !important;
  }

  .ptb_mobile--75 {
    padding: 75px 0 !important;
  }

  .plr_mobile--75 {
    padding: 0 75px !important;
  }

  .pt_mobile--75 {
    padding-top: 75px !important;
  }

  .pb_mobile--75 {
    padding-bottom: 75px !important;
  }

  .pl_mobile--75 {
    padding-left: 75px !important;
  }

  .pr_mobile--75 {
    padding-right: 75px !important;
  }

  .mt_mobile--75 {
    margin-top: 75px !important;
  }

  .mb_mobile--75 {
    margin-bottom: 75px !important;
  }

  .ptb_mobile--80 {
    padding: 80px 0 !important;
  }

  .plr_mobile--80 {
    padding: 0 80px !important;
  }

  .pt_mobile--80 {
    padding-top: 80px !important;
  }

  .pb_mobile--80 {
    padding-bottom: 80px !important;
  }

  .pl_mobile--80 {
    padding-left: 80px !important;
  }

  .pr_mobile--80 {
    padding-right: 80px !important;
  }

  .mt_mobile--80 {
    margin-top: 80px !important;
  }

  .mb_mobile--80 {
    margin-bottom: 80px !important;
  }

  .ptb_mobile--85 {
    padding: 85px 0 !important;
  }

  .plr_mobile--85 {
    padding: 0 85px !important;
  }

  .pt_mobile--85 {
    padding-top: 85px !important;
  }

  .pb_mobile--85 {
    padding-bottom: 85px !important;
  }

  .pl_mobile--85 {
    padding-left: 85px !important;
  }

  .pr_mobile--85 {
    padding-right: 85px !important;
  }

  .mt_mobile--85 {
    margin-top: 85px !important;
  }

  .mb_mobile--85 {
    margin-bottom: 85px !important;
  }

  .ptb_mobile--90 {
    padding: 90px 0 !important;
  }

  .plr_mobile--90 {
    padding: 0 90px !important;
  }

  .pt_mobile--90 {
    padding-top: 90px !important;
  }

  .pb_mobile--90 {
    padding-bottom: 90px !important;
  }

  .pl_mobile--90 {
    padding-left: 90px !important;
  }

  .pr_mobile--90 {
    padding-right: 90px !important;
  }

  .mt_mobile--90 {
    margin-top: 90px !important;
  }

  .mb_mobile--90 {
    margin-bottom: 90px !important;
  }

  .ptb_mobile--95 {
    padding: 95px 0 !important;
  }

  .plr_mobile--95 {
    padding: 0 95px !important;
  }

  .pt_mobile--95 {
    padding-top: 95px !important;
  }

  .pb_mobile--95 {
    padding-bottom: 95px !important;
  }

  .pl_mobile--95 {
    padding-left: 95px !important;
  }

  .pr_mobile--95 {
    padding-right: 95px !important;
  }

  .mt_mobile--95 {
    margin-top: 95px !important;
  }

  .mb_mobile--95 {
    margin-bottom: 95px !important;
  }

  .ptb_mobile--100 {
    padding: 100px 0 !important;
  }

  .plr_mobile--100 {
    padding: 0 100px !important;
  }

  .pt_mobile--100 {
    padding-top: 100px !important;
  }

  .pb_mobile--100 {
    padding-bottom: 100px !important;
  }

  .pl_mobile--100 {
    padding-left: 100px !important;
  }

  .pr_mobile--100 {
    padding-right: 100px !important;
  }

  .mt_mobile--100 {
    margin-top: 100px !important;
  }

  .mb_mobile--100 {
    margin-bottom: 100px !important;
  }
}

.slick-gutter-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.slick-gutter-5 .slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}

.slick-gutter-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.slick-gutter-10 .slick-slide {
  padding-left: 10px;
  padding-right: 10px;
}

.slick-gutter-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.slick-gutter-15 .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

.slick-gutter-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.slick-gutter-20 .slick-slide {
  padding-left: 20px;
  padding-right: 20px;
}

.slick-gutter-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.slick-gutter-25 .slick-slide {
  padding-left: 25px;
  padding-right: 25px;
}

.slick-gutter-30 {
  margin-left: -30px;
  margin-right: -30px;
}

.slick-gutter-30 .slick-slide {
  padding-left: 30px;
  padding-right: 30px;
}

.slick-gutter-35 {
  margin-left: -35px;
  margin-right: -35px;
}

.slick-gutter-35 .slick-slide {
  padding-left: 35px;
  padding-right: 35px;
}

.slick-gutter-40 {
  margin-left: -40px;
  margin-right: -40px;
}

.slick-gutter-40 .slick-slide {
  padding-left: 40px;
  padding-right: 40px;
}

.slick-gutter-45 {
  margin-left: -45px;
  margin-right: -45px;
}

.slick-gutter-45 .slick-slide {
  padding-left: 45px;
  padding-right: 45px;
}

.slick-gutter-50 {
  margin-left: -50px;
  margin-right: -50px;
}

.slick-gutter-50 .slick-slide {
  padding-left: 50px;
  padding-right: 50px;
}

.slick-gutter-55 {
  margin-left: -55px;
  margin-right: -55px;
}

.slick-gutter-55 .slick-slide {
  padding-left: 55px;
  padding-right: 55px;
}

.slick-gutter-60 {
  margin-left: -60px;
  margin-right: -60px;
}

.slick-gutter-60 .slick-slide {
  padding-left: 60px;
  padding-right: 60px;
}

.slick-gutter-65 {
  margin-left: -65px;
  margin-right: -65px;
}

.slick-gutter-65 .slick-slide {
  padding-left: 65px;
  padding-right: 65px;
}

.slick-gutter-70 {
  margin-left: -70px;
  margin-right: -70px;
}

.slick-gutter-70 .slick-slide {
  padding-left: 70px;
  padding-right: 70px;
}

.slick-gutter-75 {
  margin-left: -75px;
  margin-right: -75px;
}

.slick-gutter-75 .slick-slide {
  padding-left: 75px;
  padding-right: 75px;
}

.slick-gutter-80 {
  margin-left: -80px;
  margin-right: -80px;
}

.slick-gutter-80 .slick-slide {
  padding-left: 80px;
  padding-right: 80px;
}

.slick-gutter-85 {
  margin-left: -85px;
  margin-right: -85px;
}

.slick-gutter-85 .slick-slide {
  padding-left: 85px;
  padding-right: 85px;
}

.slick-gutter-90 {
  margin-left: -90px;
  margin-right: -90px;
}

.slick-gutter-90 .slick-slide {
  padding-left: 90px;
  padding-right: 90px;
}

.slick-gutter-95 {
  margin-left: -95px;
  margin-right: -95px;
}

.slick-gutter-95 .slick-slide {
  padding-left: 95px;
  padding-right: 95px;
}

.slick-gutter-100 {
  margin-left: -100px;
  margin-right: -100px;
}

.slick-gutter-100 .slick-slide {
  padding-left: 100px;
  padding-right: 100px;
}

.mt-dec-30 {
  margin-top: -30px !important;
}

.mt_dec--30 {
  margin-top: -30px !important;
}

.mt-dec-100 {
  margin-top: -100px !important;
}

@media only screen and (max-width: 479px) {
  .small-margin-pricing {
    margin-bottom: 25px !important;
  }
}

@media only screen and (max-width: 479px) {
  .contact-input {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb_dec--35 {
    margin-bottom: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .mb_dec--35 {
    margin-bottom: -75px;
  }
}

@media only screen and (max-width: 575px) {
  .mb_dec--35 {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .mt-contact-sm {
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-pb {
    padding-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-input {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-input {
    padding-bottom: 30px;
  }
}

.pb_xl--130 {
  padding-bottom: 130px;
}

@media only screen and (max-width: 1199px) {
  .pb_xl--130 {
    padding-bottom: 110px;
  }
}

@media only screen and (max-width: 1199px) {
  .mt_experience {
    margin-top: -10px;
  }
}

.mt_dec--120 {
  margin-top: -120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plr_md--0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.padding-contorler-am-slide {
  padding-left: 246px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .padding-contorler-am-slide {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 1199px) {
  .padding-contorler-am-slide {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-contorler-am-slide {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-contorler-am-slide {
    padding-left: 15px;
  }
}

.padding-contorler-am-slide-11 {
  padding-left: 246px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .padding-contorler-am-slide-11 {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 1199px) {
  .padding-contorler-am-slide-11 {
    padding-left: 0;
  }
}

.padding-contorler-am-slide-right {
  padding-right: 200px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .padding-contorler-am-slide-right {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .padding-contorler-am-slide-right {
    padding-right: 30px;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .padding-contorler-am-slide-right {
    padding-right: 30px;
    padding-top: 50px;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

a {
  color: var(--color-heading);
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: var(--color-primary);
}

a:focus {
  outline: none;
}

address {
  margin: 0 0 24px;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

mark {
  background: var(--color-primary);
  color: #ffffff;
}

code,
kbd,
pre,
samp {
  font-size: var(--font-size-b3);
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  color: var(--color-primary);
}

kbd,
ins {
  color: #ffffff;
}

pre {
  font-family: "Raleway", sans-serif;
  font-size: var(--font-size-b3);
  margin: 10px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: var(--color-body);
  background: var(--color-lighter);
}

small {
  font-size: smaller;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl {
  margin-top: 0;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

dt {
  font-weight: bold;
  color: var(--color-heading);
}

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

li>ul,
li>ol {
  margin: 0;
}

ol ul {
  margin-bottom: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid var(--color-border);
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type=checkbox],
input[type=radio] {
  padding: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

caption,
th,
td {
  font-weight: normal;
}

th {
  font-weight: 500;
  text-transform: uppercase;
}

td,
.wp-block-calendar tfoot td {
  border: 1px solid var(--color-border);
  padding: 7px 10px;
}

del {
  color: #333;
}

ins {
  background: rgba(255, 47, 47, 0.4);
  text-decoration: none;
}

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px;
}

table a,
table a:link,
table a:visited {
  text-decoration: underline;
}

dt {
  font-weight: bold;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

caption {
  caption-side: top;
}

kbd {
  background: var(--heading-color);
}

dfn,
cite,
em {
  font-style: italic;
}

/* BlockQuote  */
blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote {
  font-size: var(--font-size-b1);
  font-style: italic;
  font-weight: var(--p-light);
  margin: 24px 40px;
}

blockquote blockquote {
  margin-right: 0;
}

blockquote cite,
blockquote small {
  font-size: var(--font-size-b3);
  font-weight: normal;
}

blockquote strong,
blockquote b {
  font-weight: 700;
}

/* ========= Forms Styles ========= */
input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid var(--color-border);
  transition: all 0.4s ease-out 0s;
  color: var(--color-body);
  width: 100%;
}

input:focus,
input:active,
button:focus,
button:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  outline: none;
  border-color: var(--color-primary);
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

input {
  height: 40px;
  padding: 0 15px;
}

input[type=text],
input[type=password],
input[type=email],
input[type=number],
input[type=tel],
textarea {
  font-size: var(--font-size-b2);
  font-weight: 400;
  height: auto;
  line-height: 28px;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 15px;
  outline: none;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
}

/*==============================
 *  Utilities
=================================*/
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.fix {
  overflow: hidden;
}

.slick-initialized .slick-slide {
  margin-bottom: -10px;
}

.slick-gutter-15 {
  margin: -30px -15px;
}

.slick-gutter-15 .slick-slide {
  padding: 30px 15px;
}

iframe {
  width: 100%;
}

/*===============================
    Background Color 
=================================*/
.bg-color-primary {
  background: var(--color-primary);
}

.bg-color-secondary {
  background: var(--color-secondary);
}

.bg-color-tertiary {
  background: var(--color-tertiary);
}

.bg-color-gray {
  background: var(--color-gray);
}

.bg-color-white {
  background: #FFFFFF;
}

.bg-color-black {
  background: #1A1A1A;
}

/*===========================
Background Image 
=============================*/
.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}


/* Height and width */
.fullscreen {
  min-height: 980px;
  width: 100%;
}

/*------------------------------
    Scroll Up 
--------------------------------*/
#scrollUp {
  width: 70px;
  height: 80px;
  right: 100px;
  bottom: 60px;
  text-align: center;
  z-index: 9811 !important;
  text-decoration: none;
  background: #fff;
  line-height: 80px;
  color: #757589;
  font-size: 15px;
  font-weight: 400;
  transition: var(--transition);
  display: inline-block;
  background: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}

#scrollUp::before {
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: rgba(0, 2, 72, 0.1);
  content: "";
  position: absolute;
  z-index: -1;
  transform-style: preserve-3d;
  transform: rotateY(-10deg);
  filter: blur(50px);
}

#scrollUp::after {
  background: #ffffff;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform-style: preserve-3d;
  transform: rotateY(-10deg);
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 30px;
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
}

#scrollUp span.text {
  position: relative;
  display: inline-block;
  margin-top: 7px;
}

@media only screen and (max-width: 767px) {
  #scrollUp span.text {
    margin-top: 3px;
  }
}

#scrollUp span.text::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 7px 5px;
  border-color: transparent transparent var(--color-primary) transparent;
  position: absolute;
  content: "";
  left: 50%;
  top: 21%;
  transform: translateX(-50%);
}

#scrollUp:hover span.text {
  color: var(--color-primary);
}

body {
  scroll-behavior: auto;
  background: #FFFFFF;
}

/*------------------------
    Header Sticky 
--------------------------*/
.col-lg-20 {
  width: 20%;
  float: left;
}

.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
}

@media (min-width: 1200px) {
  .col-lg-20 {
    width: 20%;
    float: left;
  }
}

@media only screen and (max-width: 1199px) {
  .col-lg-20 {
    width: 33%;
    float: left;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-lg-20 {
    width: 50%;
    float: left;
  }
}

@media only screen and (max-width: 767px) {
  .col-lg-20 {
    width: 50%;
    float: left;
  }
}

@media only screen and (max-width: 575px) {
  .col-lg-20 {
    width: 50%;
    float: left;
  }
}

@media only screen and (max-width: 479px) {
  .col-lg-20 {
    width: 100%;
    float: left;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5D666F !important;
  opacity: 1 !important;
  /* Firefox */
  font-size: 16px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #5D666F !important;
  font-size: 16px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #5D666F !important;
  font-size: 16px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.bg-1 {
  background: #16243d;
}

.bg-secondary {
  background: #1D2027 !important;
}

.bg-white {
  background: #FFFFFF;
}

.bg-footer-one {
  background-repeat: no-repeat;
  background-size: cover;
  background: #0E1422;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bg-footer-one::after {
  content: "";
  position: absolute;
  /* background-image: url(../images/footer/h1-shape/01.svg); */
  height: 450px;
  width: 450px;
  right: -3%;
  background-repeat: no-repeat;
  bottom: -5%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bg-footer-one::after {
    right: -15%;
  }
}

@media only screen and (max-width: 1199px) {
  .bg-footer-one::after {
    right: -15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-footer-one::after {
    left: 53%;
  }
}

.bg-footer-one::before {
  content: "";
  position: absolute;
  /* background-image: url(../images/footer/h1-shape/02.png); */
  height: 800px;
  width: 823px;
  left: -16%;
  background-position: center;
  bottom: -13%;
  background-size: cover;
  z-index: -1;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bg-footer-one::before {
    left: -25%;
  }
}

@media only screen and (max-width: 1199px) {
  .bg-footer-one::before {
    left: -25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-footer-one::before {
    left: -48%;
  }
}

.bg-footer-one .bg-shape-f1 {
  position: relative;
  z-index: 1;
}

.bg-footer-one .bg-shape-f1::after {
  position: absolute;
  /* background-image: url(../images/footer/h1-shape/03.png); */
  content: "";
  height: 500px;
  width: 523px;
  left: -25%;
  top: -20%;
  z-index: -1;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bg-footer-one .bg-shape-f1::after {
    left: -30%;
    top: -32%;
  }
}

@media only screen and (max-width: 1199px) {
  .bg-footer-one .bg-shape-f1::after {
    left: -30%;
    top: -32%;
  }
}

.rts-title-area {
  position: relative;
  z-index: 1;
}

.rts-title-area::after {
  position: absolute;
  content: "About Us";
  left: -14%;
  top: -39%;
  color: #ffffff;
  background-color: #000;
  background-size: 100% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.5px transparent;
  font-size: 150px;
  height: 100%;
  max-width: max-content;
  max-height: max-content;
  font-weight: 900;
  z-index: -1;
  display: flex;
  align-items: center;
  opacity: 0.35;
  font-family: var(--font-secondary);
}

@media only screen and (max-width: 1199px) {
  .rts-title-area::after {
    font-size: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-title-area::after {
    font-size: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .rts-title-area::after {
    font-size: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .rts-title-area::after {
    font-size: 50px;
  }
}

.rts-title-area p.pre-title {
  color: var(--color-primary);
  margin-bottom: 0;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: -5px;
}

@media only screen and (max-width: 479px) {
  .rts-title-area p.pre-title {
    font-size: 14px;
  }
}

.rts-title-area .title {
  margin-bottom: 24px;
  text-transform: capitalize;
}

.rts-title-area.service::after {
  content: "Services";
  left: 50%;
  transform: translateX(-50%);
}

.rts-title-area.pricing-planes::after {
  content: "price plans";
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  height: 168px;
  top: -70%;
}

.rts-title-area.service-four::after {
  content: "Services";
  left: 50%;
  transform: translateX(-50%);
  top: -20%;
}

.rts-title-area.business::after {
  content: "Key Features";
  min-width: max-content;
  min-height: 120px;
  left: 67%;
  transform: translateX(-50%);
  z-index: -1;
}

.rts-title-area.gallery::after {
  content: "Campaigns";
  left: 18%;
  transform: translateX(-50%);
  z-index: -1;
}

.rts-title-area.team::after {
  content: "OurWork";
  left: 49%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
}

.rts-title-area.feedback::after {
  content: "Testimonial";
  left: 49%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
}

.rts-title-area.feature::after {
  content: "Features";
  left: 32%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
  top: -18%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rts-title-area.feature::after {
    left: 55%;
    font-size: 110px;
  }
}

.rts-title-area.reviews::after {
  content: "Testimonial";
  left: 56%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
  top: 2%;
}

.rts-title-area.contact::after {
  content: "Contact";
  left: 52%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
  top: -41%;
}

.rts-title-area.contact-appoinment::after {
  position: absolute;
  content: "Hello";
  left: 18%;
  top: -39%;
  color: #8b868600;
  background-color: #241e1e00;
  background-size: 100% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px #585858;
  font-size: 150px;
  height: 100%;
  max-width: max-content;
  max-height: max-content;
  font-weight: 900;
  z-index: -1;
  display: flex;
  align-items: center;
  opacity: 0.35;
  font-family: var(--font-secondary);
}

@media only screen and (max-width: 575px) {
  .rts-title-area.contact-appoinment::after {
    font-size: 100px;
  }
}

.rts-title-area.contact-fluid::after {
  content: "Contact Us";
  left: 49%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
  top: -40%;
  min-width: max-content;
}

.about-founder-wrapper .author-inner {
  display: flex;
  align-items: center;
  position: relative;
}

.about-founder-wrapper .author-inner::after {
  position: absolute;
  content: "";
  right: 11%;
  top: 50%;
  transform: translateY(-50%);
  height: 70px;
  width: 1px;
  background: #EBEBEB;
}

@media only screen and (max-width: 1199px) {
  .about-founder-wrapper .author-inner::after {
    display: none;
  }
}

.about-founder-wrapper .author-inner .founder-details .title {
  margin-bottom: 1px;
  transition: 0.3s;
}

.about-founder-wrapper .author-inner .founder-details a:hover .title {
  color: var(--color-title);
}

.about-founder-wrapper .author-inner .founder-details span {
  font-size: 14px;
  color: var(--color-primary);
  font-weight: 400;
}

.about-founder-wrapper .author-inner .thumbnail {
  display: block;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 16px;
}

.about-founder-wrapper .author-inner .thumbnail img {
  transition: 0.3s;
}

.about-founder-wrapper .author-inner .thumbnail:hover img {
  transform: scale(1.1);
}

.about-founder-wrapper .author-call-option {
  display: flex;
  align-items: center;
}

.about-founder-wrapper .author-call-option img {
  padding: 12px;
  background: var(--color-primary);
  border-radius: 14px;
  margin-right: 16px;
}

.about-founder-wrapper .author-call-option span {
  color: #5D666F;
  font-size: 14px;
}

.about-founder-wrapper .author-call-option .title {
  margin-bottom: 0;
  margin-top: 2px;
  transition: 0.3s;
  min-width: max-content;
}

.about-founder-wrapper .author-call-option .title:hover {
  color: var(--color-primary);
}

.pagination {
  margin: auto;
  max-width: max-content;
  margin-top: 25px;
}

.pagination button {
  max-width: max-content;
  padding: 15px;
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  margin-right: 10px;
  color: #1C2539;
  font-weight: 700;
  height: 50px;
  width: 50px;
  line-height: 18px;
}

.pagination button.active {
  background: #e00a0a;
  color: #ffff;
}

.pagination button:hover {
  background: var(--color-primary);
  color: #fff;
}

.pagination button:last-child {
  margin-right: 0;
  font-size: 24px;
}

.pagination button:last-child i {
  margin-top: -3px;
}
.over_link {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  left: 0;
  top: 0;
}

.container-2 {
  max-width: 1680px;
  margin: auto;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}
/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
@keyframes jump-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes rotateIt {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotateIt2 {
  to {
    transform: rotate(360deg);
  }
}

@keyframes animate-floting {
  0% {
    transform: translateX(50%);
  }

  50% {
    transform: translateX(-40%);
  }

  100% {
    transform: translateX(40%);
  }
}

.floting-line {
  animation: animate-floting 15s linear infinite;
}

.floting-line:hover {
  animation-play-state: paused;
}

.floting-line-2 {
  animation: animate-floting-2 15s linear infinite;
}

.floting-line-2:hover {
  animation-play-state: paused;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes customOne {
  0% {
    -webkit-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-50%) scale(1.3);
    transform: translateY(-50%) scale(1.3);
    opacity: 0;
  }
}

@keyframes liveAuction {
  0% {
    background: var(--color-white);
  }

  100% {
    background: var(--color-danger);
  }
}

.cd-intro {
  margin: 4em auto;
}

@media only screen and (min-width: 768px) {
  .cd-intro {
    margin: 5em auto;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-intro {
    margin: 6em auto;
  }
}

/**
 * Settings
 */
/**
  * Easings
  */
/**
  * Core
  */
/* ----------------------------------------------------------------------------------------------------------------------------------------------- */

/* Header style hear  */
.header-top-one .left {
  display: flex;
  align-items: center;
}

.header-top-one .left .mail {
  position: relative;
}

.header-top-one .left .mail a {
  padding: 11px 0;
  display: block;
  color: #fff;
  margin-right: 50px;
  font-size: 14px;
  position: relative;
  transition: var(--transition);
  text-transform: lowercase;
}

.header-top-one .left .mail a i {
  margin-right: 6px;
}

.header-top-one .left .mail a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12px;
  height: 1px;
  width: 0%;
  background: #fff;
  transition: var(--transition);
}

.header-top-one .left .mail a:hover {
  color: var(--color-white);
}

.header-top-one .left .mail a:hover::after {
  width: 100%;
}

.header-top-one .left .mail::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 14px;
  background: #f2f5f9;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.header-top-one .left .working-time p {
  color: #fff;
  font-size: 14px;
}

.header-top-one .left .working-time p i {
  margin-right: 6px;
}

.header-top-one .right {
  display: flex;
  justify-content: flex-end;
}

.header-top-one .right .top-nav {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.header-top-one .right .top-nav li {
  margin: 0;
  margin-right: 20px;
}

.header-top-one .right .top-nav li a {
  font-size: 14px;
  transition: var(--transition);
  position: relative;
  padding: 11px 0;
  display: block;
  color: #fff;
}

.header-top-one .right .top-nav li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 12px;
  height: 1px;
  width: 0%;
  background: #fff;
  transition: var(--transition);
}

.header-top-one .right .top-nav li a:hover::after {
  width: 100%;
}

.header-top-one .right .social-wrapper-one {
  margin-top: 10px;
  margin-left: 30px;
  position: relative;
}

.header-top-one .right .social-wrapper-one a i {
  transition: 0.3s;
}

.header-top-one .right .social-wrapper-one a:hover i {
  transform: scale(1.2);
}

.header-top-one .right .social-wrapper-one i {
  color: #fff;
}

.header-main-one .thumbnail a {
  padding: 20px 0;
  display: block;
  max-width: max-content;
}

@media only screen and (max-width: 479px) {
  .header-main-one .thumbnail a {
    padding: 18px 0;
  }
}


.main-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-header .nav-main ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.main-header .nav-main ul li {
  margin: 0;
  margin-right: 36px;
}

.main-header .nav-main ul li a {
  padding: 33px 0;
  display: block;
  color: #1C2539;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  transition: var(--transition);
  min-width: max-content;
}

.main-header .nav-main ul li a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 33px;
  height: 2px;
  width: 0%;
  border-radius: 1px;
  background: var(--color-primary);
  transition: var(--transition);
}

.main-header .nav-main ul li a:hover {
  color: var(--color-primary);
}

.main-header .nav-main ul li a:hover::after {
  width: 100%;
}

.main-header .button-area {
  display: flex;
  align-items: center;
  margin-left: 80px;
}

@media screen and (max-width: 1399px) {
  .main-header .button-area {
    margin-left: 16px;
  }
}

@media screen and (max-width: 1199px) {
  .main-header .button-area {
    margin-left: 16px;
    margin: 33px 0;
  }
}

@media only screen and (max-width: 479px) {
  .main-header .button-area {
    margin: 10px 0;
  }
}

.main-header .button-area button.menu .menu-dark {
  display: block;
  transition: 0.3s;
}

.main-header .button-area button.menu .menu-light {
  display: none;
  transition: 0.3s;
}

.main-header .button-area button.menu:hover .menu-dark {
  display: none;
}

.main-header .button-area button.menu:hover .menu-light {
  display: block;
}
.social-wrapper-one {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.social-wrapper-one li {
  margin: 0;
}

.social-wrapper-one li a {
  color: #fff;
  margin-right: 20px;
  padding: 11px 0;
}

.social-wrapper-one li a i {
  color: #fff;
  transition: 0.3s;
  font-size: 14px;
}

.social-wrapper-one li a:hover i {
  color: var(--color-primary);
}

.header--sticky {
  transition: 0.3s;
  top: 0;
  z-index: 999;
  position: relative;
}

.header--sticky.sticky {
  position: fixed !important;
  top: 0;
  display: block;
  backdrop-filter: blur(9px);
  z-index: 999;
  width: 100%;
  box-shadow: 0px 7px 18px #1810100d;
}


.header-one.header--sticky.sticky .header-top {
  display: none;
}


.search-input-area.show {
  visibility: visible;
  transform: inherit;
  opacity: 1;
}

.search-input-area {
  transition: all 500ms ease;
  visibility: hidden;
  transform: translateY(-100%);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 57px 0;
  background: white;
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.46);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 479px) {
  .search-input-area {
    padding: 20px 0;
  }
}

.search-input-area .search-input-inner {
  display: flex;
  align-items: center;
  position: relative;
}

.search-input-area .search-input-inner .input-div {
  width: 80%;
  display: flex;
  align-items: center;
  margin: auto;
}

.search-input-area .search-input-inner .input-div input {
  background: #F7F7F7;
  border-radius: 5px;
  height: 55px;
}

.search-input-area .search-input-inner .input-div button {
  max-width: max-content;
  padding: 17.5px;
  background: var(--color-primary);
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: block;
  margin-left: -9px;
  border-radius: 0 5px 5px 0;
}

.search-input-area .search-close-icon {
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 12px;
}

@media (max-width: 500px) {
  .search-input-area .search-close-icon {
    display: none;
  }
}

.search-input-area .search-close-icon i {
  position: relative;
  z-index: 1;
  color: var(--color-primary);
  transition: 0.3s;
  font-size: 18px;
}

.search-input-area .search-close-icon i::after {
  position: absolute;
  height: 45px;
  width: 45px;
  content: "";
  border-radius: 5px;
  background: var(--color-primary-alta);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: 0.3s;
}

.search-input-area .search-close-icon:hover i {
  color: var(--color-primary);
}

.search-input-area .search-close-icon:hover i::after {
  background: var(--color-primary-alta);
}

#anywhere-home {
  /* cursor: url(../images/banner/shape/close.png), auto; */
  background: #0e1013;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease-in-out;
  pointer-events: none;
  z-index: 50;
}

#anywhere-home.bgshow {
  background: #0e1013;
  opacity: 70%;
  visibility: visible;
  pointer-events: visible;
  z-index: 1000;
  top: 0;
}

li.has-droupdown {
  margin-right: 48px !important;
}

@media only screen and (max-width: 575px) {
  .menu-block-none {
    display: none;
  }
}

.header--sticky.header-one.six {
  position: absolute;
  width: 100%;
}

.header--sticky.header-one.six .header-top {
  background: transparent;
  border-bottom: 1px solid #E8E8E8;
  padding: 12px 0;
}

.header--sticky.header-one.six .header-top .right i {
  color: var(--color-primary-5);
  margin-right: 12px;
}

.header--sticky.header-one.six .header-top .right .call {
  margin-left: 30px;
}

.header--sticky.header-one.six .header-top .right a {
  color: #181A1C;
  font-weight: 500;
}

.header--sticky.header-one.six .header-main-one #menu-btn {
  border-radius: 50% !important;
  background: #fff !important;
}

@media only screen and (max-width: 1199px) {
  .header--sticky.header-one.six .header-main-one #menu-btn {
    background: #eee !important;
  }
}

.header--sticky.header-one.six .header-main-one #menu-btn:hover {
  background: var(--color-primary-5) !important;
}

.header--sticky.header-one.six .header-main-one .rts-btn.quote-btn {
  background: #181A1C;
  border-radius: 100px;
}

.header--sticky.header-one.six .header-main-one .rts-btn.quote-btn:hover {
  color: #181A1C;
  background: #f6f6f6 !important;
}

@media only screen and (max-width: 1199px) {
  .header--sticky.header-one.six .header-top {
    display: none;
  }
}

.header--sticky.header-one.six.sticky .header-main-one #menu-btn {
  background: #f6f6f6 !important;
}

.header--sticky.header-one.six.sticky .header-main-one #menu-btn:hover {
  background: var(--color-primary-5) !important;
}

.header-five.header-six.seven:hover {
  background: var(--color-gray);
  color: #000;
}

.mainmenu li {
  position: relative;
}

.mainmenu li.has-droupdown>a {
  position: relative;
}

.mainmenu li.has-droupdown>a::before {
  content: "\f078" !important;
  position: absolute !important;
  top: 51%;
  font-family: "FontAwesome" !important;
  right: -18px;
  transform: translateY(-50%);
  transition: 0.3s;
}

.mainmenu li.has-droupdown>a:hover {
  color: var(--color-primary);
}

.mainmenu li.has-droupdown>a:hover::before {
  content: "\f077" !important;
}

.mainmenu li.has-droupdown .submenu {
  min-width: 270px;
  height: auto;
  position: absolute;
  top: 90%;
  left: 0;
  z-index: 90;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  transition: 0.3s;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  border-left: 1px solid #ffffff14;
  border-bottom: 1px solid #ffffff14;
  border-right: 1px solid #ffffff14;
  border-top: 4px solid var(--color-primary);
  display: inline-block;
  box-shadow: 0 36px 35px rgba(0, 0, 0, 0.08);
}

.mainmenu li.has-droupdown .submenu .sub-droupdown:hover a.sub-menu-link {
  color: var(--color-primary);
}

.mainmenu li.has-droupdown .submenu .sub-droupdown:hover .third-lvl {
  opacity: 1;
  top: -8%;
}

.mainmenu li.has-droupdown .submenu .sub-droupdown a.sub-menu-link::before {
  content: "\f054";
  position: absolute;
  font-family: "FontAwesome";
  right: 0;
}

.mainmenu li.has-droupdown .submenu.third-lvl {
  opacity: 0;
  min-width: 185px;
  left: 100%;
  top: -13%;
}

.mainmenu li.has-droupdown .submenu.third-lvl::after {
  display: none;
}

.mainmenu li.has-droupdown .submenu.third-lvl.mobile-menu {
  left: 0;
}

.mainmenu li.has-droupdown .submenu::after {
  top: -20px;
  left: 16%;
  transform: translateX(-50%);
  position: absolute;
  content: "";
  border: 8px solid transparent;
  border-bottom-color: var(--color-primary);
}

.mainmenu li.has-droupdown .submenu.menu-link {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 800px;
  left: -320px;
}

.mainmenu li.has-droupdown .submenu.menu-link::after {
  display: none;
}



.mainmenu li.has-droupdown .submenu.menu-link2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 800px;
  left: -470px;
}

.mainmenu li.has-droupdown .submenu.menu-link2::after {
  display: none;
}


.mainmenu li.has-droupdown .submenu.menu-link1 {
  display: flex;
  justify-content: space-between;
  width: 590px;
}

.mainmenu li.has-droupdown .submenu.menu-link1::after {
  display: none;
}

.mainmenu li.has-droupdown .submenu li {
  margin-right: 0;
  padding: 0 15px;
}

.mainmenu li.has-droupdown .submenu li:not(:last-child) {
  border-bottom: 1px solid #C4C4C426;
}

.mainmenu li.has-droupdown .submenu li a {
  padding: 12px 0;
}

.mainmenu li.has-droupdown .submenu li a::after {
  display: none;
}

.mainmenu li:hover .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.nav-main ul li:hover a.nav-link {
  color: var(--color-primary);
}

.nav-main ul li:hover a.nav-link::before {
  color: var(--color-primary);
  content: "\f077" !important;
}

.nav-main ul li:hover a.nav-link::after {
  width: 100%;
}

/* .breadcrumb-bg {
  background-image: url(../images/breadcrumb/01.jpg);
} */

.rts-breadcrumb-area {
  padding-top: 153px;
  padding-bottom: 153px;
}

@media only screen and (max-width: 767px) {
  .rts-breadcrumb-area {
    padding: 80px 0;
  }
}

.rts-breadcrumb-area .title {
  color: #fff;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .rts-breadcrumb-area .title {
    font-size: 30px;
  }
}

.rts-breadcrumb-area .bread-tag {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 30px;
  background: #fff;
  border-radius: 31px;
  box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
  max-width: max-content;
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .rts-breadcrumb-area .bread-tag {
    margin: auto;
    margin-top: 30px;
    padding: 12px 11px;
  }
}

.rts-breadcrumb-area .bread-tag a {
  margin-right: 5px;
  font-weight: 700;
}

.rts-breadcrumb-area .bread-tag a.active {
  margin-left: 5px;
  color: #1C2539;
  margin-right: 0;
}

.rts-breadcrumb-area .bread-tag span {
  color: var(--color-primary);
}

.side-bar {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: -100%;
  width: 365px;
  padding: 40px 30px;
  padding-top: 50px;
  height: 100%;
  display: block;
  background-color: white;
  backdrop-filter: blur(7px);
  z-index: 1900;
  transition: all 600ms ease;
  box-shadow: -5px 0 20px -5px rgba(149, 22, 22, 0.12);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .side-bar {
    width: 320px;
    box-shadow: none;
  }
}

.side-bar.show {
  right: 0;
}

.side-bar button {
  max-width: max-content;
}

.side-bar button i {
  color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  background: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -8px;
  margin-top: -3px;
}

.rts-sidebar-menu-desktop .logo {
  margin-top: 50px;
}

.rts-sidebar-menu-desktop p.disc {
  margin-top: 30px;
  font-size: 16px;
  line-height: 26px;
}

.rts-sidebar-menu-desktop .get-in-touch .title {
  margin-bottom: 30px;
}

.rts-sidebar-menu-desktop .get-in-touch .wrapper .single i {
  color: var(--color-primary);
  margin-right: 10px;
  margin-bottom: 15px;
}

.rts-sidebar-menu-desktop .get-in-touch .wrapper .single i:last-child {
  margin-bottom: 0;
}

.rts-sidebar-menu-desktop .get-in-touch .wrapper .single a {
  transition: 0.3s;
  text-transform: lowercase;
}

.social-wrapper-two.menu {
  margin-top: 50px;
  margin-left: 12px;
  padding-left: 0;
}

.social-wrapper-two a {
  margin-right: 40px;
  z-index: 1;
  position: relative;
}

.social-wrapper-two a i {
  color: #fff;
  transition: 0.3s;
  font-size: 16px;
}

.social-wrapper-two a::after {
  position: absolute;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  content: "";
  z-index: -1;
  background: var(--color-primary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
}

.social-wrapper-two a:hover i {
  color: var(--color-primary);
}

.social-wrapper-two a:hover::after {
  background: #e2f0ff;
  box-shadow: 0px 10px 30px rgba(33, 5, 5, 0.05);
}

.logo-1 {
  display: block;
}

.logo-2 {
  display: none;
}

.logo-3 {
  display: none;
}

.logo-4 {
  display: none;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu-nav .mainmenu li a {
  color: #000;
  display: block;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu-nav .mainmenu li a.open {
  color: var(--color-primary);
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown>a::before {
  content: "\f078" !important;
  right: 10px;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown>a.open::before {
  content: "\f077" !important;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu::after {
  display: none;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu {
  padding-left: 0;
  margin-top: 50px;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu>li {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 11px 30px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu ul {
  padding: 0;
  margin: 0;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu {
  display: none !important;
  position: inherit;
  border-top: none;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu .tag {
  padding: 0 15px;
  margin: 20px 0 5px 0;
  font-size: 18px;
  font-weight: 500;
  color: #1C2539;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu .mobile-menu-link {
  margin: 0;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu .mobile-menu-link:hover a {
  color: var(--color-primary) !important;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu.third-lvl.mobile-menu li:hover a {
  color: var(--color-primary);
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu.active {
  display: block !important;
  visibility: visible;
  opacity: 1;
}

.rts-sidebar-menu-desktop .body-mobile .mainmenu li.has-droupdown .submenu.active .mobile-menu-link a {
  color: #000;
}




/* elements style hear  */
.rts-btn {
  padding: 17px 30px;
  min-width: max-content;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  border-radius: 15px;
  transition: var(--transition);
}

.rts-btn.btn-light-3 {
  background: #f64a0026;
  color: var(--color-primary-3);
  border-radius: 5px;
  padding: 10px 19px;
  display: block;
  max-width: max-content;
  transition: 0.3s;
}

.rts-btn.btn-light-3:hover {
  background: #fff;
  box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
}

.rts-btn:focus {
  outline: none;
  box-shadow: none;
}

.rts-btn.btn-primary {
  background: var(--color-primary);
}

.rts-btn.btn-primary.six {
  background: var(--color-primary-6);
  border-radius: 30px;
}

.rts-btn.btn-primary:hover {
  background: var(--color-primary-alta);
  color: #1C2539;
}

.rts-btn.btn-primary-2 {
  background: var(--color-primary-2);
  color: #fff;
}

.rts-btn.btn-primary-2:hover {
  background: #F7F7F7;
  color: var(--color-primary-2);
}


.rts-btn.btn-primary-4 {
  background: var(--color-primary-4);
  color: #fff;
}

.rts-btn.btn-primary-4.transparent {
  background: transparent;
  border: 1px solid #FFFFFF;
}

.rts-btn.btn-primary-4:hover {
  background: var(--color-gray);
  color: #1C2539;
}

.rts-btn.btn-primary-5 {
  background: var(--color-primary-5);
  color: #fff;
}

.rts-btn.btn-primary-5.six {
  background: var(--color-primary-6);
  border-radius: 30px;
}

.rts-btn.btn-primary-5.transparent {
  background: transparent;
  border: 1px solid #FFFFFF;
}

.rts-btn.btn-primary-5:hover {
  background: var(--color-gray);
  color: #1C2539;
}

.rts-btn.btn-secondary-3 {
  background: #fff;
  color: var(--color-primary-3);
}

.rts-btn.btn-secondary-3:hover {
  background: var(--color-primary-3);
  color: #fff;
}

.rts-btn.btn-primary-alta {
  background: var(--color-primary-alta);
  padding: 17px;
}

.rts-btn.btn-primary-alta:hover {
  background: var(--color-primary);
}

.rts-btn.btn-primary-alta:hover i {
  color: #fff;
}

.rts-btn.btn-primary-alta i {
  color: #1C2539;
  position: relative;
  margin-bottom: -2px;
  transition: 0.3s;
  line-height: 22px;
}

.rts-btn.btn-white {
  background: #fff;
  color: var(--color-primary);
  padding: 17px 35px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.rts-btn.btn-white:hover {
  background: var(--color-primary);
  color: #fff;
}

.rts-btn.rounded {
  border-radius: 50% !important;
  line-height: 7px;
  display: block;
  padding: 20px;
}

.rts-btn.rounded:hover {
  background: var(--color-primary-alta);
  color: #1C2539;
}
.rts-read-more {
  font-weight: 600;
  color: #1C2539;
  transition: var(--transition);
}

.rts-read-more i {
  padding: 13px;
  background: #fff;
  border-radius: 50%;
  margin-right: 12px;
  color: #1C2539;
  line-height: 12px;
  font-size: 14px;
  transition: var(--transition);
  box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
}

.rts-read-more.btn-primary {
  background: transparent;
  color: #1C2539;
}

.rts-read-more:hover {
  color: var(--color-primary);
}

.rts-read-more:hover i {
  background: var(--color-primary);
  color: var(--color-white);
}

.rts-read-more:focus {
  box-shadow: none;
  outline: none;
}


@media only screen and (max-width: 479px) {
  .header-one-btn.rts-btn {
    padding: 10px 7px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  #search {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px !important;
    padding: 17px 13px !important;
  }
}

#menu-btn {
  background: var(--color-primary-alta);
  height: 55px;
  width: 55px;
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 479px) {
  #menu-btn {
    background: var(--color-primary-alta);
    height: 44px;
    width: 42px;
    padding: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 479px) {
  #menu-btn {
    padding: 17px 13px;
  }
}

#menu-btn:hover {
  background: var(--color-primary);
}


#search {
  width: 55px;
}

@media only screen and (max-width: 575px) {
  .quote-btn {
    display: none;
  }
}

.quote-btnmenu {
  margin-top: 30px;
}

@media only screen and (min-width: 575px) {
  .quote-btnmenu {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .quote-btnmenu {
    display: block;
    max-width: max-content;
  }
}

.social-wrapper-two.menu.mobile-menu {
  margin-top: 35px;
  margin-left: 18px;
}

.social-wrapper-two.menu.mobile-menu a i {
  color: var(--color-primary);
}

.social-wrapper-two.menu.mobile-menu a::after {
  background: #fff;
  box-shadow: 0px 11px 30px rgba(0, 0, 0, 0.07);
}

.social-wrapper-two.menu.mobile-menu a:hover i {
  color: #fff;
}

.social-wrapper-two.menu.mobile-menu a:hover::after {
  background: var(--color-primary);
}


@media only screen and (max-width: 575px) {
  .home-violet .quote-btnmenu {
    display: block;
    max-width: max-content;
  }
}

.button-area1 .button-area-inner {
  max-width: 600px;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .button-area1 .button-area-inner {
    display: grid !important;
  }

  .button-area1 .button-area-inner .rts-btn {
    margin-bottom: 15px;
  }
}

.button-area1 .rts-btn {
  display: block;
}

.button-area2 .rts-btn {
  border: 1px solid var(--color-primary);
  border-radius: 0;
}

.button-area2 .rts-btn.two {
  border-radius: 15px;
}

.button-area2 .rts-btn.three {
  border-radius: 30px;
}

.button-area3 .rts-btn.one i {
  margin-left: 5px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-one .swiper-wrapper .swiper-slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mySwiperh2_Business_Cases .swiper-slide {
  border-radius: 20px;
}

.mySwiperh2_clients .swiper-slide {
  border-radius: 20px;
}

.mySwiperh3_business-case .swiper-slide {
  border-radius: 20px;
}

.mySwiperh1_team .swiper-slide {
  border-radius: 20px;
  padding-top: 20px;
  background: transparent;
}
.mySwiperh3_team {
  padding-bottom: 109px;
}

@media only screen and (max-width: 575px) {
  .mySwiperh3_team {
    padding-bottom: 30px;
  }
}

.mySwiperh2_Business_Cases .swiper-slide {
  background: transparent;
}

.rts-banner-one {
  position: relative;
}

.rts-banner-one .animation-img .shape-img {
  position: absolute;
}

.rts-banner-one .animation-img .shape-img.one {
  right: 3%;
  top: 34%;
  z-index: 1;
  animation: rotateIt2 25s linear infinite;
}

.rts-banner-one .animation-img .shape-img.two {
  right: -24px;
  top: 77%;
  z-index: 1;
  animation: jump-2 3s infinite;
}

.rts-banner-one .animation-img .shape-img.three {
  right: 13%;
  top: 21%;
  z-index: 1;
  animation: jump-1 3s infinite;
}

.banner-one-inner {
  width: 50%;
  margin-left: auto;
  padding: 160px 0 190px 0;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .banner-one-inner {
    width: 70%;
  }
}

@media only screen and (max-width: 1199px) {
  .banner-one-inner {
    margin-left: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .banner-one-inner {
    width: 90%;
  }
}

.banner-one-inner .pre-title {
  color: #1C2539;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: var(--font-secondary);
  letter-spacing: 1px;
}

.banner-one-inner .pre-title span {
  color: #015fc9;
  font-weight: 800;
}

.banner-one-inner .title {
  font-weight: 700;
  line-height: 82px;
  margin-bottom: 23px;
  font-size: 60px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-one-inner .title {
    line-height: 70px;
    margin-bottom: 23px;
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-one-inner .title {
    font-size: 46px;
    line-height: 63px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-one-inner .title {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-one-inner .title {
    font-size: 23px;
    line-height: 45px;
  }
}

.banner-one-inner .title span {
  font-style: italic;
  color: #015fc9;
  font-weight: 300;
  font-size: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-one-inner .title span {
    font-size: 45px !important;
  }
  p.disc.banner-para{
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 767px) {
  .banner-one-inner .title span {
    font-size: 46px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-one-inner .title span {
    font-size: 32px;
  }
}

.banner-one-inner p {
  color: #1C2539;
}

.banner-one-inner .rts-btn {
  display: block;
  margin-top: 43px;
  max-width: max-content;
}
.index-six .mainmenu li.has-droupdown .submenu li a:hover {
  color: var(--color-primary-6);
}

.banner-one .swiper-slide-active .pre-title {
  animation: fadeInUp 1.5s;
  animation-delay: 0s;
}

.banner-one .swiper-slide-active .title {
  animation: fadeInUp 1.5s;
  animation-delay: 0s;
}

.banner-one .swiper-slide-active p.disc {
  animation: fadeInUp 2s;
  animation-delay: 0s;
}

.banner-one .swiper-slide-active a.rts-btn {
  animation: fadeInUp 2.5s;
  animation-delay: 0s;
}
.rts-banner-one .swiper-wrapper .swiper-slide {
  position: relative;
}

.rts-banner-one .swiper-wrapper .swiper-slide::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.336);
  display: none;
}

@media only screen and (max-width: 1199px) {
  .rts-banner-one .swiper-wrapper .swiper-slide::after {
    display: block;
  }
}

.rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner {
  position: relative;
  z-index: 1;
  /* height: 80vh; */
}

.rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner .shape-img.one {
  position: absolute;
  right: 40px;
  bottom: 100px;
  height: 436px;
  width: 436px;
  z-index: -1;
  animation: rotateIt2 25s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner .shape-img.one {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 1199px) {
  .rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner .shape-img.one {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner .shape-img.one {
    width: 300px;
    height: 300px;
    right: -15%;
  }
}

@media only screen and (max-width: 767px) {
  .rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner .shape-img.one {
    width: 250px;
    height: 250px;
    right: -15%;
  }
}

@media only screen and (max-width: 575px) {
  .rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner .shape-img.one {
    height: 150px;
    width: 150px;
    right: 15%;
  }
}

.banner-three {
  position: relative;
}

.banner-three::before {
  position: absolute;
  content: "";
  /* background-image: url(../images/banner/shape/04.png); */
  height: 100%;
  width: 650px;
  background-size: cover;
  right: 0;
  bottom: 0;
  z-index: 3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-three::before {
    width: 370px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-three::before {
    width: 350px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-three::before {
    width: 250px;
  }
}

p.disc.banner-para {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

@media only screen and (max-width: 575px) {
  p.disc.banner-para {
    font-size: 16px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .col-xl-6.col-lg-6.col-md-6.col-sm-12.col-12.breadcrumb-1 {
    text-align: center;
  }
}

.rts-btn.color-h-black:hover {
  background: var(--color-primary-alta);
    color: #1C2539;
}

.onepage .header-four.header-five .nav-main ul li:hover a.nav-link::before {
  display: none;
}
.cta-two-wrapper .rts-btn.btn-primary {
  background: #fa360a !important;
}

.about-inner p.disc {
  margin-bottom: 30px;
}

.bg-about-sm-shape {
  position: relative;
}

.bg-about-sm-shape::after {
  content: "";
  position: absolute;
  height: 150px;
  width: 150px;
  background-image: url(./pages/images/about/shape/01.png);
  background-repeat: no-repeat;
  top: 32%;
  animation: jump-1 8s linear infinite;
}

@media only screen and (max-width: 767px) {
  .bg-about-sm-shape::after {
    right: -15%;
  }
}

@media only screen and (max-width: 479px) {
  .bg-about-sm-shape::after {
    right: -32%;
  }
}

.bg-about-sm-shape::before {
  content: "";
  position: absolute;
  height: 150px;
  width: 150px;
  background-image: url(./pages/images/about/shape/02.png);
  background-repeat: no-repeat;
  top: 60%;
  right: -2%;
  animation: rotateIt 10s linear infinite;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-about-sm-shape::before {
    top: 76%;
  }
}

@media only screen and (max-width: 767px) {
  .bg-about-sm-shape::before {
    top: 76%;
    height: 100px;
    width: 100px;
  }
}

.about-success-wrapper .single {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  align-items: flex-start;
}

.about-success-wrapper .single i {
  padding: 4px;
  background: #0ce0ff;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  margin-right: 15px;
  margin-top: 5px;
}

.about-success-wrapper .single p.details {
  font-size: 16px;
  color: #1C2539;
  font-weight: 500;
}

.about-one-thumbnail {
  display: flex;
  justify-content: end;
  position: relative;
}

.about-one-thumbnail .small-img {
  position: absolute;
  left: 0%;
  top: 7%;
  animation: jump-1 10s linear infinite;
}

.about-one-thumbnail .experience {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 32px;
  background: var(--color-primary);
  border-radius: 15px;
  bottom: 7%;
  left: 1%;
  animation: jump-2 6s linear infinite;
  width: 310px;
}

.about-one-thumbnail .experience .single {
  width: 50%;
}

.about-one-thumbnail .experience .left .title {
  color: #fff;
  margin-bottom: 0;
  font-family: var(--font-secondary);
}

.about-one-thumbnail .experience .left p {
  font-size: 22px;
  color: #fff;
  font-family: var(--font-secondary);
  font-weight: 700;
  margin-top: -8px;
}

.about-one-thumbnail .experience .right p {
  color: #fff;
  line-height: 24px;
  font-size: 18px;
  font-weight: 700;
}

.about-image-v-inner .image-area {
  display: flex;
  position: relative;
}

.about-image-v-inner .image-area .img-1 {
  border-top: 10px solid var(--color-primary);
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .about-image-v-inner .image-area .img-1 {
    width: 65%;
  }
}

.about-image-v-inner .image-area .img-over {
  position: absolute;
  right: 0;
  top: 0;
  animation: jump-2 5s linear infinite;
}

@media only screen and (max-width: 767px) {
  .about-image-v-inner .image-area .img-over {
    width: 50%;
  }
}

.about-progress-inner {
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-progress-inner {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-progress-inner {
    padding-left: 0;
  }
}

.about-progress-inner .title-area span {
  color: var(--color-primary);
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: 600;
}

.about-progress-inner .title-area .title {
  margin-top: 5px;
  text-transform: capitalize;
}

.about-progress-inner .inner .rts-progress-one-wrapper {
  padding: 40px;
  background: #F7F7F7;
}

@media only screen and (max-width: 767px) {
  .about-progress-inner .inner .rts-progress-one-wrapper {
    padding: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .about-progress-inner .inner .rts-progress-one-wrapper {
    padding: 15px;
  }
}

.about-progress-inner .inner .rts-progress-one-wrapper .meter {
  background: #fff;
  height: 15px;
  display: flex;
  align-items: center;
  padding-left: 7px;
  overflow: hidden;
}

.about-progress-inner .inner .rts-progress-one-wrapper .meter span {
  height: 3px;
}

.about-progress-inner .inner .rts-progress-one-wrapper .single-progress {
  border-bottom: none;
}

.about-progress-inner .inner .rts-progress-one-wrapper .single-progress .progress-top p {
  color: #1C2539;
}

.about-progress-inner .inner .rts-progress-one-wrapper .single-progress .progress-top span {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 16px;
}

.about-progress-inner .inner .rts-progress-one-wrapper .single-progress:last-child {
  margin-bottom: 0;
}

.about-progress-inner .inner a.rts-btn {
  display: block;
  max-width: max-content;
  margin-top: 40px;
}

.about-progress-inner.inner1 {
  max-width: 700px;
  margin: 0 auto;
}

.about-two-bg {
  /* background-image: url(../images/about/bg-01.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
}
.contoler-company {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .contoler-company {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .contoler-company .tab-content {
    margin-top: 30px;
  }
}

.about-company-wrapper p.disc {
  color: #5D666F;
  font-size: 18px;
  margin-bottom: 60px;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .about-company-wrapper p.disc {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .about-company-wrapper p.disc br {
    display: none;
  }
}

.rts-tab-style-one .button-area {
  padding: 30px;
  border: 1px solid #EDEFF3;
  border-radius: 20px;
  background: #fff;
  text-align: left;
  min-width: max-content;
}

@media only screen and (max-width: 767px) {
  .rts-tab-style-one .button-area {
    padding: 10px;
  }
}

.rts-tab-style-one .button-area button {
  background: transparent !important;
  color: #5D666F;
  padding-bottom: 20px;
  border-bottom: 1px solid #EDEFF3;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .rts-tab-style-one .button-area button {
    font-size: 14px;
  }
}

.rts-tab-style-one .button-area button:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.rts-tab-style-one .button-area button.active {
  color: #015fc9;
}

.rts-tab-style-one .tab-content {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .rts-tab-style-one .tab-content {
    margin-left: 10px;
  }
}

.rts-tab-style-one .tab-content .rts-tab-content-one {
  margin-right: -8px;
}

.rts-tab-style-one .tab-content .rts-tab-content-one p.disc {
  font-size: 16px;
  font-weight: 500;
  color: #1C2539;
  line-height: 26px;
  margin-top: -4px;
  margin-bottom: 24px;
}

.rts-tab-style-one .tab-content .rts-tab-content-one .check-area {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.rts-tab-style-one .tab-content .rts-tab-content-one .check-area:last-child {
  margin-bottom: 0;
}

.rts-tab-style-one .tab-content .rts-tab-content-one .check-area i {
  color: var(--color-primary-2);
  font-size: 18px;
}

.rts-tab-style-one .tab-content .rts-tab-content-one .check-area p {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 16px;
  color: #5D666F;
  font-weight: 400;
  margin-bottom: -6px;
}

.rts-tab-style-one .tab-content .rts-tab-content-one a.rts-btn {
  margin-top: 40px;
  display: block;
  max-width: max-content;
}


.about-company-thumbnail {
  position: relative;
  z-index: 1;
}

.about-company-thumbnail::after {
  position: absolute;
  background-image: url(./pages/images/about/shape/03.png);
  content: "";
  height: 517.43px;
  min-width: 712px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  top: -10%;
  left: 4%;
  animation: jump-1 5s linear infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .about-company-thumbnail::after {
    height: 447.43px;
    min-width: 660px;
  }
}

@media only screen and (max-width: 1199px) {
  .about-company-thumbnail::after {
    height: 380.43px;
    min-width: 712px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-company-thumbnail::after {
    height: 96%;
  }
}

@media only screen and (max-width: 767px) {
  .about-company-thumbnail::after {
    height: 391.43px;
    min-width: 712px;
  }
}

@media only screen and (max-width: 479px) {
  .about-company-thumbnail::after {
    height: 100%;
    min-width: 708px;
  }
}

.about-company-thumbnail img {
  margin-left: 40px;
}

@media only screen and (max-width: 767px) {
  .about-company-thumbnail img {
    width: 76%;
  }
}

.rts-about-our-company-h2 {
  position: relative;
}

.rts-about-our-company-h2::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  contain: "";
  height: 235.7px;
  width: 213.54px;
  background-image: url(./pages/images/about/shape/04.png);
  background-repeat: no-repeat;
  animation: jump-2 5s linear infinite;
  z-index: -1;
}

@media only screen and (max-width: 575px) {
  .rts-about-our-company-h2::after {
    display: none;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .rts-about-our-company-h2.eight .title-area .title br {
    display: none;
  }
}

@media (max-width: 450px) {
  .rts-about-our-company-h2.eight .title-area .title br {
    display: none;
  }
}

.rts-about-our-company-h2.eight .title-area::after {
  content: "04";
}

@keyframes floating {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}


.background-service {
  background-image: url(./pages/images/service/bg-01.jpg);
  padding: 70px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 575px) {
  .background-service {
    padding: 20px;
  }
}
.plr--120-service {
  padding: 0 120px;
}

@media only screen and (max-width: 1199px) {
  .plr--120-service {
    padding: 0;
  }
}

.service-one-inner {
  display: flex;
  padding: 52px 35px 40px 64px;
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service-one-inner {
    padding: 36px 19px 40px 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-one-inner {
    padding: 42px 18px 25px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .service-one-inner {
    padding: 42px 18px 25px 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .service-one-inner::after {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .service-one-inner::before {
    display: none;
  }
}

.service-one-inner::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background: #0001;
  right: -15px;
}

.service-one-inner.one::before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background: #0001;
  bottom: 0;
  left: 13%;
}

.service-one-inner.two::before {
  position: absolute;
  content: "";
  width: 103%;
  height: 1px;
  background: #0001;
  bottom: 0;
  left: 0;
}

.service-one-inner.three::before {
  position: absolute;
  content: "";
  width: 103%;
  height: 1px;
  background: #0001;
  bottom: 0;
  left: 0;
}

.service-one-inner.three::after {
  display: none;
}

.service-one-inner.four::after {
  position: absolute;
  content: "";
  height: 71%;
  width: 1px;
  background: #0001;
  right: -15px;
}

.service-one-inner.five::after {
  position: absolute;
  content: "";
  height: 71%;
  width: 1px;
  background: #0001;
  right: -15px;
}

.service-one-inner.six::after {
  position: absolute;
  content: "";
  height: 71%;
  width: 1px;
  background: #0001;
  right: -15px;
  display: none;
}

.service-one-inner .thumbnail {
  margin-right: 37px;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .service-one-inner .thumbnail {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service-one-inner .thumbnail {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .service-one-inner .thumbnail {
    margin-right: 20px;
  }
}

.service-one-inner .thumbnail img {
  max-width: 65px;
  height: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service-one-inner .thumbnail img {
    max-width: 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .service-one-inner .thumbnail img {
    max-width: 40px;
  }
}

.service-one-inner .service-details .title {
  margin-bottom: 7px;
  text-transform: capitalize;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .service-one-inner .service-details .title {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .service-one-inner .service-details .title {
    font-size: 20px;
  }
}

.service-one-inner .service-details p {
  margin-bottom: 20px;
  text-align: left !important;
}

.color-primary {
  color: var(--color-primary);
}

.color-primary.sub {
  font-weight: 600;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 479px) {
  .color-primary.sub {
    font-size: 14px;
  }
}

.service-detials-step-1 .thumbnail {
  margin-bottom: 45px;
}

.service-detials-step-1 .title {
  margin-bottom: 16px;
  letter-spacing: 2px;
}

.service-detials-step-1 p.disc {
  margin-bottom: 23px;
  font-size: 16px;
  line-height: 26px;
}

.service-detials-step-1 .service-details-card {
  padding: 30px;
  box-shadow: 0px 8px 30px #6a6a6a1a;
  display: flex;
  align-items: flex-start;
  border-left: 5px solid var(--color-primary);
}

.service-detials-step-1 .service-details-card .thumbnail img {
  height: 29px;
  width: 74px;
}

.service-detials-step-1 .service-details-card .details {
  margin-left: 20px;
}

.service-detials-step-1 .service-details-card .details .title {
  margin-bottom: 5px;
  font-size: 20px;
  text-transform: capitalize;
}

.service-detials-step-1 .service-details-card .details p {
  margin-bottom: 0;
}
.service-detials-step-3 .title {
  margin-bottom: 8px;
}

.service-detials-step-3 p.disc {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

.service-detials-step-3 .single-banifits {
  margin-bottom: 10px;
}

.service-detials-step-3 .single-banifits i {
  margin-right: 15px;
  color: var(--color-primary);
}

.service-two-bg {
  background-image: url(./pages/images/service/bg-02.jpg);
}

.service-two-inner {
  box-shadow: 0px 13px 40px #1810100a;
  position: relative;
  border-radius: 15px;
}

.service-two-inner .thumbnail {
  overflow: hidden;
  display: block;
  border-radius: 15px 15px 0 0;
  position: relative;
}

.service-two-inner .thumbnail::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "05";
  font-size: 18px;
  background: #fff;
  border-radius: 0 0 0 15px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  font-weight: 700;
}

.service-two-inner .thumbnail.two::after {
  content: "07";
}

.service-two-inner .thumbnail.three::after {
  content: "08";
}

.service-two-inner .thumbnail.four::after {
  content: "06";
}

.service-two-inner .thumbnail.five::after {
  content: "01";
}

.service-two-inner .thumbnail.six::after {
  content: "02";
}

.service-two-inner .thumbnail.seven::after {
  content: "03";
}

.service-two-inner .thumbnail.eight::after {
  content: "04";
}

.service-two-inner .thumbnail.nine::after {
  content: "10";
}
.service-two-inner .thumbnail.ten::after {
  content: "11";
}
.service-two-inner .thumbnail.eleven::after {
  content: "09";
}

.service-two-inner .thumbnail img {
  width: 100%;
  border-radius: 15px 15px 0 0;
  transition: 0.3s;
}

.service-two-inner .body-content {
  z-index: 5;
  padding: 40px;
  background: #fff;
  border-radius: 0 0 15px 15px;
  position: absolute;
  bottom: -50%;
}

@media only screen and (max-width: 767px) {
  .service-two-inner .body-content {
    bottom: -37%;
  }
}

@media only screen and (max-width: 479px) {
  .service-two-inner .body-content {
    padding: 20px;
  }
}

.service-two-inner .body-content .hidden-area {
  max-height: 84px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}

.service-two-inner .body-content .title {
  margin-bottom: 8px;
  font-size: 22px;
}

.service-two-inner .body-content p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #5D666F;
  margin-bottom: 15px;
}

.service-two-inner .body-content .rts-read-more-two {
  color: var(--color-primary);
  font-weight: 700;
  font-size: 16px;
  align-items: center;
  display: flex;
}

.service-two-inner .body-content .rts-read-more-two i {
  margin-left: 5px;
  transition: 0.3s;
}

.service-two-inner .body-content .rts-read-more-two:hover i {
  margin-left: 8px;
}

.service-two-inner:hover .thumbnail img {
  transform: scale(1.15);
}

.service-two-inner:hover .body-content .hidden-area {
  max-height: 150px;
  bottom: 0;
}

@media only screen and (max-width: 479px) {

  .col-xl-4.col-md-6.col-sm-12.col-12.pb--140.pb_md--100,
  .col-xl-4.col-md-6.col-sm-12.col-12.pb--140.pb_md--60 {
    padding-bottom: 80px !important;
  }
}

@media only screen and (max-width: 479px) {
  .col-xl-4.col-md-6.col-sm-12.col-12.pb--140.pb_md--60.pb_sm--60 {
    padding-bottom: 30px !important;
  }
}

.padding-controler {
  margin-bottom: -75px;
}

/* .accordion-service-bg {
  background-image: url(../images/service/bg-03.jpg);
} */

@media only screen and (max-width: 1199px) {
  .accordion-service-bg {
    background-image: none;
    background: #F6F6F6;
  }
}

.accordion-service-bg.inner {
  background-image: none;
}

.rts-accordion-area.service {
  padding-left: 120px;
  padding-right: 120px;
}

@media only screen and (max-width: 1199px) {
  .rts-accordion-area.service {
    padding-left: 0;
    padding-right: 0;
  }
}

.rts-accordion-area.inner {
  background-color: var(--color-gray);
}

.accordion-service-inner .accordion-area {
  padding-right: 130px;
}

@media only screen and (max-width: 575px) {
  .accordion-service-inner .accordion-area {
    padding-right: 15px;
  }
}

.accordion-service-inner .accordion-area .accordion {
  margin-top: 45px;
}

@media only screen and (max-width: 575px) {
  .accordion-service-inner .accordion-area .accordion {
    margin-left: 0;
    margin-top: 30px;
  }
}

.accordion-service-inner .accordion-area .accordion .accordion-item {
  background: transparent;
  border: none;
  padding-bottom: 30px;
  position: relative;
  padding-left: 60px;
}

.accordion-service-inner .accordion-area .accordion .accordion-item:last-child {
  padding-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item {
    padding-left: 98px;
  }
}

@media only screen and (max-width: 767px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item {
    padding-left: 52px;
  }
}

@media only screen and (max-width: 575px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item {
    padding-left: 0;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item {
    padding-left: 0;
  }
}

.accordion-service-inner .accordion-area .accordion .accordion-item:last-child::after {
  display: none;
}

.accordion-service-inner .accordion-area .accordion .accordion-item::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  border: 1px dashed #0d6efd;
  top: 3px;
  left: 18px;
  position: absolute;
}

@media only screen and (max-width: 575px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header {
    margin-bottom: 10px;
  }
}

.accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button {
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  box-shadow: none;
  padding: 0;
  position: relative;
  color: #1C2539;
  cursor: default;
}

@media only screen and (max-width: 767px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true] {
    color: var(--color-primary);
  }
}

.accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
  content: "";
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  left: -11.5%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  background-color: #fff;
  z-index: 5;
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
    left: -51px;
  }
}

@media only screen and (max-width: 1199px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
    left: -12.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
    left: -18.5%;
  }
}

@media only screen and (max-width: 575px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
    display: none;
  }
}

.accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true]::before {
  content: "";
  position: absolute;
  left: 0;
  background-image: none;
  height: 50px;
  width: 50px;
  left: -14.6%;
  background: var(--color-primary);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  z-index: 5;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true]::before {
    left: -66px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true]::before {
    left: -21.6%;
  }
}

@media only screen and (max-width: 767px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true]::before {
    left: -17.6%;
  }
}

@media only screen and (max-width: 575px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true]::before {
    display: none;
  }
}

.accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button:focus {
  box-shadow: none;
}

.accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::after {
  display: none;
}

.accordion-service-inner .accordion-area .accordion .accordion-item .accordion-body {
  padding: 0;
}


.service-one-inner-four:hover .thumbnail img {
  transform: scale(1.2);
}

.title-service-three p {
  color: var(--color-primary-3);
  margin-bottom: 0;
}

.title-service-three .title {
  margin-top: 10px;
  text-transform: uppercase;
}


@media only screen and (max-width: 767px) {
  .bg-service-h2 {
    padding-top: 294px;
  }
}

.rts-single-service-h2 {
  padding: 25px;
  border-radius: 20px;
  background: #fff;
  border: 1px solid #E4E7EE;
  transition: 0.3s;
}

.rts-single-service-h2.inner .body a:hover .title {
  color: var(--color-primary);
}

.rts-single-service-h2.inner:hover .body a.btn-red-more {
  color: var(--color-primary);
}

.rts-single-service-h2 .thumbnail {
  overflow: hidden;
  display: block;
  border-radius: 20px;
}

.rts-single-service-h2 .thumbnail img {
  width: 100%;
  transition: 0.4s;
}

.rts-single-service-h2 .body {
  margin-top: 22px;
}

.rts-single-service-h2 .body a:hover .title {
  color: var(--color-primary-2);
}

.rts-single-service-h2 .body .title {
  margin-bottom: 10px;
  transition: 0.3s;
  font-size: 22px;
}

.rts-single-service-h2 .body p.disc {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 12px;
}

.rts-single-service-h2 .body a.btn-red-more {
  color: #1C2539;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.rts-single-service-h2 .body a.btn-red-more i {
  margin-left: 0;
  opacity: 0;
  transition: 0.3s;
}

.rts-single-service-h2:hover {
  box-shadow: 0px 12px 24px rgba(18, 22, 34, 0.07);
}

.rts-single-service-h2:hover .thumbnail img {
  transform: scale(1.2);
}

.rts-single-service-h2:hover .body a.btn-red-more {
  color: var(--color-primary-2);
}

.rts-single-service-h2:hover .body a.btn-red-more i {
  margin-left: 7px;
  opacity: 1;
}

.navigation-center-bottom {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation-center-bottom.service .swiper-button-next {
    right: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .navigation-center-bottom.service .swiper-button-next {
    right: 40%;
  }
}

@media only screen and (max-width: 575px) {
  .navigation-center-bottom.service .swiper-button-next {
    right: 36%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation-center-bottom.service .swiper-button-prev {
    left: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .navigation-center-bottom.service .swiper-button-prev {
    left: 40%;
  }
}

@media only screen and (max-width: 575px) {
  .navigation-center-bottom.service .swiper-button-prev {
    left: 36%;
  }
}

.navigation-center-bottom .swiper-pagination {
  max-width: fit-content;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  bottom: -23px;
  font-weight: 700;
  font-size: 16px;
  color: #1C2539;
}

.navigation-center-bottom .swiper-pagination .swiper-pagination-current {
  color: var(--color-primary-3);
}

.navigation-center-bottom .swiper-button-next {
  right: 45%;
}

.navigation-center-bottom .swiper-button-next::after {
  content: "\f061";
  font-family: "FontAwesome";
  font-size: 16px;
  color: #1C2539;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(24, 16, 16, 0.06);
  border-radius: 8px;
  transition: 0.3s;
}

.navigation-center-bottom .swiper-button-next:hover::after {
  background: var(--color-primary-3);
  color: #fff;
}

.navigation-center-bottom .swiper-button-prev {
  left: 45%;
}

.navigation-center-bottom .swiper-button-prev::after {
  content: "\f060";
  font-family: "FontAwesome";
  font-size: 16px;
  color: #1C2539;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 10px 20px #1810100f;
  border-radius: 8px;
  transition: 0.3s;
}

.navigation-center-bottom .swiper-button-prev:hover::after {
  background: var(--color-primary-3);
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .thumbnail.sm-thumb-service img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .thumbnail.sm-thumb-service img {
    width: 100%;
  }
}

.about-service-width-controler {
  max-width: 87.5%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-service-width-controler {
    max-width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .about-service-width-controler {
    max-width: 100%;
    margin-right: -7px;
  }
}

.rts-service-area4 .container .title-service-three .pre-title {
  color: var(--color-primary-4);
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: "Red Hat Display", sans-serif;
}

.rts-service-area4 .container .title-service-three .title {
  text-transform: none;
}

@media (max-width: 767px) {
  .rts-service-area4 .container .title-service-three .title {
    margin-bottom: 0;
  }
}
.rts-service-area5 {
  margin-top: -150px;
}

@media (max-width: 1200px) {
  .rts-service-area5 {
    margin-top: 50px;
  }
}

.rts-service-area5 .container .service-one-inner-four:hover {
  transform: translateY(-15px);
}


.service-single-style-six {
  background: linear-gradient(180deg, rgba(235, 235, 235, 0) 0%, #F7F7F7 100%);
  border-radius: 20px;
  padding: 100px 40px 40px 40px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .service-single-style-six {
    margin-bottom: 60px;
  }
}

.service-single-style-six .icon {
  position: absolute;
  top: -70px;
  left: 10px;
  transition: 0.6s;
  z-index: 2;
}

.service-single-style-six .icon img {
  position: relative;
  z-index: 5;
  transition: 1s;
}

.service-single-style-six .icon::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 56%;
  height: 40px;
  width: 40px;
  background: #000;
  filter: blur(27px);
  transform: translate(-50%, -50%);
  z-index: 0;
}

.service-single-style-six:hover .icon img {
  transform: rotate(-360deg);
}

.team-bg-image-style-six img {
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .team-bg-image-style-six img {
    height: 450px;
  }
}

@keyframes rotate-45 {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(45deg) scale(0.8);
  }

  100% {
    transform: rotate(0deg) scale(1.1);
  }
}

.rts-service-area.eight {
  background: #f7f7f7;
}

.rts-service-area.eight .rts-title-area .pre-title {
  color: #5d666f;
}

.rts-service-area.eight .rts-title-area::after {
  content: "03";
}

.rts-service-area.eight .background-service {
  background: none;
  padding: 0 70px;
}

@media (max-width: 575px) {
  .rts-service-area.eight .background-service {
    padding: 20px;
  }
}

.rts-service-area.eight .background-service .service-one-inner .service-details .rts-read-more:hover {
  color: var(--color-primary-2);
}

.rts-service-area.eight .background-service .service-one-inner .service-details .rts-read-more:hover i {
  background: var(--color-primary-2);
}

.cta-one-bg {
  background-image: url(./pages/images/cta/01.webp);
  background-repeat: no-repeat;
  background-color: #0d6efd;
  background-blend-mode: multiply;
  overflow: hidden;
  background-position: center;
  object-fit: contain;
  background-size: cover;
}
.cta-one-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-one-inner {
    padding: 50px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .cta-one-inner {
    padding: 50px 60px;
    flex-direction: column;
  }
}

.cta-one-inner .cta-left {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .cta-one-inner .cta-left {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .cta-one-inner .cta-right {
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .cta-one-inner .cta-right .rts-btn {
    padding: 13px 20px;
    font-size: 14px;
  }
}

.cta-one-inner .title {
  color: #fff;
  margin-bottom: 0;
  font-size: 40px;
}

@media only screen and (max-width: 1199px) {
  .cta-one-inner .title {
    font-size: 24px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .cta-one-inner .title {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
  }
}

.business-goal-one {
  position: relative;
}

.business-goal-one img.small {
  position: absolute;
  top: 9%;
  transform: translateY(-50%);
  right: 2%;
  animation: jump-2 5s linear infinite;
  z-index: 7;
}

@media only screen and (max-width: 575px) {
  .business-goal-one img.small {
    width: 50%;
    top: 37%;
  }
}

.rts-business-goal {
  margin-top: 50px;
}

.rts-business-goal .single-goal {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.rts-business-goal .single-goal:last-child {
  margin-bottom: 0;
}

.rts-business-goal .single-goal img {
  margin-right: 30px;
}

.rts-business-goal .single-goal .goal-wrapper .title {
  margin-bottom: 5px;
}

.rts-business-goal .single-goal .goal-wrapper p {
  color: #5D666F;
}
.counter-bg {
  background-image: url(./pages/images/counterup/bg-01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1199px) {
  .counter-bg {
    height: auto;
  }
}

.counter-bg.bg-2 {
  height: auto;
}

.single-counter {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .single-counter.two {
    justify-content: flex-start !important;
    padding-left: 0 !important;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-counter.three {
    justify-content: flex-start !important;
    padding-left: 0 !important;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-counter.three {
    padding-left: 0 !important;
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 575px) {
  .single-counter.three {
    justify-content: flex-start !important;
  }
}

@media only screen and (max-width: 1199px) {
  .single-counter.four {
    justify-content: flex-start !important;
    padding-left: 0 !important;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-counter.four {
    justify-content: flex-start !important;
    padding-left: 61px !important;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-counter.four {
    justify-content: flex-start !important;
    padding-left: 32px !important;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .single-counter.four {
    justify-content: flex-start !important;
    padding-left: 0 !important;
  }
}

.single-counter img {
  margin-right: 25px;
}

.single-counter .counter-details .title {
  color: #fff;
  margin-bottom: -2px;
  font-weight: 700;
}

.single-counter .counter-details .title span {
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-counter .counter-details .title span {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .single-counter .counter-details .title span {
    font-size: 26px;
  }
}

.single-counter .counter-details .title span::after {
  position: absolute;
  right: -31px;
  top: 50%;
  transform: translateY(-50%);
  content: "+";
}

.single-counter .counter-details .title.rate span::after {
  content: "%";
  right: -40px;
}

@media only screen and (max-width: 767px) {
  .single-counter .counter-details .title.rate span::after {
    right: -40px;
  }
}

.single-counter .counter-details p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-counter .counter-details p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .single-counter .counter-details p {
    font-size: 16px;
  }
}

.counter-wrapper-two .single-counter {
  position: relative;
}

.counter-wrapper-two .single-counter img {
  margin-right: 0;
  position: absolute;
}

.counter-wrapper-two .single-counter .counter-details {
  padding: 20px;
  box-shadow: 0px 2px 20px #18101012;
  width: 100%;
  padding: 40px 40px 40px 70px;
  margin-left: 60px;
}

@media only screen and (max-width: 1199px) {
  .counter-wrapper-two .single-counter .counter-details {
    padding: 20px 15px 20px 50px;
  }
}

@media only screen and (max-width: 479px) {
  .counter-wrapper-two .single-counter .counter-details {
    padding: 30px 26px 30px 54px;
  }
}

.counter-wrapper-two .single-counter .counter-details .title {
  color: #1C2539;
}

.counter-wrapper-two .single-counter .counter-details .disc {
  color: #1C2539;
}

.counter-5 {
  background: #E9EFFF;
}

.counter-5 .single-counter .counter-details .title {
  color: #000;
}

.counter-5 .single-counter .counter-details p {
  color: #000;
}

.w-g-100 {
  width: 100%;
}

.thumbnail-gallery img {
  object-fit: contain;
  margin-top: -5px;
}

.gallery-bg {
  /* background-image: url(../images/gallery/01.svg); */
  position: relative;
  /* margin-top: -23%; */
}

@media only screen and (max-width: 1199px) {
  .gallery-bg {
    margin-top: 0;
  }
}

.bg-right-gallery {
  /* background-image: url(../images/gallery/gallery-bg-1.jpg); */
  height: 100%;
  object-fit: cover;
  width: 100%;
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: contain;
  padding: 50px;
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-right-gallery {
    background-size: cover;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-right-gallery {
    background-size: cover;
    border-radius: 20px;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 575px) {
  .bg-right-gallery {
    padding: 25px 25px 44px 25px;
  }
}

.bg-right-gallery::after {
  content: "";
  height: 350px;
  width: 350px;
  left: -40%;
  position: absolute;
  /* background-image: url(../images/gallery/shape.png); */
  opacity: 0.1;
  animation: jump-1 2s linear infinite;
}

.bg-right-gallery .icon {
  margin-top: 48px;
}

@media only screen and (max-width: 1400px) {
  .bg-right-gallery .icon {
    margin-top: 0;
  }
}

.bg-right-gallery .icon img {
  max-width: 100px;
}

@media only screen and (max-width: 1199px) {
  .bg-right-gallery .icon img {
    max-width: 56px;
  }
}

.bg-right-gallery .title {
  color: #fff;
  margin-top: 40px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1199px) {
  .bg-right-gallery .title {
    margin-top: 20px;
    font-size: 20px;
  }
}

.bg-right-gallery span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.bg-right-gallery p.disc {
  margin-top: 16px;
  color: #B3B7C1;
  margin-bottom: 45px;
  line-height: 26px;
}

@media only screen and (max-width: 1199px) {
  .bg-right-gallery p.disc {
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 24px;
  }
}

.swiper-wrapper.gallery {
  padding-left: 9px;
}

.swiper-wrapper.gallery .swiper-slide {
  background: transparent;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .swiper-wrapper.gallery .swiper-slide {
    justify-content: center;
  }
}

.swiper.mygallery {
  overflow: visible;
}

.swiper.mygallery .swiper-button-next {
  top: -16%;
  right: 32px;
}

@media only screen and (max-width: 1199px) {
  .swiper.mygallery .swiper-button-next {
    top: -22%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper.mygallery .swiper-button-next {
    top: -12%;
  }
}

@media only screen and (max-width: 767px) {
  .swiper.mygallery .swiper-button-next {
    top: -12%;
  }
}

@media only screen and (max-width: 575px) {
  .swiper.mygallery .swiper-button-next {
    top: -5%;
  }
}

.swiper.mygallery .swiper-button-next::after {
  content: "\f061";
  font-family: "FontAwesome";
  font-size: 16px;
  color: #1C2539;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 10px 20px rgba(24, 16, 16, 0.06);
  border-radius: 8px;
  transition: 0.3s;
}

.swiper.mygallery .swiper-button-next:hover::after {
  background: var(--color-primary);
  color: #fff;
}

.swiper.mygallery .swiper-button-next.six:hover::after {
  background: var(--color-primary-6);
}

.swiper.mygallery .swiper-button-prev {
  top: -16%;
  right: 165px;
  left: auto;
}

@media only screen and (max-width: 1199px) {
  .swiper.mygallery .swiper-button-prev {
    top: -22%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper.mygallery .swiper-button-prev {
    top: -12%;
  }
}

@media only screen and (max-width: 767px) {
  .swiper.mygallery .swiper-button-prev {
    top: -12%;
    right: 115px;
  }
}

@media only screen and (max-width: 575px) {
  .swiper.mygallery .swiper-button-prev {
    top: -5%;
  }
}

.swiper.mygallery .swiper-button-prev::after {
  content: "\f060";
  font-family: "FontAwesome";
  font-size: 16px;
  color: #1C2539;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 10px 20px #1810100f;
  border-radius: 8px;
  transition: 0.3s;
}

.swiper.mygallery .swiper-button-prev:hover::after {
  background: var(--color-primary);
  color: #fff;
}

.swiper.mygallery .swiper-button-prev.six:hover::after {
  background: var(--color-primary-6);
}

.swiper.mygallery .swiper-pagination {
  top: -18%;
  left: auto;
  max-width: max-content;
  right: 100px;
  height: max-content;
  font-weight: 700;
  color: #1C2539;
}

@media only screen and (max-width: 1199px) {
  .swiper.mygallery .swiper-pagination {
    top: -25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper.mygallery .swiper-pagination {
    top: -13%;
  }
}

@media only screen and (max-width: 767px) {
  .swiper.mygallery .swiper-pagination {
    top: -13.5%;
    right: 71px;
  }
}

@media only screen and (max-width: 575px) {
  .swiper.mygallery .swiper-pagination {
    top: -6.5%;
  }
}

.swiper.mygallery .swiper-pagination .swiper-pagination-current {
  color: var(--color-primary);
  font-weight: 700;
}

.swiper.mygallery .swiper-pagination .swiper-pagination-total {
  color: #1C2539;
  font-weight: 700;
}

.swiper.mygallery .swiper-pagination.six .swiper-pagination-current {
  color: var(--color-primary-6);
}

.rts-title-area.six p.pre-title {
  color: var(--color-primary-6);
}

.tab-button-area-one .nav {
  justify-content: center;
  border-bottom: none;
  margin-left: -20px;
  margin-top: 0;
}

.tab-button-area-one .nav li {
  margin-left: 20px;
  margin-top: 0;
}

.tab-button-area-one .nav li button {
  background: #FFFFFF;
  color: #1C2539;
  padding: 12px 28px;
  border: 1px solid #EDEFF3;
  box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
  border-radius: 15px;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
}

@media only screen and (max-width: 479px) {
  .tab-button-area-one .nav li button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

.tab-button-area-one .nav li button.active {
  background: var(--color-primary);
  color: #fff;
}

.rts-product-one {
  padding: 35px 35px 33px 35px;
  border: 1px solid #EDEFF3;
  border-radius: 15px;
}

@media only screen and (max-width: 479px) {
  .rts-product-one {
    padding: 15px;
  }
}

.rts-product-one .product-contact-wrapper {
  padding: 30px 0 0 0;
}

.rts-product-one .product-contact-wrapper span {
  margin-bottom: 15px;
}

.rts-product-one .product-contact-wrapper .title {
  margin-bottom: 8px;
  transition: 0.3s;
}
.rts-product-one:hover .product-contact-wrapper a .title {
  color: var(--color-primary);
}

.big-bg-porduct-details {
  position: relative;
}

.big-bg-porduct-details img {
  width: 100%;
  border-radius: 16px;
}

.big-bg-porduct-details .project-info {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  width: 386px;
  box-shadow: 0px 10px 21px rgba(27, 18, 18, 0.05);
  border-radius: 15px 0 15px 0;
}

@media only screen and (max-width: 1199px) {
  .big-bg-porduct-details .project-info {
    bottom: -66px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .big-bg-porduct-details .project-info {
    position: relative;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 30px;
    margin-top: -33px;
  }
}

@media only screen and (max-width: 767px) {
  .big-bg-porduct-details .project-info {
    position: relative;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 30px;
    margin-top: -33px;
  }
}

.big-bg-porduct-details .project-info .info-head {
  padding: 24px 40px;
  background: var(--color-primary);
  border-radius: 15px 0 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .big-bg-porduct-details .project-info .info-head {
    border-radius: 15px 15px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .big-bg-porduct-details .project-info .info-head {
    border-radius: 15px 15px 0 0;
  }
}

@media only screen and (max-width: 479px) {
  .big-bg-porduct-details .project-info .info-head {
    padding: 12px 14px;
  }
}

.big-bg-porduct-details .project-info .info-head .title {
  color: #fff;
  margin-bottom: 0;
}

@media only screen and (max-width: 479px) {
  .big-bg-porduct-details .project-info .info-head .title {
    font-size: 18px;
  }
}

.big-bg-porduct-details .project-info .info-body {
  padding: 15px 40px;
}

@media only screen and (max-width: 479px) {
  .big-bg-porduct-details .project-info .info-body {
    padding: 15px 10px;
  }
}

.big-bg-porduct-details .project-info .info-body .single-info {
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid #E9E9E9;
}

@media only screen and (max-width: 479px) {
  .big-bg-porduct-details .project-info .info-body .single-info {
    padding: 15px 0;
  }
}

.big-bg-porduct-details .project-info .info-body .single-info:last-child {
  border-bottom: none;
}

.big-bg-porduct-details .project-info .info-body .single-info .info-ico i {
  height: 50px;
  width: 50px;
  background: var(--color-primary-alta);
  color: var(--color-primary);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.big-bg-porduct-details .project-info .info-body .single-info .info-details {
  margin-left: 20px;
}

.big-bg-porduct-details .project-info .info-body .single-info .info-details span {
  margin-bottom: 5px;
}

.big-bg-porduct-details .project-info .info-body .single-info .info-details .name {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 3px;
}

@media only screen and (max-width: 479px) {
  .product-details-main-inner {
    padding: 0 10px;
  }
}

.product-details-main-inner span {
  color: var(--color-primary);
  font-weight: 500;
  text-transform: uppercase;
}

.product-details-main-inner .title {
  margin-bottom: 20px;
}

.product-details-main-inner p.disc {
  font-size: 16px;
  margin-bottom: 25px;
}

.product-details-main-inner p.italic {
  font-size: 20px;
  font-style: italic;
  color: #1C2539;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 30px;
}

.single-project-details-challenge {
  margin-bottom: 20px;
  display: flex;
}

.single-project-details-challenge .details {
  color: #1C2539;
  line-height: 26px;
  font-weight: 400;
  font-size: 16px;
}

.single-project-details-challenge:last-child {
  margin-bottom: 0;
}

.single-project-details-challenge .icon {
  margin-right: 15px;
}

.single-project-details-challenge .icon i {
  width: max-content;
  border-radius: 50%;
  color: var(--color-primary);
  font-size: 19px;
  margin-top: 4px;
}

.business-case-bg {
  /* background-image: url(../images/business-case/bg-01.jpg); */
  background-position: top center;
  background-repeat: no-repeat;
}

.title-area-business-case-3 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .title-area-business-case-3 {
    flex-direction: column;
    align-items: flex-start;
  }
}

.title-area-business-case-3 a {
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .title-area-business-case-3 a {
    margin-left: 0;
  }
}

.title-area-business-case-3 .title-area .title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 64px;
  color: #1C2539;
}

.title-area-business-case-3 .title-area span {
  color: var(--color-primary-3);
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: 700;
}

.rts-cse-study-3-wrapper {
  width: 100%;
}

.rts-cse-study-3-wrapper .thumbnail {
  position: relative;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}

.rts-cse-study-3-wrapper .thumbnail img {
  width: 100%;
}

.rts-cse-study-3-wrapper .thumbnail img {
  border-radius: 10px;
  filter: grayscale(0);
}

.rts-cse-study-3-wrapper .thumbnail .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-3);
  background: #fff;
  z-index: 5;
  border-radius: 50%;
  transition: 0.3s;
}

.rts-cse-study-3-wrapper .thumbnail .content {
  position: absolute;
  z-index: 10;
  bottom: 40px;
  background: #fff;
  border-radius: 5px;
  padding: 30px 40px;
  left: 50%;
  transform: translateX(-50%) translateY(134%);
  width: 85%;
  overflow: hidden;
  transition: 0.8s;
}

.rts-cse-study-3-wrapper .thumbnail .content .title {
  margin-bottom: 5px;
  transition: 0.3s;
}

.rts-cse-study-3-wrapper .thumbnail .content .title:hover {
  color: var(--color-primary-3);
}

.rts-cse-study-3-wrapper .thumbnail .content span {
  font-size: 16px;
  font-weight: 400;
  color: #5D666F;
}

.rts-cse-study-3-wrapper .thumbnail .content .content-wrap {
  position: relative;
}

.rts-cse-study-3-wrapper .thumbnail .content .content-wrap::after {
  position: absolute;
  content: "";
  left: -15%;
  bottom: -83%;
  /* background-image: url(../images/business-case/icon/01.png); */
  height: 58px;
  width: 58px;
}

.rts-cse-study-3-wrapper .thumbnail:hover img {
  filter: grayscale(1);
}

.rts-cse-study-3-wrapper .thumbnail:hover::after {
  transform: translate(-50%, -50%) scale(1);
  border-radius: 10px;
}

.rts-cse-study-3-wrapper .thumbnail:hover .icon {
  transform: translate(-50%, -50%) scale(1);
}

.rts-cse-study-3-wrapper .thumbnail:hover .content {
  transform: translateX(-50%) translateY(0%);
}

.bg-project-three {
  /* background-image: url(../images/product/bg/01.jpg); */
  background-repeat: no-repeat;
}

.title-area-project-w-in .sub {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.3em;
  color: var(--color-primary-3);
  text-transform: uppercase;
}

.title-area-project-w-in .title {
  font-size: 60px;
  color: #fff;
  line-height: 65px;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .title-area-project-w-in .title {
    font-size: 26px;
    line-height: 40px;
  }

  .title-area-project-w-in .title br {
    display: none;
  }
}

.title-area-project-w-in .title span {
  color: var(--color-primary-3);
}

.title-area-project-w-in p.disc {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;
}

.title-area-project-w-in .bg-email {
  /* background-image: url(../images/product/bg/sm-1.jpg); */
  background-size: cover;
  margin-right: -2px;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title-area-project-w-in .bg-email {
    background-repeat: no-repeat;
  }
}

.title-area-project-w-in .bg-email .content-wrapper {
  padding: 46.5px 47.5px 45.5px 50px;
}

@media only screen and (max-width: 575px) {
  .title-area-project-w-in .bg-email .content-wrapper {
    padding: 20px;
  }
}

.title-area-project-w-in .bg-email .contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.title-area-project-w-in .bg-email .contact-info:last-child {
  margin-bottom: 0;
}

.title-area-project-w-in .bg-email .contact-info .discription {
  margin-left: 23px;
}

@media only screen and (max-width: 575px) {
  .title-area-project-w-in .bg-email .contact-info .discription {
    margin-left: 15px;
  }
}

.title-area-project-w-in .bg-email .contact-info .discription span {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.title-area-project-w-in .bg-email .contact-info .discription .title-sm {
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 700;
}

@media only screen and (max-width: 575px) {
  .title-area-project-w-in .bg-email .contact-info .discription .title-sm {
    font-size: 20px;
  }
}

.bg-input-project {
  /* background-image: url(../images/product/bg/02.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: -192px;
  margin-right: -120px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bg-input-project {
    margin-left: -114px;
  }
}

@media only screen and (max-width: 1199px) {
  .bg-input-project {
    margin-left: 0;
    margin-right: -120px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-input-project {
    margin-left: 0;
    margin-right: -2px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-input-project {
    margin-right: -2px;
    margin-left: 0px;
  }
}

.bg-input-project .product-form {
  padding: 120px;
  margin-top: -230px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bg-input-project .product-form {
    padding: 120px 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .bg-input-project .product-form {
    padding: 50px;
    margin-top: 21px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-input-project .product-form {
    margin-top: 40px;
    padding: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-input-project .product-form {
    padding: 25px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .bg-input-project .product-form {
    padding: 20px;
  }
}

.bg-input-project .product-form input {
  background: #ffff;
  border-radius: 15px;
  height: 55px;
  border: 1px solid transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-input-project .product-form input {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-input-project .product-form input {
    border-radius: 5px;
  }
}

.bg-input-project .product-form input:focus {
  border: 1px solid var(--color-primary-3);
}

.bg-input-project .product-form textarea {
  background: #ffff;
  border-radius: 15px;
  height: 160px;
  padding: 15px;
  border: 1px solid transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-input-project .product-form textarea {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .bg-input-project .product-form textarea {
    border-radius: 5px;
  }
}

.bg-input-project .product-form textarea:focus {
  border: 1px solid var(--color-primary-3);
}

.bg-input-project .product-form a.rts-btn {
  display: block;
  max-width: max-content;
}

.margin-controlerproject {
  margin-top: 110px;
}

@media only screen and (max-width: 1199px) {
  .margin-controlerproject {
    margin-top: 0;
  }
}

.portfolio-area.style-3 .portfolio-wrapper2 {
  position: relative;
  z-index: 1;
  transition: all 0.4s;
  margin-bottom: 40px;
  margin-right: 15px;
}

.portfolio-area.style-3 .portfolio-wrapper2:hover .img-fluid::before {
  height: 100%;
  bottom: 0;
}

.portfolio-area.style-3 .portfolio-wrapper2:hover .img-fluid .portfolio-content {
  transform: translate(-50%, -50%) scale(1);
}

.portfolio-area.style-3 .portfolio-wrapper2::before {
  pointer-events: none;
}

.portfolio-area.style-3 .portfolio-wrapper2:hover .portfolio-content a {
  transform: scale(1.5);
}

.portfolio-area.style-3 .portfolio-wrapper2:hover .portfolio-text .p-title a {
  text-decoration: underline !important;
}

.portfolio-area.style-3 .portfolio-wrapper2::before {
  background: #0000007A;
}

.portfolio-area.style-3 .portfolio-wrapper2 .img-fluid {
  position: relative;
  transition: all 0.3s;
}

.portfolio-area.style-3 .portfolio-wrapper2 .img-fluid::before {
  content: "";
  position: absolute;
  background-color: var(--color-primary);
  opacity: 0.8;
  width: 100%;
  height: 0;
  top: 0;
  border-radius: 20px;
  transition: all 0.4s;
}

.portfolio-area.style-3 .portfolio-wrapper2 .img-fluid img {
  width: 100%;
}

.portfolio-area.style-3 .portfolio-wrapper2 .img-fluid .portfolio-content {
  position: absolute;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  padding: 15px 30px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  display: inline-block;
  z-index: 2;
  left: 50%;
  top: 50%;
  transition: all 0.4s;
  transform: translate(-50%, -50%) scale(0);
}

.portfolio-area.style-3 .portfolio-wrapper2 .img-fluid .portfolio-content a {
  font-size: 14px;
  left: 39%;
  bottom: 55%;
  padding: 2px 15px;
  transition: all 0.4s ease-out 0s;
}

.portfolio-area.style-3 .portfolio-wrapper2 .portfolio-text {
  padding: 18px 0 0;
}

.portfolio-area.style-3 .portfolio-wrapper2 .portfolio-text .text .p-category {
  margin-bottom: 10px;
}

.portfolio-area.style-3 .portfolio-wrapper2 .portfolio-text .text .p-category a {
  color: #040404;
  font-size: 14px;
}

.portfolio-area.style-3 .portfolio-wrapper2 .portfolio-text .text .p-title {
  color: #040404;
  font-size: 22px;
  font-weight: 700;
  transition: all 0.4s;
}

@media (max-width: 767px) {
  .portfolio-area.style-3 .portfolio-wrapper2 .portfolio-text .text .p-title {
    font-size: 20px;
  }
}

.portfolio-area.style-3 .portfolio-wrapper2 .portfolio-text .text .p-title:hover {
  color: #ff4969 !important;
}

.portfolio-area.style-4 .item {
  position: relative;
  margin: 0 10px 30px 0;
  transition: all 0.4s;
}

@media (max-width: 768px) {
  .portfolio-area.style-4 .item {
    margin: 0 0 30px 0;
  }
}

.portfolio-area.style-4 .item:hover::before {
  height: 100%;
}

.portfolio-area.style-4 .item:hover .portfolio-wrapper .single-portfolio {
  transform: scale(1);
}

.portfolio-area.style-4 .item:hover .portfolio-wrapper .pf-btn {
  transform: scale(1);
}

.portfolio-area.style-4 .item::before {
  content: "";
  position: absolute;
  background: var(--color-primary);
  opacity: 0.9;
  width: 100%;
  height: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  transition: all 0.3s ease 0s;
  clip-path: polygon(0% -65%, 0% 100%, 99% 100%);
  border-radius: 20px;
}

.portfolio-area.style-4 .item .img-fluid img {
  width: 100%;
}

.portfolio-area.style-4 .item .portfolio-wrapper {
  position: relative;
}

.portfolio-area.style-4 .item .portfolio-wrapper .single-portfolio {
  position: absolute;
  bottom: 10%;
  left: 8%;
  transform: scale(0);
  transition: all 0.4s;
  z-index: 1;
}

.portfolio-area.style-4 .item .portfolio-wrapper .single-portfolio span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.portfolio-area.style-4 .item .portfolio-wrapper .single-portfolio .portfolio-title {
  font-size: 22px;
  font-weight: 700;
}

.portfolio-area.style-4 .item .portfolio-wrapper .pf-btn {
  background-color: #fff;
  color: #000;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  border-radius: 50%;
  position: absolute;
  bottom: 12%;
  right: 7%;
  transform: scale(0);
  transition: all 0.4s;
  z-index: 1;
}

.portfolio-area.style-5 .item {
  position: relative;
  margin-bottom: 20px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .portfolio-area.style-5 .item {
    margin-right: 0;
  }
}

.portfolio-area.style-5 .item:hover::before {
  background: #1c2539;
  opacity: 0.902;
  clip-path: polygon(0 54.6%, 0% 100%, 45.5% 100%);
}

.portfolio-area.style-5 .item:hover::after {
  visibility: visible;
  height: 100%;
}

.portfolio-area.style-5 .item:hover .portfolio-wrapper .single-portfolio {
  top: 8%;
  bottom: unset;
}

.portfolio-area.style-5 .item:hover .portfolio-wrapper .pf-btn {
  transform: scale(1);
}

.portfolio-area.style-5 .item::before {
  content: "";
  position: absolute;
  background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.70196) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 100%;
  transition: all 0.4s ease 0s;
  left: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 20px;
}

.portfolio-area.style-5 .item::after {
  content: "";
  position: absolute;
  background-color: #0d6efd;
  opacity: 0.902;
  clip-path: polygon(100% 0, 100% 100%, 45.5% 100%, 0% 55%, 0 0);
  width: 100%;
  height: 0;
  transition: all 0.4s ease 0s;
  left: 0;
  top: 0;
  border-radius: 20px;
  z-index: 1;
}

.portfolio-area.style-5 .item .portfolio-wrapper .img-fluid img {
  width: 100%;
}

.portfolio-area.style-5 .item .portfolio-wrapper .single-portfolio {
  position: absolute;
  bottom: 10%;
  left: 15%;
  transform: scale(1);
  transition: all 0.4s;
  z-index: 9;
}

.portfolio-area.style-5 .item .portfolio-wrapper .single-portfolio span {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.portfolio-area.style-5 .item .portfolio-wrapper .single-portfolio .portfolio-title {
  font-size: 22px;
  font-weight: 700;
}

.portfolio-area.style-5 .item .portfolio-wrapper .pf-btn {
  background-color: #fff;
  color: #000;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  border-radius: 50%;
  position: absolute;
  bottom: 16%;
  left: 15%;
  transform: scale(0);
  transition: all 0.4s;
  z-index: 9;
}

.rts-project-area.eight .title-area-project-w-in {
  padding: 150px 0;
}

@media (max-width: 991px) {
  .rts-project-area.eight .title-area-project-w-in {
    padding: 150px 0 0 0;
  }
}

.rts-project-area.eight .title-area-project-w-in .sub {
  color: var(--color-primary-2);
}

.rts-project-area.eight .title-area-project-w-in .title span {
  color: var(--color-primary-2);
}

.rts-project-area.eight .bg-input-project .product-form {
  margin-top: 14px;
  padding: 110px 100px 50px;
}

@media only screen and (max-width: 767px) {
  .rts-project-area.eight .bg-input-project .product-form {
    padding: 25px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .rts-project-area.eight .bg-input-project .product-form {
    padding: 20px;
  }
}

.rts-project-area.eight .bg-input-project .product-form form .rts-btn {
  background: var(--color-primary-2);
}

.rts-project-area.eight .bg-input-project .product-form form input:focus {
  border: 1px solid var(--color-primary-2);
}

.rts-project-area.eight .bg-input-project .product-form form textarea:focus {
  border: 1px solid var(--color-primary-2);
}

.title-area-client {
  position: relative;
}

.title-area-client p.client-title {
  max-width: max-content;
  margin: auto;
  font-weight: 700;
  color: #1C2539;
  text-transform: uppercase;
}

.title-area-client.six::after {
  background: var(--color-primary-6);
}

.title-area-client.six::before {
  background: var(--color-primary-6);
}

.title-area-client::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  width: 38%;
  background: var(--color-primary);
  display: flex;
  align-items: center;
  margin-top: -14px;
}

@media only screen and (max-width: 1199px) {
  .title-area-client::after {
    width: 38%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title-area-client::after {
    width: 28%;
  }
}

@media only screen and (max-width: 767px) {
  .title-area-client::after {
    width: 30%;
  }
}

@media only screen and (max-width: 575px) {
  .title-area-client::after {
    display: none;
  }
}

.title-area-client::before {
  content: "";
  position: absolute;
  left: 62%;
  width: 100%;
  height: 2px;
  width: 36%;
  background: var(--color-primary);
  display: flex;
  align-items: center;
  margin-top: 15px;
}

@media only screen and (max-width: 1199px) {
  .title-area-client::before {
    left: 62%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .title-area-client::before {
    left: 72%;
    width: 28%;
  }
}

@media only screen and (max-width: 767px) {
  .title-area-client::before {
    left: 69%;
    width: 30%;
  }
}

@media only screen and (max-width: 575px) {
  .title-area-client::before {
    display: none;
  }
}

.client-wrapper-one {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .client-wrapper-one {
    justify-content: center;
  }
}

@media only screen and (max-width: 1199px) {
  .client-wrapper-one {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .client-wrapper-one {
    position: relative;
    z-index: 50;
  }
}

.client-wrapper-one a img {
  box-shadow: 0px 10px 30px rgba(33, 5, 5, 0.05);
  border-radius: 15px;
  transition: var(--transition);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .client-wrapper-one a img {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .client-wrapper-one a img {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.client-wrapper-one a:hover img {
  transform: scale(1.1) translateY(-5px);
}

.client-bg {
  /* background-image: url(../images/client/bg.jpg); */
}

.client-bg .client-two-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1199px) {
  .client-bg .client-two-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.client-bg .client-two-wrapper img {
  cursor: pointer;
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .client-bg .client-two-wrapper img {
    max-width: 15%;
  }
}

@media only screen and (max-width: 1199px) {
  .client-bg .client-two-wrapper img {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .client-bg .client-two-wrapper img {
    width: 50%;
  }
}

.client-bg .client-two-wrapper img:hover {
  transform: scale(1.15);
}

.rts-trusted-client2 .container .title-area-client::before {
  background: var(--color-primary-4);
}

.rts-trusted-client2 .container .title-area-client::after {
  background: var(--color-primary-4);
}

.bg-team {
  /* background-image: url(../images/team/bg-01.jpg); */
  height: 1029px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bg-team {
    height: 978px;
  }
}

@media only screen and (max-width: 1499px) {
  .bg-team {
    height: 931px;
  }
}

@media only screen and (max-width: 1399px) {
  .bg-team {
    height: 879px;
  }
}

@media only screen and (max-width: 1199px) {
  .bg-team {
    height: auto;
  }
}

.bg-team-color {
  background: #F6F6F6;
}

.bg-white-feature {
  background: #fff;
  box-shadow: 0px 22px 23px rgba(0, 0, 0, 0.07);
}

.team-single-one-start {
  background: #fff;
  border-radius: 8px;
  transition: 0.3s;
}

.team-single-one-start:hover {
  transform: translateY(-20px);
}

.team-single-one-start .team-image-area a {
  overflow: hidden;
  display: block;
  position: relative;
  border-radius: 8px 8px 0 0;
}

.team-single-one-start .team-image-area a:hover img {
  transform: scale(1.1);
}

.team-single-one-start .team-image-area a img {
  width: 100%;
  transition: 0.3s;
}

.team-single-one-start .team-image-area a .team-social {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.team-single-one-start .team-image-area a .team-social .main i {
  padding: 16px;
  background: #fff;
  border-radius: 50%;
  font-size: 16px;
  line-height: 12px;
  font-weight: 500;
  color: var(--color-primary);
  transition: 0.3s;
}

.team-single-one-start .team-image-area a .team-social:hover .main i {
  background: var(--color-primary);
  color: #fff;
}

.team-single-one-start .team-image-area a .team-social .team-social-one {
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateY(50px);
  transition: 1s;
  opacity: 0;
}

.team-single-one-start .team-image-area a .team-social .team-social-one i {
  background: #fff;
  border-radius: 60%;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: var(--color-primary);
  transition: 0.3s;
  margin-bottom: 10px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-single-one-start .team-image-area a .team-social .team-social-one i:hover {
  background: var(--color-primary);
  color: #fff;
}

.team-single-one-start .team-image-area a .team-social:hover .main {
  opacity: 0;
}

.team-single-one-start .team-image-area a .team-social:hover .team-social-one {
  transform: translateY(-96%);
  z-index: 2;
  opacity: 1;
}

.team-single-one-start .single-details {
  padding: 28px 30px 27px 30px;
}

.team-single-one-start .single-details a .title {
  margin-bottom: 0px;
  transition: 0.3s;
}

.team-single-one-start .single-details a:hover .title {
  color: var(--color-primary);
}

.team-single-one-start .single-details p {
  color: var(--color-primary);
  font-size: 16px;
}

.team-inner-two {
  position: relative;
  width: 100%;
}

.team-inner-two.inner {
  margin-bottom: 100px;
}

.team-inner-two .thumbnail {
  overflow: hidden;
  display: block;
  border-radius: 15px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, .2);
}

.team-inner-two .thumbnail img {
  width: 100%;
  /* transition: 0.3s; */
}

.team-inner-two .thumbnail:hover img {
  /* transform: scale(1.15); */
}

.team-inner-two .acquaintance-area {
  position: absolute;
  left: 0;
  bottom: -20%;
  background: #fff;
  border-radius: 0 15px 15px 15px;
  box-shadow: 0px 4px 27px #00000012;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team-inner-two .acquaintance-area {
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .team-inner-two .acquaintance-area {
    bottom: 0;
  }
}

.team-inner-two .acquaintance-area .header {
  padding: 30px 82px 22px 82px;
  border-bottom: 1px solid #F0F0F0;
}

@media only screen and (max-width: 1199px) {
  .team-inner-two .acquaintance-area .header {
    padding: 20px 62px;
  }
}

.team-inner-two .acquaintance-area .header .title {
  margin-bottom: 5px;
}

@media only screen and (max-width: 1199px) {
  .team-inner-two .acquaintance-area .header .title {
    font-size: 20px;
  }
}

.team-inner-two .acquaintance-area .header span {
  color: var(--color-primary);
  font-weight: 400;
  font-size: 16px;
}

.team-inner-two .acquaintance-area .acquaintance-social {
  padding: 27px 84px 36px 84px;
  margin-right: -30px;
}

@media only screen and (max-width: 1199px) {
  .team-inner-two .acquaintance-area .acquaintance-social {
    padding: 20px 62px;
    margin-right: -30px;
  }
}

.team-inner-two .acquaintance-area .acquaintance-social a {
  position: relative;
  z-index: 1;
  transition: 0.3s;
  margin-right: 30px;
}

.team-inner-two .acquaintance-area .acquaintance-social a i {
  font-size: 14px;
  color: #1C2539;
  transition: 0.3s;
}

.team-inner-two .acquaintance-area .acquaintance-social a::after {
  position: absolute;
  content: "";
  background: #F6F6F6;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: 0.3s;
}

.team-inner-two .acquaintance-area .acquaintance-social a:hover i {
  color: #fff;
}

.team-inner-two .acquaintance-area .acquaintance-social a:hover::after {
  background: var(--color-primary);
}

.details-thumb img {
  width: 100%;
}

.rts-team-area4 {
  background: #fff;
  height: auto;
}

@media (max-width: 767px) {
  .rts-team-area4 {
    padding: 100px 0;
  }
}

.rts-team-area4 .container .rts-title-area .pre-title {
  color: var(--color-primary-4);
}

.rts-team-area4 .container .rts-title-area .title {
  color: #0B101C;
  margin-bottom: 0;
}

.rts-team-area4 .container .rts-title-area::after {
  display: none;
}

.rts-team-area4 .container .mySwiperh1_team {
  padding-bottom: 20px;
}

.rts-team-area4 .container .mySwiperh1_team .team-single-one-start {
  box-shadow: 0px 2px 20px rgba(24, 16, 16, 0.09);
}

.rts-team-area4 .container .mySwiperh1_team .team-single-one-start .team-image-area .team-social:hover .main i {
  background: var(--color-primary-4);
  color: #fff;
}

.rts-team-area4 .container .mySwiperh1_team .team-single-one-start .team-image-area .team-social .main i {
  color: var(--color-primary-4);
}

.rts-team-area4 .container .mySwiperh1_team .team-single-one-start .team-image-area .team-social .team-social-one i {
  color: var(--color-primary-4);
}

.rts-team-area4 .container .mySwiperh1_team .team-single-one-start .team-image-area .team-social .team-social-one i:hover {
  background: var(--color-primary-4);
  color: #fff;
}

.rts-team-area4 .container .mySwiperh1_team .team-single-one-start .single-details a:hover .title {
  color: var(--color-primary-4);
}

.rts-team-area4 .container .mySwiperh1_team .team-single-one-start .single-details p {
  color: var(--color-primary-4);
}

.rts-team-area.style-3 {
  background: #f6f6f6;
  padding-top: 70px;
}

.rts-team-area.style-3 .team-inner-two {
  background: #fff;
  margin-bottom: 15px;
}

.rts-team-area.style-3 .team-inner-two:hover .thumbnail .social {
  right: 0;
}

.rts-team-area.style-3 .team-inner-two .thumbnail {
  border-radius: 0;
  position: relative;
  overflow: hidden;
}

.rts-team-area.style-3 .team-inner-two .thumbnail img {
  width: 100%;
}

.rts-team-area.style-3 .team-inner-two .thumbnail .social {
  position: absolute;
  right: -50px;
  bottom: 0;
  display: inline-grid;
  background: var(--color-primary);
  padding: 12px 0;
  width: 50px;
  text-align: center;
  color: #fff;
  transition: all 0.4s;
}

.rts-team-area.style-3 .team-inner-two .thumbnail .social a {
  margin: 5px 0;
  transition: all 0.4s;
}

.rts-team-area.style-3 .team-inner-two .thumbnail .social a:hover {
  color: #0B101C;
}

.rts-team-area.style-3 .team-inner-two .inner-content {
  padding: 30px;
  text-align: center;
}

.rts-team-area.style-3 .team-inner-two .inner-content .header .title {
  margin-bottom: 5px;
}

.rts-team-area.style-3 .team-inner-two .inner-content .header span {
  color: var(--color-primary);
}

.rts-team-area.style-4 .team-inner-two {
  position: relative;
  width: 100%;
  transition: all 0.6s;
}

.rts-team-area.style-4 .team-inner-two.inner {
  margin-bottom: 100px;
}

.rts-team-area.style-4 .team-inner-two:hover .acquaintance-area .team-desc {
  max-height: 400px;
  transform: scaleY(1);
  transition: all 0.4s, max-height 1.5s;
  padding: 15px 0;
}

.rts-team-area.style-4 .team-inner-two .thumbnail {
  overflow: hidden;
  display: block;
  border-radius: 15px;
}

.rts-team-area.style-4 .team-inner-two .thumbnail img {
  width: 100%;
  transition: 0.3s;
}

.rts-team-area.style-4 .team-inner-two .thumbnail:hover img {
  transform: scale(1.15);
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area {
  position: absolute;
  right: 50%;
  left: unset;
  width: 85%;
  padding: 20px;
  transform: translateX(50%);
  bottom: -20%;
  background: #fff;
  border-radius: 15px;
  transition: all 0.6s;
  box-shadow: 0px 4px 27px #00000012;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-team-area.style-4 .team-inner-two .acquaintance-area {
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rts-team-area.style-4 .team-inner-two .acquaintance-area {
    bottom: 0;
  }
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .header {
  padding: 0 0 5px 0;
  border: 0;
}

@media only screen and (max-width: 1199px) {
  .rts-team-area.style-4 .team-inner-two .acquaintance-area .header {
    padding: 20px 62px;
  }
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .header .title {
  font-size: 22px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1199px) {
  .rts-team-area.style-4 .team-inner-two .acquaintance-area .header .title {
    font-size: 20px;
  }
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .header span {
  color: var(--color-primary);
  font-weight: 400;
  font-size: 16px;
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .team-desc {
  font-size: 14px;
  margin-bottom: 0;
  color: #777;
  position: relative;
  padding: 5px 0;
  z-index: 1;
  max-height: 0;
  opacity: 1;
  line-height: 24px;
  overflow: hidden;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: all 0.8s, max-height 0.8s;
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .team-desc::before {
  content: "";
  position: absolute;
  background: var(--color-primary);
  width: 50px;
  height: 2px;
  right: 50%;
  top: 3px;
  transform: translateX(50%);
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .acquaintance-social {
  padding: 5px 84px 5px 84px;
  margin-right: -30px;
}

@media only screen and (max-width: 1199px) {
  .rts-team-area.style-4 .team-inner-two .acquaintance-area .acquaintance-social {
    padding: 20px 62px;
    margin-right: -30px;
  }
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .acquaintance-social a {
  position: relative;
  z-index: 1;
  transition: 0.3s;
  margin-right: 30px;
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .acquaintance-social a i {
  font-size: 14px;
  color: var(--color-primary);
  transition: 0.3s;
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .acquaintance-social a::after {
  position: absolute;
  content: "";
  background: #F6F6F6;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: 0.3s;
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .acquaintance-social a:hover i {
  color: #fff;
}

.rts-team-area.style-4 .team-inner-two .acquaintance-area .acquaintance-social a:hover::after {
  background: var(--color-primary);
}

.rts-team-area.style-5 .team-inner-two {
  margin-bottom: 30px;
}

.rts-team-area.style-5 .team-inner-two .thumbnail {
  border-radius: 50%;
}

.rts-team-area.style-5 .team-inner-two .team-content {
  text-align: center;
  margin-top: 30px;
}

.rts-team-area.style-5 .team-inner-two .team-content .header .title {
  margin-bottom: 5px;
}

.rts-team-area.style-5 .team-inner-two .team-content .header span {
  color: var(--color-primary);
}

.rts-team-area.style-5 .team-inner-two .team-content .acquaintance-social {
  margin-top: 10px;
}

.rts-team-area.style-5 .team-inner-two .team-content .acquaintance-social a {
  margin-right: 5px;
  background: #fff;
  color: #4a4a4a;
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  transition: all 0.3s;
}

.rts-team-area.style-5 .team-inner-two .team-content .acquaintance-social a:hover {
  background: var(--color-primary);
  color: #fff;
}

.color-white {
  color: #ffff !important;
}

.rts-team-area-style-six {
  position: relative;
  z-index: 1;
  height: 1159px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rts-team-area-style-six {
    height: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .rts-team-area-style-six {
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-team-area-style-six {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .rts-team-area-style-six {
    height: auto;
  }
}

.rts-team-area-style-six .team-bg-image-style-six {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}

.single-team-style-six .inner .thumbnail {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.single-team-style-six .inner .thumbnail a img {
  width: 100%;
  height: 100%;
  transition: 0.3s;
  border-radius: 10px;
}

.single-team-style-six .inner .thumbnail .content-inner {
  width: 333px;
  height: 333px;
  background: #B00F0F;
  border-radius: 50%;
  position: absolute;
  bottom: -47%;
  right: -16%;
}

@media only screen and (max-width: 767px) {
  .single-team-style-six .inner .thumbnail .content-inner {
    bottom: -61%;
    right: -32%;
    width: 100%;
    bottom: 0;
    right: 0;
    height: auto;
    border-radius: 0;
  }
}

.single-team-style-six .inner .thumbnail .content-inner .text {
  margin-left: 70px;
  margin-top: 70px;
}

@media only screen and (max-width: 767px) {
  .single-team-style-six .inner .thumbnail .content-inner .text {
    padding: 12px;
    margin: 0;
  }
}

.single-team-style-six .inner .thumbnail .content-inner .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.single-team-style-six .inner .thumbnail .content-inner .designation {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFDBCB;
}

.single-team-style-six .inner .thumbnail .social-team {
  position: absolute;
  top: -60px;
  left: 0;
  transition: 0.3s;
}

.single-team-style-six .inner .thumbnail .social-team ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.single-team-style-six .inner .thumbnail .social-team ul li {
  margin: 0 8px;
}

.single-team-style-six .inner .thumbnail .social-team ul li a i {
  width: 40px;
  height: 40px;
  color: #181A1C;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.single-team-style-six .inner .thumbnail .social-team ul li a:hover i {
  color: var(--color-primary-5);
}

.single-team-style-six .inner:hover .thumbnail .social-team {
  top: 10px;
}

.index-six p.pre-title {
  color: var(--color-primary-6);
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--font-secondary);
  letter-spacing: 0.1em;
  margin-bottom: 13px;
  text-transform: uppercase;
}

.rts-single-team-h7 {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: block;
  z-index: 1;
  border-radius: 12px;
}

.rts-single-team-h7::after {
  position: absolute;
  content: "";
  width: 333px;
  height: 333px;
  left: 10%;
  top: 60%;
  background: #F64A00;
  border-radius: 50%;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .rts-single-team-h7::after {
    left: 0%;
    top: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .rts-single-team-h7::after {
    left: auto;
    top: auto;
    right: -10%;
    bottom: -30%;
  }
}

.rts-single-team-h7 .thumbnail img {
  border-radius: 14px;
  position: relative;
  width: 100%;
}

.rts-single-team-h7 .content {
  position: absolute;
  z-index: 1;
  bottom: 30px;
  right: 30px;
}

.rts-single-team-h7 .content .title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 5px;
  color: #fff;
}

.rts-single-team-h7 .content span {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFDBCB;
}

.rts-single-team-h7 .social-area {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  z-index: 1;
  transition: 0.3s;
}

.rts-single-team-h7 .social-area ul {
  padding: 0;
  margin: 0;
  display: flex;
}

.rts-single-team-h7 .social-area ul li {
  margin-right: 10px;
}

.rts-single-team-h7 .social-area ul li:last-child {
  margin-right: 0;
}

.rts-single-team-h7 .social-area ul li a {
  width: 40px;
  height: 40px;
  display: block;
  background: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rts-single-team-h7 .social-area ul li a i {
  color: #1C2539;
}

.rts-single-team-h7 .social-area ul li a:hover i {
  color: var(--color-primary-5);
}

.rts-single-team-h7:hover .social-area {
  top: 20px;
}

.rts-single-team-h7.six .social-area ul li a:hover i {
  color: var(--color-primary-6);
}

.rts-feature-area {
  position: relative;
  /* margin-top: -17%; */
}

@media only screen and (max-width: 1199px) {
  .rts-feature-area {
    margin-top: 0;
  }
}

.feature-left-area {
  display: flex;
  justify-content: flex-end;
  padding-right: 83px;
  animation: jump-2 5s linear infinite;
}

@media only screen and (max-width: 1199px) {
  .feature-left-area img {
    width: 80%;
    margin-top: 50px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-left-area {
    justify-content: center;
    margin-top: -50px;
  }
}

.bg-white-feature {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-bottom: 5px solid var(--color-primary);
  padding: 0 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bg-white-feature {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media only screen and (max-width: 1199px) {
  .bg-white-feature {
    padding: 0 80px !important;
  }
}

@media only screen and (max-width: 767px) {
  .bg-white-feature {
    padding: 0 30px !important;
  }
}

@media only screen and (max-width: 575px) {
  .bg-white-feature {
    padding: 0 15px !important;
  }
}

.bg-white-feature::after {
  position: absolute;
  content: "";
  height: 763px;
  width: 763px;
  background-image: url(./pages/images/feature/shape/01.webp);
  left: -15%;
  top: -50%;
  z-index: -1;
  animation: rotateIt 20s linear infinite;
}

@media only screen and (max-width: 1199px) {
  .bg-white-feature::after {
    display: none;
  }
}

@media screen and (max-width: 1366px) {
  .padding-controler {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .feature-title {
    font-size: 30px;
  }
}

.feature-one-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1199px) {
  .feature-one-wrapper {
    padding-bottom: 50px;
  }
}

.feature-one-wrapper .button-group {
  margin-top: 40px;
}

.single-feature-one {
  display: flex;
  align-items: center;
  padding: 15px 28px;
  background: #fff;
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  max-width: auto;
  /* margin-right: 25px; */
  margin-bottom: 25px;
  /* cursor: pointer; */
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-feature-one {
    padding: 8px 12px;
    /* margin-right: 10px; */
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .single-feature-one {
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.single-feature-one:hover {
  box-shadow: 0px 9px 18px #1810100d;
}

.single-feature-one i {
  padding: 5px;
  background: #0ce0ff;
  border-radius: 50%;
  color: #fff;
  margin-right: 15px;
  font-size: 12px;
  line-height: 12px;
}

.single-feature-one p {
  color: #1C2539;
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-feature-one p {
    font-size: 13px;
  }
  .pl--30{
    padding-left: 0px !important; 
  }
}

.single-feature-one.eight {
  padding: 15px 22px;
  margin-right: 15px;
}

.single-feature-one.eight svg {
  width: 35px;
  height: 35px;
  fill: var(--color-primary-2);
  margin-right: 8px;
}

.single-feature-one.eight p {
  font-size: 20px;
}

.rts-test-one-image-inner {
  position: relative;
  z-index: 1;
}

.rts-test-one-image-inner::after {
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  left: 13%;
  bottom: -14%;
  /* background-image: url(../images/testimonials/shape/01.svg); */
  animation: jump-1 5s linear infinite;
}

.rts-test-one-image-inner::before {
  position: absolute;
  z-index: -1;
  content: "";
  height: 158px;
  width: 220px;
  background-repeat: no-repeat;
  top: 8%;
  left: -12%;
  background-image: url(./pages/images/testimonials/shape/02.png);
}

.testimonial-bottom-one {
  max-width: max-content;
  display: flex;
  align-items: center;
}

.testimonial-bottom-one .thumbnail {
  margin-right: 20px;
}

.testimonial-bottom-one .thumbnail img {
  max-width: 80px;
  height: 80px;
}

@media only screen and (max-width: 479px) {
  .testimonial-bottom-one .thumbnail img {
    max-width: 42px;
    height: 42px;
  }
}

.testimonial-bottom-one .details {
  text-align: left;
}

.testimonial-bottom-one .details a .title {
  margin-bottom: 0px;
  transition: 0.3s;
}

.testimonial-bottom-one .details a:hover .title {
  color: var(--color-primary);
}

.testimonial-bottom-one .details span {
  margin-left: 0;
  color: #5D666F;
}

.mySwipertestimonial .swiper-button-next {
  right: 30%;
}

@media only screen and (max-width: 479px) {
  .mySwipertestimonial .swiper-button-next {
    right: 4%;
  }
}

.mySwipertestimonial .swiper-button-next::after {
  content: "\f061";
  font-family: "FontAwesome";
  font-size: 16px;
  color: #1C2539;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 9px 18px #1810100d;
  border-radius: 8px;
  transition: 0.3s;
  line-height: 16px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.mySwipertestimonial .swiper-button-next:hover::after {
  background: var(--color-primary);
  color: #fff;
}

.mySwipertestimonial .swiper-button-prev {
  left: 57%;
}

@media only screen and (max-width: 479px) {
  .mySwipertestimonial .swiper-button-prev {
    left: 68%;
  }
}

.mySwipertestimonial .swiper-button-prev::after {
  content: "\f060";
  font-family: "FontAwesome";
  font-size: 16px;
  color: #1C2539;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 9px 18px #1810100d;
  border-radius: 8px;
  transition: 0.3s;
  line-height: 16px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.mySwipertestimonial .swiper-button-prev:hover::after {
  background: var(--color-primary);
  color: #fff;
}

.mySwipertestimonial .swiper-button-next,
.mySwipertestimonial .swiper-button-prev {
  top: 85%;
}

@media only screen and (max-width: 479px) {

  .mySwipertestimonial .swiper-button-next,
  .mySwipertestimonial .swiper-button-prev {
    top: 91%;
  }
}

.rts-client-feedback {
  position: relative;
}

.rts-client-feedback::after {
  position: absolute;
  content: "";
  left: 0;
  height: 200px;
  width: 200px;
  top: 3%;
  /* background-image: url(../images/testimonials/shape/03.png); */
  background-size: cover;
  animation: jump-2 5s linear infinite;
}

@media only screen and (max-width: 1199px) {
  .rts-client-feedback::after {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rts-client-feedback::after {
    display: none;
  }
}

.rts-client-feedback.inner::after {
  display: none;
}

@media only screen and (max-width: 575px) {
  .title-area.left-right.testimonial-h2 {
    flex-direction: column;
    align-items: flex-start;
  }
}

.bg-customer-feedback {
  background: #F6F6F6;
}

.testimopnial-wrapper-two {
  padding: 50px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 11px 30px rgba(0, 0, 0, 0.02);
}

@media only screen and (max-width: 575px) {
  .testimopnial-wrapper-two {
    padding: 20px;
  }
}
.testimopnial-wrapper-two .test-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.testimopnial-wrapper-two .test-header .thumbnail {
  position: relative;
}
.testimopnial-wrapper-two .test-header .thumbnail::after {
  position: absolute;
  right: -22%;
  content: "";
  /* background-image: url(../images/testimonials/icon/02.png); */
  top: 60%;
  transform: translateY(-50%);
  height: 35px;
  width: 35px;
}
.testimopnial-wrapper-two .test-header .thumbnail img {
  max-width: 100%;
}
.testimopnial-wrapper-two .test-header .name-desig {
  margin-left: 37px;
}
.testimopnial-wrapper-two .test-header .name-desig .title {
  margin-bottom: 0;
}
.testimopnial-wrapper-two .test-body p {
  color: #5D666F;
  font-size: 16px;
  height: 150px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-image-one img {
    width: 100%;
  }
}
.title-area.left-right {
  display: flex;
  align-items: center;
}
.title-area.left-right .title-left {
  margin-left: 0;
}
.title-area.left-right .title-left span {
  color: #fff !important;
}
.title-area.left-right .title-left .title {
  color: #fff !important;
  margin-top: -12px;
}
.title-area.left-right .title-left .title.inner {
  color: #1C2539 !important;
}

.title-area.left-right .title-left.inner span {
  color: var(--color-primary) !important;
}
.title-area.left-right .button-area {
  margin-left: auto;
}
@media only screen and (max-width: 575px) {
  .title-area.left-right .button-area {
    margin-left: 0;
    margin-top: 25px;
  }
}
.title-area.left-right .button-area.inner .btn-primary-2 {
  background: var(--color-primary);
}
.title-area.left-right .button-area.inner .btn-primary-2:hover {
  color: var(--color-primary);
  background: var(--color-primary-alta);
}
.title-area.inner {
  text-align: center;
  justify-content: center;
  position: relative;
}
.title-area.inner .title-left span {
  color: var(--color-primary) !important;
}
.title-area.inner .title-left .title {
  color: #1C2539 !important;
}
.title-area.inner::after {
  position: absolute;
  content: "01";
  left: 50%;
  top: -42%;
  transform: translateX(-50%);
  color: #ffffff;
  background-color: #000;
  background-size: 100% 100%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.5px transparent;
  font-size: 143px;
  height: 100%;
  max-width: max-content;
  max-height: max-content;
  font-weight: 900;
  z-index: -1;
  display: flex;
  align-items: center;
  opacity: 0.35;
  font-family: var(--font-secondary);
  z-index: -1;
}
.rts-client-reviews-h2 {
  padding: 50px 50px 30px 50px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #E9ECF1;
}
.rts-client-reviews-h2 .review-header {
  display: flex;
  align-items: center;
}
.rts-client-reviews-h2 .review-header .thumbnail {
  position: relative;
}
.rts-client-reviews-h2 .review-header .thumbnail::after {
  position: absolute;
  content: "";
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background-size: cover;
}
.rts-client-reviews-h2 .review-header .discription {
  margin-left: 30px;
}

.rts-client-reviews-h2 .review-header .discription a:hover .title {
  color: var(--color-primary-2);
}

.rts-client-reviews-h2 .review-header .discription .title {
  margin-bottom: 0;
  transition: 0.3s;
}

.rts-client-reviews-h2.six .review-header .discription a:hover .title {
  color: var(--color-primary-6);
}

.rts-client-reviews-h2 .review-body p.disc {
  font-size: 16px;
  line-height: 26px;
  color: #5D666F;
  margin-top: 35px;
  padding-bottom: 30px;
  border-bottom: 2px solid #E9ECF1;
  margin-bottom: 25px;
  text-align: left;
}

.rts-client-reviews-h2 .review-body .body-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rts-client-reviews-h2 .review-body .body-end .star-icon i {
  color: var(--color-primary-2);
}
@media only screen and (max-width: 1199px) {
  .service-detials-step-1 .thumbnail img {
    width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .pl-lg-controler {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .pl-lg-controler {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }
}
.rts-single-wized {
  background: #F6F6F6;
  border-radius: 15px;
  padding: 40px;
  margin-bottom: 40px;
}

.rts-single-wized:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 479px) {
  .rts-single-wized {
    padding: 20px;
  }
}

.rts-single-wized.service {
  border-radius: 0;
}

.rts-single-wized.service .single-categories li a {
  border-radius: 0;
}

.rts-single-wized.download {
  background: #1C2539;
}

.rts-single-wized.download .title {
  color: #fff;
}
.rts-single-wized.contact {
  background: #1C2539;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
}

@media only screen and (max-width: 479px) {
  .rts-single-wized.contact {
    padding: 25px 20px;
  }
}

@media only screen and (max-width: 479px) {
  .rts-single-wized.contact:last-child {
    margin-bottom: 0;
  }
}

.rts-single-wized.contact .wized-body {
  text-align: center;
}

.rts-single-wized.contact .wized-body .title {
  color: #fff;
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 32px;
}

.rts-single-wized.contact .wized-body a.rts-btn {
  display: block;
  max-width: max-content;
  margin: auto;
}

.rts-single-wized .wized-header .title {
  margin-bottom: 10px;
}

.rts-single-wized .wized-body {
  margin-top: 30px;
}

.rts-single-wized .single-categories {
  margin-bottom: 15px;
  padding: 0;
}

.rts-single-wized .single-categories:last-child {
  margin-bottom: 0;
}

.rts-single-wized .single-categories li {
  list-style: none;
}

.rts-single-wized .single-categories li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  background: #fff;
  color: #5D666F;
  font-weight: 500;
  transition: 0.3s;
  border-radius: 5px;
}

.rts-single-wized .single-categories li a i {
  color: var(--color-primary);
  transition: 0.3s;
}

.rts-single-wized .single-categories li a:hover {
  background: var(--color-primary);
  transform: translateY(-5px) scale(1.03);
  color: var(--color-white);
}

.rts-single-wized .single-categories li a:hover i {
  color: #fff;
}

.rts-single-wized .recent-post-single {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.rts-single-wized .recent-post-single:last-child {
  margin-bottom: 0;
}

.rts-single-wized .recent-post-single .thumbnail {
  margin-right: 20px;
  overflow: hidden;
  max-width: max-content;
  width: 100%;
  border-radius: 5px;
}

.rts-single-wized .recent-post-single .thumbnail img {
  min-width: 85px;
  height: auto;
  transition: 0.3s;
}

.rts-single-wized .recent-post-single .thumbnail:hover img {
  transform: scale(1.2);
}

.rts-single-wized .recent-post-single .user {
  display: flex;
  align-items: center;
}

.rts-single-wized .recent-post-single .user span {
  margin-left: 9px;
}

.rts-single-wized .recent-post-single .post-title .title {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: #1C2539;
  line-height: 26px;
  margin-top: 5px;
  transition: 0.3s;
}

@media only screen and (max-width: 479px) {
  .rts-single-wized .recent-post-single .post-title .title {
    font-size: 14px;
    line-height: 26px;
    margin-top: 0;
  }
}

.rts-single-wized .recent-post-single .post-title:hover .title {
  color: var(--color-primary);
}
.disc.para-1 {
  margin-bottom: 20px;
}

.rts-blog-list-area .pagination {
  margin-top: 10px;
}

.rts-blog-list-area.inner {
  background: var(--color-gray);
}

@media only screen and (max-width: 479px) {
  .thumbnail.details.team {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .thumbnail.details.team {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .thumbnail.details.team {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .pr_sm-controler--0 {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
}
.progress {
  width: 150px;
  height: 150px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-73%);
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid #434653;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}
.progress .progress-right {
  right: 0;
}


.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  font-size: 35px;
  color: #fff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}
@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(125deg);
    transform: rotate(125deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.background-contact-appoinment {
  background: #F6F6F6;
}

.background-contact-appoinment .contact-form-area-one {
  /* background-image: url(../images/appoinment/03.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  background-color: rgba(255, 255, 255, 0);
  padding: 165px 120px 120px 120px;
  margin-left: -26px;
}

@media only screen and (max-width: 575px) {
  .background-contact-appoinment .contact-form-area-one {
    padding: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .background-contact-appoinment .contact-form-area-one {
    padding: 60px 10px 60px 40px;
  }
}

.background-contact-appoinment .contact-form-area-one .title {
  color: #fff;
}

.background-contact-appoinment .contact-form-area-one input {
  background: #212329;
  color: #fff;
}

.background-contact-appoinment .contact-form-area-one input::-webkit-input-placeholder {
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
}

.background-contact-appoinment .contact-form-area-one textarea {
  color: #fff;
  background: #212329;
}

.background-contact-appoinment .contact-form-area-one textarea::-webkit-input-placeholder {
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 21px !important;
}

.contact-image-one.appoinment {
  margin-right: -123px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-image-one.appoinment img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .contact-image-one.appoinment img {
    width: 100%;
  }
}

.rts-contact-area.contact-one.appoinment::after {
  display: none;
}

.appoinment-team {
  padding-bottom: 210px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appoinment-team {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .appoinment-team {
    padding-bottom: 60px;
  }
}

.rts-faq-area {
  position: relative;
  overflow: hidden;
}

.rts-faq-area::after {
  position: absolute;
  content: "";
  right: -9%;
  top: -33%;
  /* background-image: url(../images/faq/shape.png); */
  height: 736px;
  width: 736px;
  background-size: contain;
  z-index: -1;
  animation: rotateIt 20s linear infinite;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-faq-area::after {
    position: absolute;
    content: "";
    right: -22%;
    top: -16%;
    /* background-image: url(../images/faq/shape.png); */
    height: 500px;
    width: 500px;
    background-size: contain;
    z-index: -1;
    animation: rotateIt 20s linear infinite;
  }
}

@media only screen and (max-width: 767px) {
  .rts-faq-area::after {
    position: absolute;
    content: "";
    right: -22%;
    top: -10%;
    /* background-image: url(../images/faq/shape.png); */
    height: 300px;
    width: 300px;
    background-size: contain;
    z-index: -1;
    animation: rotateIt 20s linear infinite;
  }
}

.rts-faq-area::before {
  position: absolute;
  content: "";
  left: -14%;
  top: 11%;
  /* background-image: url(../images/faq/shape-2.png); */
  width: 698px;
  height: 698px;
  background-size: contain;
  z-index: -1;
  animation: jump-2 5s linear infinite;
}

.rts-faq-area .faq-thumbnail img {
  width: 100%;
  animation: jump-2 5s linear infinite;
}

@media only screen and (max-width: 479px) {
  .rts-faq-area .faq-thumbnail img {
    margin-bottom: 30px;
  }
}

.rts-faq-area.inner {
  background-color: #fff;
}

.rts-faq-area.inner::before {
  display: none;
}

.rts-faq-area.inner::after {
  display: none;
}

.rts-faq-area.inner2 {
  background-color: var(--color-gray);
}

.accordion-area .title-area {
  margin-bottom: 50px;
}

.accordion-area .title-area span {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}

.accordion-area .title-area .title {
  margin-bottom: 0;
}

.accordion-area.inner {
  max-width: 700px;
  margin: 0 auto;
}

.accordion-one-inner .accordion-item {
  box-shadow: 0px 7px 18px rgba(24, 16, 16, 0.05);
  margin-bottom: 20px;
  border: none;
}

.accordion-one-inner .accordion-item .accordion-header button {
  font-size: 18px;
  color: #1C2539;
  font-weight: 700;
  padding: 29px 40px;
}

@media only screen and (max-width: 575px) {
  .accordion-one-inner .accordion-item .accordion-header button {
    padding: 13px 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .accordion-one-inner .accordion-item .accordion-header button {
    display: flex;
    align-items: flex-start;
  }
}

.accordion-one-inner .accordion-item .accordion-header button::after {
  background-image: none;
  content: "\f078";
  font-family: "FontAwesome" !important;
  transition: 0.3s;
  color: var(--color-primary);
}

.accordion-one-inner .accordion-item .accordion-header button span {
  color: var(--color-primary);
  margin-right: 5px;
  min-width: max-content;
}

.accordion-one-inner .accordion-item .accordion-header button:focus {
  box-shadow: none;
}

.accordion-one-inner .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: #fff;
}

.accordion-one-inner .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "\f077";
  font-family: "FontAwesome" !important;
  transform: rotate(0);
  transition: 0.3s;
}

.accordion-one-inner .accordion-item .accordion-body {
  padding: 0px 40px 40px 40px;
}

@media only screen and (max-width: 479px) {
  .accordion-one-inner .accordion-item .accordion-body {
    font-size: 14px;
    line-height: 24px;
    padding: 20px;
    padding-top: 0;
  }
}

.accordion-one-inner.inner .accordion-item {
  box-shadow: none !important;
  border: 1px solid #9993;
  border-radius: 15px;
  background-color: var(--color-gray);
}

.accordion-one-inner.inner .accordion-item .accordion-header button {
  border-radius: 15px;
  background-color: var(--color-gray);
}

.accordion-one-inner.inner .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: var(--color-gray);
}

.accordion-one-inner.inner .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "\f077";
  font-family: "FontAwesome" !important;
  transform: rotate(0);
  transition: 0.3s;
}

.accordion-one-inner.inner2 .accordion-item {
  border: none;
  box-shadow: 0px 7px 18px rgba(24, 16, 16, 0.05) !important;
  border-radius: 15px;
}

.accordion-one-inner.inner2 .accordion-item .accordion-header button {
  font-size: 18px;
  border-radius: 15px;
  background-color: #fff;
  font-weight: 700;
  padding: 29px 40px;
}

@media only screen and (max-width: 575px) {
  .accordion-one-inner.inner2 .accordion-item .accordion-header button {
    padding: 13px 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .accordion-one-inner.inner2 .accordion-item .accordion-header button {
    display: flex;
    align-items: flex-start;
  }
}

.accordion-one-inner.inner2 .accordion-item .accordion-header button::after {
  background-image: none;
  content: "\f078";
  font-family: "FontAwesome" !important;
  transition: 0.3s;
  color: var(--color-primary);
}

.accordion-one-inner.inner2 .accordion-item .accordion-header button span {
  color: var(--color-primary);
  margin-right: 5px;
  min-width: max-content;
}

.accordion-one-inner.inner2 .accordion-item .accordion-header button:focus {
  box-shadow: none;
}

.accordion-one-inner.inner2 .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  box-shadow: none;
  background: var(--color-primary);
  color: #fff;
  border-radius: 15px 15px 0 0;
}

.accordion-one-inner.inner2 .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "\f077";
  font-family: "FontAwesome" !important;
  transform: rotate(0);
  transition: 0.3s;
  color: #fff;
}

.accordion-one-inner.inner2 .accordion-item .accordion-header .accordion-button:not(.collapsed) span {
  color: #fff;
}

.accordion-one-inner.inner2 .accordion-item .accordion-body {
  padding: 0px 40px 40px 40px;
  color: #f9f9f9;
  background: var(--color-primary);
}

@media only screen and (max-width: 479px) {
  .accordion-one-inner.inner2 .accordion-item .accordion-body {
    font-size: 14px;
    line-height: 24px;
    padding: 20px;
    padding-top: 0;
  }
}

.tab-content>.tab-pane {
  display: none;
  transition: 0.3s;
}

.tab-content>.active {
  display: block;
  transition: 0.3s;
}

.tab-content {
  transition: 0.3s;
}

.rts-faq-bg {
  /* background-image: url(../images/faq/bg-01.jpg); */
}

.faq-two-inner .title-area-faq .sub {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.3em;
}

.faq-two-inner .title-area-faq span.sm-title {
  font-size: 40px;
}

@media only screen and (max-width: 575px) {
  .faq-two-inner .title-area-faq span.sm-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .faq-two-inner .title-area-faq span.sm-title {
    font-size: 20px;
  }
}

.faq-two-inner .title-area-faq span.sm-title span {
  color: var(--color-primary);
  font-weight: 600;
}

.faq-two-inner .title-area-faq .title {
  color: #fff;
  /* text-transform: uppercase; */
  margin-top: 5px;
}

.faq-two-inner .faq-accordion-area {
  margin-top: 47px;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item {
  background: transparent;
  margin-bottom: 30px;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button {
  border: 1px solid #414857;
  background: transparent;
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  height: 60px;
  align-items: center;
  display: flex;
  padding: 0 35px;
}

@media only screen and (max-width: 575px) {
  .faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button {
    font-size: 14px;
  }
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true] {
  border: 1px solid transparent;
  background: #fff;
  color: #1C2539;
  border-radius: 5px 5px 0 0;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button[aria-expanded=true]::after {
  content: "\f077";
  font-family: "FontAwesome" !important;
  background-image: none;
  top: -5px;
  position: relative;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button span {
  color: var(--color-primary);
  margin-right: 10px;
  min-width: max-content;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button:focus {
  box-shadow: none;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button::after {
  content: "\f078";
  font-family: "FontAwesome" !important;
  background-image: none;
  top: -5px;
  position: relative;
  transform: none;
  color: var(--color-primary);
}

.faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.faq-two-inner .faq-accordion-area .accordion .accordion-body {
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 10px 35px 35px 35px;
}

.thumbnail-faq-four {
  padding-left: 30px;
}



.rts-contact-area.contact-one {
  position: relative;
  z-index: 1;
}

.rts-contact-area.contact-one::after {
  position: absolute;
  content: "";
  background-image: url(./pages/images/contact/shape/01.png);
  right: 10%;
  top: -8%;
  height: 190px;
  width: 190px;
  z-index: -24;
  animation: rotateIt 15s linear infinite;
}

.contact-form-area-one {
  padding: 120px 100px;
  background: #fff;
  box-shadow: 0px 21px 46px #0000000a;
}

@media only screen and (max-width: 767px) {
  .contact-form-area-one {
    padding: 25px;
  }
}

.contact-form-area-one form {
  margin-top: 45px;
}

.contact-form-area-one form input {
  height: 55px;
  background: #F7F7F7;
  margin-top: 20px;
  border-radius: 15px;
  padding: 0 25px;
  border: 1px solid transparent;
}
.contact-form-area-one form small {
  color: #dc3232;
}
.contact-form-area-one form input:focus {
  border: 1px solid var(--color-primary);
}

.contact-form-area-one form textarea {
  border-radius: 15px;
  background: #F7F7F7;
  height: 150px;
  padding: 15px 25px;
  border: 1px solid transparent;
}

.contact-form-area-one form textarea:focus {
  border: 1px solid var(--color-primary);
}

.contact-form-area-one form .name-email {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .contact-form-area-one form .name-email {
    flex-direction: column;
  }
}

.contact-form-area-one form .name-email input {
  width: 48.8%;
}

@media only screen and (max-width: 767px) {
  .contact-form-area-one form .name-email input {
    width: 100%;
    display: block;
  }
}

.contact-form-area-one .rts-btn {
  display: block;
  max-width: max-content;
  margin-top: 30px;
}

.contact-form-area-one .pre-title {
  letter-spacing: 0.1em;
}

.contact-form-area-one .error {
  color: var(--color-primary);
}

.contact-form-area-one .success {
  color: green;
  margin-bottom: 15px;
}

.contact-map-area-fluid {
  position: relative;
}

.contact-map-area-fluid .contact-map {
  width: 100%;
  height: 580px;
}

.contact-map-area-fluid .location {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  max-width: 100px;
  height: auto;
}

.rts-contact-fluid {
  box-shadow: 0px 24px 39px rgba(0, 0, 0, 0.05);
}

.rts-contact-fluid .form-wrapper {
  width: 80%;
  margin: auto;
}

@media only screen and (max-width: 575px) {
  .rts-contact-fluid .form-wrapper {
    width: 90%;
  }
}

.rts-contact-fluid .form-wrapper .error {
  color: var(--color-primary);
  margin-bottom: 15px;
}

.rts-contact-fluid .form-wrapper .success {
  color: green;
  margin-bottom: 15px;
}

.rts-contact-fluid .form-wrapper form .name-email {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .rts-contact-fluid .form-wrapper form .name-email {
    flex-direction: column;
  }
}

.rts-contact-fluid .form-wrapper form .name-email input {
  width: 48.8%;
}

@media only screen and (max-width: 767px) {
  .rts-contact-fluid .form-wrapper form .name-email input {
    width: 100%;
  }
}

.rts-contact-fluid .form-wrapper input {
  background: #F6F6F6;
  height: 55px;
  border-radius: 15px;
  margin-top: 25px;
  border: 1px solid transparent;
}

.rts-contact-fluid .form-wrapper input:focus {
  border: 1px solid var(--color-primary);
}

.rts-contact-fluid .form-wrapper textarea {
  height: 150px;
  background: #F6F6F6;
  border-radius: 15px;
  padding: 15px 15px;
  border: 1px solid transparent;
}

.rts-contact-fluid .form-wrapper textarea:focus {
  border: 1px solid var(--color-primary);
}

.rts-contact-fluid .rts-btn {
  display: block;
  max-width: max-content;
  margin: auto;
  margin-top: 30px;
}

.onepage.two .rts-contact-fluid .form-wrapper {
  width: 100%;
}

.onepage.two .rts-contact-fluid {
  box-shadow: none;
}

.onepage.two .rts-title-area p.pre-title {
  font-size: 18px;
  color: #5D666F !important;
  font-weight: 500 !important;
}

.onepage.two .rts-title-area.contact-fluid::after {
  content: "09";
  left: 49%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
  top: -40%;
  min-width: max-content;
}

.contact-four::before {
  content: "";
  position: absolute;
  /* background-image: url(../images/contact/shape/02.png) !important; */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
  left: -2%;
  bottom: 10%;
  animation: rotateIt 15s linear infinite;
}

@media (max-width: 768px) {
  .contact-four::before {
    display: none;
  }
}

.contact-four::after {
  /* background-image: url(../images/contact/shape/02.png) !important; */
  background-repeat: no-repeat;
  background-size: cover;
  right: 8% !important;
  top: 20% !important;
}

.contact-four .container .contact-image-one img {
  width: 100%;
}

@media (max-width: 767px) {
  .contact-four .container .contact-form-area-one {
    padding-top: 50px;
  }
}

.contact-four .container .contact-form-area-one .rts-title-area::after {
  display: none;
}

.contact-four .container .contact-form-area-one .rts-title-area .pre-title {
  color: var(--color-primary-4);
}

.contact-four .container .contact-form-area-one .btn-primary {
  background: var(--color-primary-4);
}

.contact-four .container .contact-form-area-one .btn-primary:hover {
  color: var(--color-primary-4);
  background: #E4E7FF;
}

.contact-four .container .contact-form-area-one form input:focus {
  border-color: var(--color-primary-4);
}

.contact-four .container .contact-form-area-one form textarea:focus {
  border-color: var(--color-primary-4);
}

.rts-contact-form-area.six .rts-contact-fluid {
  background: #FFFFFF47;
  margin-top: 120px;
  padding: 30px 25px;
  border-radius: 15px;
}

@media (max-width: 1200px) {
  .rts-contact-form-area.six .rts-contact-fluid {
    margin-top: 0;
    margin-bottom: 120px;
  }
}

.rts-contact-form-area.six .rts-contact-fluid .rts-title-area::after {
  display: none;
}

.rts-contact-form-area.six .rts-contact-fluid .rts-title-area .title {
  font-size: 26px;
  color: var(--color-primary-6);
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper {
  width: 95%;
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper form input {
  margin-bottom: 15px;
  height: 50px;
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper form input:focus {
  border: 1px solid var(--color-primary-6);
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper form textarea:focus {
  border: 1px solid var(--color-primary-6);
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper form select {
  height: 50px;
  background: #f6f6f6;
  border-radius: 15px;
  margin-bottom: 15px;
  padding: 0 15px;
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper form select:focus {
  border: 1px solid var(--color-primary-6);
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper form .btn-primary {
  background: var(--color-primary-6);
  max-width: 100%;
  width: 100%;
  padding: 14px 30px;
  margin-top: 20px;
}

.rts-contact-form-area.six .rts-contact-fluid .form-wrapper form .btn-primary:hover {
  background: var(--color-primary-alta);
}

.bg-light-white {
  background: #F7F7F7;
}

.rts-map-area {
  position: relative;
}

.rts-map-area::after {
  position: absolute;
  content: "";
  background-image: url(./pages/images/contact/shape/01.png);
  height: 105px;
  width: 105px;
  left: 53px;
  top: -11%;
  background-size: cover;
  animation: rotateIt 10s linear infinite;
}

@media only screen and (max-width: 767px) {
  .contact-image-one img {
    width: 100%;
  }
}

.mapdetails-inner-one {
  margin-left: 305px;
  display: flex;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mapdetails-inner-one {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 1199px) {
  .mapdetails-inner-one {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mapdetails-inner-one {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .mapdetails-inner-one {
    margin-left: 100px;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 575px) {
  .mapdetails-inner-one {
    margin-left: 0;
  }
}

.mapdetails-inner-one .single-wized {
  margin-right: 63px;
  width: 41%;
}

@media only screen and (max-width: 575px) {
  .mapdetails-inner-one .single-wized {
    margin-right: 30px;
  }
}

.mapdetails-inner-one .single-wized:last-child {
  margin-right: 0;
}

.mapdetails-inner-one .single-wized .title {
  position: relative;
  margin-bottom: 40px;
  min-width: max-content;
}

.mapdetails-inner-one .single-wized .title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(--color-primary);
}

.mapdetails-inner-one .single-wized:first-child {
  border-right: 1px solid #E4E4E4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mapdetails-inner-one .single-wized:first-child {
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .mapdetails-inner-one .single-wized:first-child {
    border: none;
  }
}

@media only screen and (max-width: 575px) {
  .mapdetails-inner-one .single-wized:first-child {
    border-right: none;
  }
}

.mapdetails-inner-one.inner {
  max-width: 700px;
  margin: 0 auto;
}

.mapdetails-inner-one {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .mapdetails-inner-one {
    flex-direction: column;
  }
  .mapdetails-inner-one .single-wized {
    width: 100%;
}
}

@media only screen and (max-width: 575px) {
  .mapdetails-inner-one .left-area {
    margin-bottom: 30px;
  }
}

.mapdetails-inner-one .left-area .details p {
  margin-bottom: 3px;
  color: #5D666F;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.mapdetails-inner-one .left-area .details a.number {
  font-size: 16px;
  font-weight: 700;
  color: #1C2539;
  margin-bottom: 30px;
}

.mapdetails-inner-one .left-area .details p.time-header {
  margin-top: 30px;
  margin-bottom: 5px;
  color: #5D666F;
}

.mapdetails-inner-one .left-area .details p.time {
  color: #1C2539;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.mapdetails-inner-one .right-area .details p {
  margin-bottom: 3px;
  color: #5D666F;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.mapdetails-inner-one .right-area .details a {
  color: #1C2539;
  font-size: 16px;
  font-weight: 700;
}

.mapdetails-inner-one .right-area .details p.headoffice {
  margin-top: 24px;
}

.mapdetails-inner-one .right-area .details p.office {
  color: #1C2539;
  font-weight: 700;
}

.team-details-support-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.team-details-support-wrapper i {
  height: 60px;
  width: 60px;
  border-radius: 15px;
  border: 1px solid #EDEDED;
  box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: var(--color-primary);
}

.team-details-support-wrapper .support-innner {
  margin-left: 20px;
}

.team-details-support-wrapper .support-innner span {
  margin-bottom: 5px;
  color: #5D666F;
  font-size: 16px;
  font-weight: 400;
}

.team-details-support-wrapper .support-innner a .title {
  margin-bottom: 0;
  transition: 0.3s;
}

@media only screen and (max-width: 479px) {
  .team-details-support-wrapper .support-innner a .title {
    font-size: 14px;
  }
}

.team-details-support-wrapper .support-innner a:hover .title {
  color: var(--color-primary);
}

p.disc {
  font-size: 16px;
  line-height: 26px;
  text-align: justify !important;
}

.details-right-inner .title-area {
  margin-bottom: 16px;
}

.details-right-inner .title-area span {
  color: #5D666F;
  font-weight: 500;
  font-size: 16px;
}

.details-right-inner .title-area .title {
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 40px;
}

.details-right-inner p.disc {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 33px;
  color: #5D666F;
}

.details-right-inner .rts-btn {
  margin-top: 30px;
  display: block;
  max-width: max-content;
}

.single-about-skill-inner .title {
  position: relative;
  margin-bottom: 40px;
}

.single-about-skill-inner .title::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #F1F1F1;
}

.rts-progress-one-wrapper .meter {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  display: block;
  height: 8px;
  margin-bottom: 10px;
  position: relative;
}

.rts-progress-one-wrapper .meter>span {
  display: block;
  height: 100%;
  background-color: #0d6efd;
  position: relative;
  overflow: hidden;
  transition: width 2s ease-out;
}

.rts-progress-one-wrapper .orange>span {
  background-color: #0d6efd;
}

.rts-progress-one-wrapper .red>span {
  background-color: #0d6efd;
}

.rts-progress-one-wrapper .cadetblue>span {
  background-color: #0d6efd;
}

.rts-progress-one-wrapper .single-progress {
  margin-bottom: 20px;
  border-bottom: 1px solid #F1F1F1;
}

.rts-progress-one-wrapper .progress-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.rts-progress-one-wrapper .progress-top p {
  margin-bottom: 0;
  color: #0d6efd;
  font-weight: 500;
  font-size: 16px;
}

.rts-progress-one-wrapper .progress-top .persectage {
  color: #0d6efd;
  font-weight: 500;
  font-size: 16px;
}

.education-skill-wrapper {
  display: flex;
  box-shadow: 0px 13px 21px rgba(0, 0, 0, 0.03);
  flex-wrap: wrap;
}

.education-skill-wrapper .number-area {
  position: absolute;
  height: 60px;
  width: 60px;
  background: var(--color-primary);
  border-radius: 50%;
  left: -4%;
  top: -10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.education-skill-wrapper .number-area p {
  font-size: 22px;
  color: #fff;
  margin-left: auto;
  margin-top: auto;
  margin-right: 12px;
  margin-bottom: 8px;
}

.education-skill-wrapper .number-area p span {
  font-size: 12px;
  color: #fff;
  margin-left: -3px;
}

.education-skill-wrapper .single-skill {
  padding: 30px 60px;
  border: 1px solid #F1F1F1;
  width: 50%;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  background: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .education-skill-wrapper .single-skill {
    padding: 30px 11px 30px 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .education-skill-wrapper .single-skill {
    padding: 30px 10px 30px 40px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .education-skill-wrapper .single-skill {
    padding: 30px 10px 30px 50px;
  }
}

.education-skill-wrapper .single-skill .experience {
  margin-bottom: 3px;
}

.education-skill-wrapper .single-skill .date span {
  color: var(--color-primary);
}

.education-skill-wrapper .single-skill:hover {
  transform: scale(1.05);
}

.single-contact-one-inner {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  height: 100%;
}

.single-contact-one-inner .thumbnail {
  display: block;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.single-contact-one-inner .thumbnail img {
  width: 100%;
  transition: 0.3s;
}

.single-contact-one-inner .thumbnail:hover img {
  transform: scale(1.1);
}

.single-contact-one-inner .content {
  display: flex;
  align-items: center;
  padding: 30px;
  background-color: var(--color-primary-alta);
}

@media only screen and (max-width: 767px) {
  .single-contact-one-inner .content {
    padding: 15px;
  }
}

.single-contact-one-inner .content .icone {
  margin-right: 20px;
}

.single-contact-one-inner .content .info span {
  margin-bottom: 0;
  color: var(--color-primary);
  font-weight: 500;
}

.single-contact-one-inner .content .info a h5 {
  margin-bottom: 0;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-contact-one-inner .content .info a h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .single-contact-one-inner .content .info a h5 {
    font-size: 18px;
  }
}

.single-contact-one-inner .content .info a:hover h5 {
  color: var(--color-primary);
}

.working-process-bg {
  /* background-image: url(../images/working-step/bg-01.jpg); */
  max-width: 1680px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.rts-working-process-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rts-working-process-1 .inner {
  width: 192px;
  height: 192px;
  border: 2px dashed #0b4df52f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 33px;
  transition: 0.3s;
  position: relative;
}

.rts-working-process-1 .inner.two::after {
  content: "02";
}

.rts-working-process-1 .inner.three::after {
  content: "03";
}

.rts-working-process-1 .inner.four::after {
  content: "04";
}

.rts-working-process-1 .inner::after {
  position: absolute;
  right: 5px;
  top: 7px;
  content: "01";
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--color-primary-2);
  color: #fff;
  transform: scale(0);
  transition: 0.3s;
}

.rts-working-process-1 .inner .icon {
  height: 144px;
  width: 144px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rts-working-process-1 .content .title {
  margin-bottom: 7px;
}

.rts-working-process-1.process-lg .inner {
  width: 245px;
  height: 245px;
}

.rts-working-process-1.process-lg .inner::after {
  width: 60px;
  height: 60px;
}

.rts-working-process-1.process-lg .inner .icon {
  width: 193.03px;
  height: 193.03px;
}

.rts-working-process-1:hover .inner {
  border: 2px dashed var(--color-primary-2);
}

.rts-working-process-1:hover .inner::after {
  transform: scale(1);
}

.rts-service-areah2-im-3 .image-area {
  position: relative;
  margin-bottom: 120px;
  margin-top: 30px;
}

@media only screen and (max-width: 479px) {
  .rts-service-areah2-im-3 .image-area {
    margin-bottom: 80px;
  }
}

.rts-service-areah2-im-3 .image-area .ratio-area {
  position: absolute;
  top: 60px;
  left: 60px;
}

@media only screen and (max-width: 479px) {
  .rts-service-areah2-im-3 .image-area .ratio-area {
    top: 30px;
    left: 30px;
  }
}

.rts-service-areah2-im-3 .image-area .ratio-area .ratio {
  color: #fff;
  margin-bottom: 0px;
  font-size: 40px;
  font-weight: 700;
}

.rts-service-areah2-im-3 .image-area .ratio-area span {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

@media only screen and (max-width: 479px) {
  .rts-service-areah2-im-3 .image-area .ratio-area span {
    font-size: 16px;
  }
}

.rts-service-areah2-im-3 .image-area img {
  border-radius: 20px;
}

.rts-service-areah2-im-3 .image-area img.two {
  position: absolute;
  z-index: 5;
  top: -10%;
  left: 40%;
  animation: jump-1 5s linear infinite;
}

.rts-service-areah2-im-3 .image-area img.three {
  position: absolute;
  left: 18%;
  top: 38%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .rts-service-areah2-im-3 .image-area img.three {
    width: 85%;
  }
}

.rts-service-areah2-im-3.eight .title-area.service-h2.service::after {
  content: "01";
}

.working-process-area2 {
  /* background: url("../images/working-step/bg-02.jpg"); */
  max-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.working-process-area2 .container .title-area span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}

.working-process-area2 .container .title-area .title {
  color: #fff;
}

.working-process-area2 .container .rts-working-process-1 .inner {
  border-color: #5264f330;
}

.working-process-area2 .container .rts-working-process-1 .inner::after {
  background: var(--color-primary-4);
}

.working-process-area2 .container .rts-working-process-1:hover .inner {
  border-color: var(--color-primary-4);
}

.working-process-area2 .container .rts-working-process-1 .content .title {
  color: #fff;
}

.rts-working-process-section.eight .title-area::after {
  content: "02";
  left: 7%;
  transform: translateX(-50%);
  top: -70%;
  height: 145px;
}
.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 10000;
  opacity: 1;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  font-family: "FontAwesome";
  content: "\f062";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: var(--color-primary);
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  border: 2px solid var(--color-primary);
  box-shadow: none;
  border-radius: 50% !important;
  border-radius: 5px;
}

.progress-wrap:hover::after {
  opacity: 1;
  content: "\f062";
  border: 2px solid var(--color-primary);
}

.progress-wrap::before {
  position: absolute;
  font-family: "FontAwesome";
  content: "\e84b";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  background: var(--color-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap:hover::before {
  opacity: 0;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg {
  color: #015fc9;
  border-radius: 50%;
  background: #fff;
}

.progress-wrap svg.progress-circle path {
  stroke: #015fc9;
  stroke-width: 34px;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}


/* Loader Styles start here */
.loader-wrapper {
  --line-width: 5px;
  --curtain-color: #ffffff;
  --outer-line-color: #9db7d5;
  --middle-line-color: #4078b7;
  --inner-line-color: #04448d;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loader {
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: var(--line-width) solid transparent;
  border-top-color: var(--outer-line-color);
  border-radius: 100%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 1001;
}

.loader:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: var(--line-width) solid transparent;
  border-top-color: var(--inner-line-color);
  border-radius: 100%;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loader:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
  bottom: 14px;
  border: var(--line-width) solid transparent;
  border-top-color: var(--middle-line-color);
  border-radius: 100%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  background: var(--curtain-color);
  width: 51%;
  height: 100%;
  z-index: 1000;
}

.loader-wrapper .loader-section.section-left {
  left: 0;
}

.loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded Styles */
.loaded .loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded .loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded .loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.loaded .loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}

.background-cta {
  background-image: url(./pages/images/footer/callto-action.webp);
  background-repeat: no-repeat;
  background-color: #0d6efd;
  background-blend-mode: multiply;
  overflow: hidden;
  border-radius: 100px;
  background-position: center;
  object-fit: contain;
  background-size: cover;
}

.background-cta.six {
  background-color: var(--color-primary-6);
}
 
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .background-cta {
    border-radius: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .background-cta {
    border-radius: 10px;
  }
}

.rts-cta-wrapper .background-cta .cta-left-wrapepr {
  margin-left: 80px;
  padding: 45px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-cta-wrapper .background-cta .cta-left-wrapepr {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rts-cta-wrapper .background-cta .cta-left-wrapepr {
    padding-bottom: 10px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .rts-cta-wrapper .background-cta .cta-left-wrapepr {
    margin: 0;
    text-align: center;
  }
}

.rts-cta-wrapper .background-cta .cta-left-wrapepr p {
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-family: var(--font-secondary);
}

@media only screen and (max-width: 479px) {
  .rts-cta-wrapper .background-cta .cta-left-wrapepr p {
    font-size: 14px;
  }
}

.rts-cta-wrapper .background-cta .cta-left-wrapepr .title {
  color: #fff;
  margin-bottom: 0;
  margin-top: -5px;
}

@media only screen and (max-width: 479px) {
  .rts-cta-wrapper .background-cta .cta-left-wrapepr .title {
    font-size: 18px;
  }
}

.rts-cta-wrapper .background-cta .cta-input-arae {
  padding: 60px 0;
  position: relative;
}

@media screen and (max-width: 996px) {
  .rts-cta-wrapper .background-cta .cta-input-arae {
    padding-top: 0;
    margin-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .rts-cta-wrapper .background-cta .cta-input-arae {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .rts-cta-wrapper .background-cta .cta-input-arae {
    margin-left: 20px;
    padding-bottom: 30px;
  }
}

.rts-cta-wrapper .background-cta .cta-input-arae input {
  background: #FFFFFF;
  border-radius: 100px;
  height: 55px;
  width: 547px;
  color: #000;
  padding: 0 186px 0 25px;
}

@media screen and (max-width: 1400px) {
  .rts-cta-wrapper .background-cta .cta-input-arae input {
    width: 64%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rts-cta-wrapper .background-cta .cta-input-arae input {
    width: 64%;
  }
  .title-area-faq{
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .rts-cta-wrapper .background-cta .cta-input-arae input {
    padding-right: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .rts-cta-wrapper .background-cta .cta-input-arae input {
    height: 45px;
  }
}
@media only screen and (max-width: 479px) {
  .rts-cta-wrapper .background-cta .cta-input-arae input {
    border-radius: 5px;
    padding: 0 82px 0 10px;
  }
}
.rts-cta-wrapper .background-cta .cta-input-arae button {
  position: absolute;
  border-radius: 100px;
  max-width: max-content;
  right: 90px;
}
@media only screen and (max-width: 575px) {
  .rts-cta-wrapper .background-cta .cta-input-arae button {
    padding: 12px;
    right: 20px;
    margin-left: 0;
  }
}
@media only screen and (max-width: 479px) {
  .rts-cta-wrapper .background-cta .cta-input-arae button {
    border-radius: 5px;
    font-size: 12px;
  }
}
.footer-one-single-wized.mid-bg {
  background: linear-gradient(180deg, #0E1422 -4.66%, #212631 100%);
  border-radius: 15px;
  padding: 40px;
  margin-top: -40px;
  margin: -40px 30px 0 30px;
}
@media only screen and (max-width: 767px) {
  .footer-one-single-wized.mid-bg {
    margin-left: 0;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .footer-one-single-wized.mid-bg {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .footer-one-single-wized.mid-bg {
    margin-right: 0;
    padding: 10px;
  }
}
.footer-one-single-wized.mid-bg .opening-time-inner .rts-btn.contact-us {
  display: block;
  max-width: max-content;
}

@media only screen and (max-width: 479px) {
  .footer-one-single-wized.mid-bg .opening-time-inner .rts-btn.contact-us {
    padding: 12px 22px;
  }
}

.footer-one-single-wized.six .quick-link-inner .links li a:hover {
  color: var(--color-primary-6);
}

.footer-one-single-wized.six .quick-link-inner .links li a::after {
  background: var(--color-primary-6);
}
.footer-one-single-wized.seven .quick-link-inner .links li a:hover {
  color: var(--color-primary-5);
}

.footer-one-single-wized.seven .quick-link-inner .links li a::after {
  background: var(--color-primary-5);
}
.footer-one-single-wized .wized-title {
  margin-bottom: 25px;
}

.footer-one-single-wized .wized-title .title {
  color: #fff;
  margin-bottom: 10px;
}

.footer-one-single-wized .quick-link-inner {
  display: flex;
}

@media only screen and (max-width: 479px) {
  .footer-one-single-wized .quick-link-inner {
    flex-direction: column;
  }
}

.footer-one-single-wized .quick-link-inner .links {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.footer-one-single-wized .quick-link-inner .links.margin-left-70 {
  margin-left: 70px;
}

@media only screen and (max-width: 479px) {
  .footer-one-single-wized .quick-link-inner .links.margin-left-70 {
    margin-left: 0;
  }
}

.footer-one-single-wized .quick-link-inner .links li {
  margin-top: 0;
}

.footer-one-single-wized .quick-link-inner .links li a {
  color: #8B8F99;
  transition: var(--transition);
  position: relative;
  max-width: max-content;
}

.footer-one-single-wized .quick-link-inner .links li a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background: var(--color-primary);
  left: 29px;
  bottom: 0;
  transition: 0.3s;
}

.footer-one-single-wized .quick-link-inner .links li a i {
  margin-right: 12px;
  transition: 0.3s;
}

.footer-one-single-wized .quick-link-inner .links li a:hover {
  color: var(--color-primary);
}

.footer-one-single-wized .quick-link-inner .links li a:hover::after {
  position: absolute;
  width: 76%;
}

.footer-one-single-wized .quick-link-inner .links li a:hover i {
  color: #fff;
}

.footer-one .rts-copyright-area {
  border-top: 1px solid #21293D;
  padding: 30px 0;
}

.footer-one .rts-copyright-area p {
  font-size: 16px;
  color: #fff;
  margin: 0px;
}

.support-team {
  display: flex;
  align-items: center;
  padding-top: 40px;
  border-top: 1px solid #EEEEEE;
  margin-top: 10px;
}

.support-team.eight {
  border: none;
}

.support-team .thumbnail {
  display: block;
  overflow: hidden;
  border-radius: 20px;
}

.support-team .thumbnail img {
  border-radius: 20px;
  transition: 0.3s;
}

.support-team .thumbnail:hover img {
  transform: scale(1.1);
}

.support-team .details {
  margin-left: 15px;
}

.support-team .details a .title {
  transition: 0.3s;
}

.support-team .details a:hover .title {
  color: var(--color-primary-2);
}

.support-team .details .title {
  margin-bottom: 0;
  margin-top: 5px;
}
.margin-left-65 {
  margin-left: 65px;
}

@media only screen and (max-width: 1199px) {
  .margin-left-65 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .footer-one-single-wized {
    padding: 0 10px;
  }
}
/*# sourceMappingURL=../maps/ */
/* end style */