.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* custom css */

/* 
========
comman css
==========
 */
 .font-weight-normal {
  font-weight: 400;
}

.font-static-black {
  color: #000 !important;
}

.font-static-16 {
  font-size: 16px;
}

.font-static-24 {
  font-size: 24px !important;
}

.job-details h4 {
  padding-left: 25px;
}

.font-weight-600 {
  font-weight: 600;
}

/* .font-static-black {
  color: #000 !important;
} */

.font-static-white {
  color: #fff !important;
}

.font-static-gray {
  color: var(--color-body);
}

/* 
==========
NAVBAR HEADER
==========
 */
.main-header .nav-main ul li a.active,
.main-header .nav-main ul li a.active {
  color: var(--color-primary);
  position: relative;
}

.main-header .nav-main ul li a.active::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 33px;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background: var(--color-primary);
  transition: var(--transition);
}

.mainmenu li.service-menu .submenu {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
}

.mainmenu li.service-menu .submenu li {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .mainmenu li.service-menu .submenu li {
    width: 100%;
  }

  .mainmenu li.service-menu .submenu {
    width: auto;
  }
  
  .title-area-faq {
    padding-left: 0px !important;
}
}

/* 
================================
sidebar
================================
 */

.rts-sidebar-menu-desktop .get-in-touch .wrapper .single {
  display: flex;
  align-items: baseline;
}

/* .main-header{
  justify-content: space-between;
 } */
.main-header .button-area {
  margin-right: 16px;
}

/* 
========
BANNER CSS & HOME PAGE
===========
 */

.banner-one .swiper-wrapper .swiper-slide {
  /* background-image: url(../images/banner/Frame\ 5.png); */
  background-image: url(../src/pages/images/banner/banner-5.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-one .swiper-wrapper .swiper-slide.two {
  background-image: url(../src/pages/images/banner/banner-4.webp);
}

.banner-one .swiper-wrapper .swiper-slide.three {
  background-image: url(../src/pages/images/banner/banner-3.webp);
}

/* .rts-banner-one .animation-img .shape-img.three{
    opacity: 0.7;
  } */
.banner-one-inner .title span {
  font-size: 65px;
}

/* 
  =======
  ABOUT
  ==========
 */
.about-one-thumbnail .experience .right p {
  color: #fff;
  line-height: 24px;
  font-size: 18px;
  font-weight: 700;
}

.about-one-thumbnail .experience {
  padding: 27px 40px 23px;
  width: auto;
}

.rts-about-our-company-h2 .title-area span {
  color: var(--color-primary);
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: 600;
}

.thumbnail-faq-four img {
  animation: rotation 14s infinite linear;
}

.global-business-pic {
  position: relative;
  text-align: center;
}

.global-business-pic p.xl-business-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@keyframes rotation {
  from {
    transform: rotate(0)
  }

  to {
    transform: rotate(360deg)
  }
}

.global-business-pic p.xl-business-text span:nth-child(1) {
  font-size: 20px;
  color: var(--bs-blue);
  font-weight: 600;
}

.global-business-pic p.xl-business-text span:nth-last-of-type(2) {
  font-size: 25px;
  color: var(--bs-dark);
  font-weight: 600;
}

.global-business-pic p.xl-business-text span:nth-last-of-type(3) {
  font-size: 22px;
  color: var(--bs-cyan);
  font-weight: 600;
}

.global-business-pic p.xl-business-text span:last-child {
  font-size: 35px;
  color: var(--bs-red);
  font-weight: 600;
}

/* 
===========
ABOUT PAGE
============
 */
.rts-about-bg {
  background-image: url(./pages/images/service/bg-04.jpg);
}

.industriescol2 {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.title-area-faq {
  padding-left: 60px;
}

.rts-faq-section .faq-two-inner .title-area-faq .sub {
  color: var(--color-primary);
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: 600;
  text-transform: uppercase;
}

.faq-two-inner .title-area-faq .title {
  color: var(--color-heading-1);
}

.industries-inc ul li {
  float: left;
  width: 95px;
  box-sizing: border-box;
  margin: 0px 15px;
}

.industries-inc ul li a {
  background: url(./pages/images/about/home_industry.png) no-repeat -1px -59px;
  background-position: 8px 8px;
  float: left;
  width: 102px;
  height: 102px;
}

.industries-inc ul li .realestate {
  background-position: -96px 8px;
}

.industries-inc ul li .furniture {
  background-position: -200px 8px;
}

.industries-inc ul li .jewelry {
  background-position: -304px 8px;
}

.industries-inc ul li .hotel {
  background-position: -408px 8px;
}

.industries-inc ul li .chemical {
  background-position: 8px -95px;
}

.industries-inc ul li .pharma {
  background-position: -96px -95px;
}

.industries-inc ul li .manufacture {
  background-position: -200px -95px;
}

.industries-inc ul li .advertising {
  background-position: -304px -95px;
}

.industries-inc ul li .engineering {
  background-position: -408px -95px;
}

.industries-inc ul li .finance {
  background-position: 8px -200px;
}

.industries-inc ul li .food {
  background-position: -96px -200px;
}

.industries-inc ul li .education {
  background-position: -200px -200px;
}

.industries-inc ul li .solar {
  background-position: -304px -200px;
}

.tooltip {
  display: inline-block;
  position: relative;
  z-index: 999;
  opacity: unset;
}

.tooltip-content {
  position: absolute;
  z-index: 9999;
  min-width: 130px;
  left: 50%;
  transform: translateX(-50%) !important;
  top: -50px;
  line-height: 100%;
  box-shadow: -5px -5px 15px rgba(48, 54, 61, .2);
  background: var(--color-primary);
  opacity: 0;
  cursor: default;
  pointer-events: none;
  color: #fff;
  box-sizing: border-box;
  padding: 12px 10px 10px;
  border-radius: 4px;
  text-align: center;
  word-wrap: break-word;
  font-size: 18px;
}

.tooltip-content:after {
  content: '';
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: var(--color-primary);
  border-width: 10px;
  margin-left: -10px;
}

.tooltip:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .3s, transform .3s;
}

@media screen and (max-width: 640px) {
  .industries-inc ul li {
    width: 96px !important;
  }

  /* jay */
  .global-business-pic {
    width: 100%;
    float: none;
    position: relative;
    text-align: center;
    margin: 30px auto
  }

  /*End jay */
  .title-area-faq {
    padding-left: 0px;
  }

  .thumbnail-faq-four {
    padding-left: 0px;
  }
}

@media screen and (max-width: 992px) {
  .industriescol2 {
    box-sizing: border-box;
    padding: 0;
    position: relative;
    z-index: 1;
    width: 100%;
  }

  .global-business-pic {
    margin: 0 auto 70px
  }

  .industriescol2.industries-inc ul {
    width: 100%;
    text-align: center;
    padding: 0px;
  }

  .industries-inc ul li {
    float: none !important;
    display: inline-block !important
  }
}

@media (max-width: 767px) {
  .industries-inc ul li {
    float: left;
    width: 118px;
    box-sizing: border-box;
    margin: 5px;
  }

  .title-area-faq {
    padding-left: 0px;
  }
}

/* 
=============
FEEDBACK
================
*/
.rts-test-one-image-inner::after {
  background-image: url(./pages/images/testimonials/shape/01.png);
  background-repeat: no-repeat;
}

.rts-client-feedback::after {
  background-image: url(./pages/images/testimonials/shape/03.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.rts-test-one-image-inner::after {
  bottom: -21%;
}

.mySwipertestimonial .swiper-button-next {
  right: 3%;
}

.mySwipertestimonial .swiper-button-prev {
  left: 82%;
}

/* 
============
TEAM
============ */
.bg-team {
  /* background-image: url(../images/team/bg-01.png); */
  background-repeat: no-repeat;

}

.team-inner-two a.thumbnail img {
  max-width: 410px;
  max-height: 450px;
}

.rts-title-area.ceo::after {
  content: "CEO";
  left: 49%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
}

.rts-title-area.teams::after {
  content: "TEAMS";
  left: 49%;
  transform: translateX(-50%);
  z-index: -1;
  height: 109px;
}

/* 
===========
GALLERY
===========
 */
.bg-right-gallery {
  background-image: url(./pages/images/gallery/gallery-bg-1.png);
}

.thumbnail-gallery img {
  border-radius: 20px;
}

.bg-right-gallery .icon {
  margin-top: 20px;
}

.thumbnail.webvision-portfolio-image{
  position: relative;
    line-height: 0;
}
.thumbnail.webvision-portfolio-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  transform: scale(1.15);
  width: 100%;
  height: 100%;
  transition: .5s ease;
  opacity: 0;
}
.thumbnail.webvision-portfolio-image:hover:before {
  opacity: 1;
}
.view-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
.thumbnail.webvision-portfolio-image:hover .view-content {
  display: block;
  transition: .5s ease;
}
.rts-work-area .webvision-portfolio-image a.view-more {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  transition: .5s ease;
}
.big-bg-porduct-details img {
  width: 100%;
  border-radius: 16px;
  /* max-width: 635px;
  max-height: 535px; */
  object-fit: contain;
}
.para {
  box-shadow: 5px 5px 20px -10px rgb(0 0 0/25%);
  border-left: 6px solid #015fc9;
  /* border-radius: 10px; */
  background: #fff;
  padding: 20px;
  float: left;
  margin: 20px 0;
  text-align: left;
  box-sizing: border-box;
  /* width: 80%; */
}
.big-bg-porduct-details.appointment-img img{
  max-width: 100%;
  max-height: 100%;
}

/* 
 ===============
 CONTACT US PAGE breadcrumb
 */
.breadcrumb-bg {
  background-image: url(./pages/images/breadcrumb/01.webp);
  background-repeat: no-repeat;
  background-size: cover;

}
.rts-contact-fluid small{
  color: #dc3232;
}

/* 
===========
SERVICE
===========
 */
.service-one-inner .thumbnail img {
  max-width: 60px;
}

.service-detials-step-1 p.disc {
  text-align: justify;
}

/* 
====================
WHY TO CHOOSE US
====================
*/

.cta-one-bg {
  background-color: #538adb;
}

.background-cta {
  background-color: #538adb
}

.rts-title-area.feature::after {
  content: 'Why';
}

/* 
=====================
feature
=====================
*/
.rts-business-goal .single-goal img {
  margin-right: 30px;
  width: 65px;
}

/* 
====================
CONTANCT 
====================
*/
.rts-contact-area.contact-one {
  padding: 80px 0px;
  background-color: #0E1422;
}

.single-contact-one-inner {
  background-color: var(--color-primary-alta);
}

.single-contact-one-inner .icone img {
  max-width: none;
}

.single-contact-one-inner .content {
  display: flex;
  align-items: flex-start;
}

/* 
====================
FOOTER
====================
*/
.rts-footer-area small{
  color: #dc3232;
}
.site-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 32px;
}

.site-footer__social a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 15px;
  border-radius: 17px;
  border: 2px solid var(--bs-blue);
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.footer-one-single-wized .icon-png i{
  font-size: 20px;
}
.site-footer__social a:hover {
  color: var(--bs-blue);
  background-color: #fff;
  border: 2px solid #fff;
}

.site-footer__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #fff;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.site-footer__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.site-footer__social a+a {
  margin-left: 10px;
}

.footer-one-single-wized .quick-link-inner {
  display: flex;
  justify-content: space-between;
}

.bg-footer-one p,
.footer-one-single-wized .quick-link-inner .links li a {
  color: #fff;
}

.bg-footer-one p,
.footer-one-single-wized .quick-link-inner .links li {
  color: #fff;
}

.footer-one-single-wized .quick-link-inner .links li i {
  margin-right: 12px;
}
.footer-one-single-wized .quick-link-inner .links tr td i{
  font-size: 22px;
}
/* ABOUTUS PAGE */
.bg-customer-feedback .swiper-slide {
  background: none;
  text-align: left;

}

.mySwipertestimonial2 .swiper-button-next,
.mySwipertestimonial2 .swiper-button-prev {
  right: 30%;
  display: none;
}

@media only screen and (max-width: 479px) {
  .mySwipertestimonial2 .swiper-button-next {
    right: 4%;
  }
}

.mySwipertestimonial2 .swiper-button-next::after {
  content: "\f061";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  color: #1C2539;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 9px 18px #1810100d;
  border-radius: 8px;
  transition: 0.3s;
  line-height: 16px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.mySwipertestimonial2 .swiper-button-next:hover::after {
  background: var(--color-primary);
  color: #fff;
}

.mySwipertestimonial2 .swiper-button-prev {
  left: 57%;
}

@media only screen and (max-width: 479px) {
  .mySwipertestimonial2 .swiper-button-prev {
    left: 68%;
  }
}

.mySwipertestimonial2 .swiper-button-prev::after {
  content: "\f060";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  color: #1C2539;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 9px 18px #1810100d;
  border-radius: 8px;
  transition: 0.3s;
  line-height: 16px;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.mySwipertestimonial2 .swiper-button-prev:hover::after {
  background: var(--color-primary);
  color: #fff;
}

.mySwipertestimonial2 .swiper-button-next,
.mySwipertestimonial2 .swiper-button-prev {
  top: 85%;
}

@media only screen and (max-width: 479px) {

  .mySwipertestimonial2 .swiper-button-next,
  .mySwipertestimonial2 .swiper-button-prev {
    top: 91%;
  }
}

/* 
==================================
e-commerce page
==================================
 */
.benefits-two__points li {
  position: relative;
  display: flex;
  align-items: baseline;
}

.benefits-two__points li+li {
  margin-top: 14px;
}

.benefits-two__points li .icon {
  position: relative;
  height: 16px;
  width: 16px;
  background-color: var(--bs-blue);
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transform: scale(1) rotateY(0deg);
  transform: scale(1) rotateY(0deg);
  padding: 10px;
}

.benefits-two__points li:hover .icon {
  -webkit-transform: scale(0.9) rotateY(360deg);
  transform: scale(0.9) rotateY(360deg);
  background-color: var(--bs-blue);
}

.benefits-two__points li .text {
  margin-left: 15px;
}

.benefits-two__points li .text p {
  font-size: 18px;
  color: var(--insur-black);
  font-weight: 500;
  letter-spacing: var(--insur-letter-spacing);
}

/* 
=========================================================
OUR SERVICES PAGE
========================================================= */
.accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
  content: "";
  position: absolute;
  left: 0;
  background-image: none;
  left: -11.5%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  background-color: #f8f9fa;
  z-index: 5;
  transition: 0.3s;
  width: 22px;
  height: 22px;
  border: 6px solid #015fc9;
}

.accordion-service-bg {
  background-image: url(./pages/images/about/Frame_42.webp);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
    left: -51px;

  }
}

@media only screen and (max-width: 1199px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
    left: -12.5%;
  }

  .rts-about-bg {
    height: auto;
  }

  .main-header .button-area {
    margin-right: 0px;
  }
  .product-details-main-inner{
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .accordion-service-inner .accordion-area .accordion .accordion-item .accordion-header button::before {
    left: -18.5%;
  }

  .accordion-service-bg {
    background-image: none;
    background: #F6F6F6;
  }

  .footer-one-single-wized.mid-bg {
    margin: 0px 10px 0 10px;
  }

  .footer-one-single-wized .quick-link-inner {
    flex-wrap: wrap;
  }
}

/* 
=========================================
web-design page
=========================================
*/
.rts-single-wized .single-categories li a.active {
  background: #0158c9;
  color: #fff;
}

.rts-single-wized .single-categories li a i.active {
  color: #fff;
}

/* all inner page */
/* .service-two-inner .thumbnail img{
  height: 285px;
  object-fit: cover;
} */
.service-details-card .thumbnail {
  margin-bottom: 20px;
}

/* 
===========================================
other-serivce page
============================================
*/
.service-details-card {
  padding: 30px;
  box-shadow: 0px 8px 30px #6a6a6a1a;
  border-left: 5px solid var(--color-primary);
  height: 340px;
}

/* 
==================================
Project Detalis page
==================================
*/
.single-project-details-challenge {
  display: block;
}

.rts-project-details-area .thumbnail img {
  border-radius: 15px;
}

/* 
======================================
IT-CONSULTANCY
======================================
*/
.rts-service-details-area .disc span {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .service-detials-step-1 p.disc {
    text-align: left;

  }

  .mainmenu li.service-menu .submenu li {
    width: 100%;
  }
}

.title-area-client p.client-title {
  font-size: 25px;
}

/* 
==================
career page 
==================*/
.who-we-title h2.title {
  text-transform: capitalize;
}

.opportunities-section {
  padding: 40px 0 70px
}

.opportunities-section .view-btn a.btn {
  position: absolute;
  left: 50%;
  bottom: -5%;
  transform: translate(-50%, 5%);
  width: 50%;
  line-height: 21px;
  padding: 15px
}

.opportunities-section .vacancy-header .job-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0
}

.opportunities-section .vacancy-header .job-title h2 {
  line-height: 28px
}

.opportunities-section .vacancy-header .numbering {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto
}

.opportunities-section .vacancy-header .numbering p {
  line-height: 28px;
  margin: 0;
  padding: 0
}

.opportunities-section .vacancy-header .number-opening small {
  line-height: 16px
}


.apply-btn .btn,
.modal .apply-btn .btn {
  padding: 10px 48px;
  border-radius: 48px
}

.opportunities-section .vacancy-header {
  box-shadow: 0 3px 15px rgba(0, 0, 0, .1);
  border-radius: 20px;
  position: relative;
  padding: 20px 30px;
  z-index: 0;
  width: 90%;
  margin: 0 auto;
}

.opportunities-section .vacancy-header::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  height: 185px;
  background-image: url(./pages/images/career/career-shape.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.job-details {
  position: relative;
  padding: 5px 0;
}

.job-details p {
  margin: 0px;
}

.job-details::after {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  transform: rotate(45deg);
  background-color: var(--color-primary);
}

.intro {
  font-weight: 400;
  font-size: 18px;
  color: #000;
  list-style: disc;
}

.bg-blue {
  background-color: var(--color-primary);
}

.btn-primarys {
  border-radius: 95px;
  border-color: transparent;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
  z-index: 0;
  -webkit-appearance: none;
}

.btn-primarys:hover {
  color: #000 !important;
  background-color: transparent;
  border-color: transparent;
}

.btn-primarys:hover::before {
  height: 380%;
}

.font-static-18 {
  font-size: 18px;
}

.btn-primarys::before {
  background: #f0fafd;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all .6s ease;
  color: #000;
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
}

/* 
===========================
Workshow case
===========================
 */
.rts-work-area .team-inner-two .acquaintance-area {
  box-shadow: 0 3px 15px rgba(0, 0, 0, .1);
  border-radius: 20px 20px 20px 0;
  text-align: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 20%);
  width: 75%;
  z-index: 99;
  padding: 13px 35px;
}

.rts-work-area .team-inner-two .acquaintance-area .header {
  padding: 0px;
  border-bottom: none;
}
.view-more-work{
  background-color: #0158c9;
  width: 100px;
  color: #fff !important;
  text-align: right;
  padding: 8px 9px;
  border-radius: 5px;
}


/* .rts-tab-style-one .tab-content {
  height: 390px !important;
} */
@media only screen and (max-width: 1400px) {

  /* Our Trusted Clients */
  .title-area-client::before {
    position: absolute;
    left: 64%;
  }

  .title-area-client::after {
    position: absolute;
    left: -2%;
  }

  /*End Our Trusted Clients */

  /* About page */
  .global-business-pic p.xl-business-text span:nth-child(1) {
    font-size: 16px;
  }

  .global-business-pic p.xl-business-text span:last-child {
    font-size: 28px;
  }

  .global-business-pic p.xl-business-text span:nth-last-of-type(3) {
    font-size: 18px;
  }
  /* home page */
  .about-founder-wrapper .author-inner::after {
    position: absolute;
    content: "";
    right: 3%;
  }
}

@media only screen and (max-width: 1200px) {
  .rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner{
    margin-left: 10px;
  }
  /* .thumbnail img {
    margin-top: 20px;
  } */

  .rts-section-gap {
    padding: 50px 0;
  }

  /* Our Trusted Clients */
  .title-area-client::before {
    position: absolute;
    left: 66%;
  }

  .title-area-client::after {
    position: absolute;
    left: -4%;
  }

  /*End Our Trusted Clients */
  /* About page */
  /* FEEDBACKS  */
  .testimopnial-wrapper-two .test-body p {
    height: 198px;
  }

  /*FEEDBACKS  */
}

@media only screen and (max-width: 992px) {

  /* about page */
  .global-business-pic p.xl-business-text span:nth-child(1) {
    font-size: 20px;
  }

  .global-business-pic p.xl-business-text span:last-child {
    font-size: 32px;
  }

  .global-business-pic p.xl-business-text span:nth-last-of-type(3) {
    font-size: 20px;
  }

  /* FEEDBACKS  */
  .testimopnial-wrapper-two .test-body p {
    height: auto !important;
  }

  /*FEEDBACKS  */
  /* End About */
  .single-counter.four {
    padding-left: 73px !important;
  }


  /* Our Testimonials  */
  .rts-contact-area.contact-one::after {
    position: absolute;
    content: "";
    background-image: url(./pages/images/contact/shape/01.png);
    right: 10%;
    top: -6%;
  }

  /*End Our Testimonials  */

  /* Our Trusted Clients */
  .title-area-client::before {
    position: absolute;
    left: 72%;
  }

  .title-area-client::after {
    position: absolute;
    left: -0%;
  }

  /*End Our Trusted Clients */

  /* counter */
  .single-counter .counter-details .title span::after {
    right: -24px;
  }

  .single-counter .counter-details .title.rate span::after {
    right: -32px;
  }

  /* End counter */

  .flex-direction-column-reverse{
    flex-direction: column-reverse;
  }
  .display-none{
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  p.disc {
    font-size: 16px;
    line-height: 26px;
    text-align: left !important;
  }

  .bg-about-sm-shape::before {
    display: none;
  }

  .banner-one-inner .title span {
    font-size: 46px;
  }

  .single-counter.three {
    justify-content: left !important;
  }

  .single-counter.four {
    padding-left: 30px !important;
  }

  /* Our Expertise */
  .service-one-inner {
    padding: 42px 18px 25px 0px;
  }

  /* end Our Expertise */

  /* Our Trusted Clients */
  .title-area-client::before {
    position: absolute;
    left: 79%;
  }

  .title-area-client::after {
    position: absolute;
    left: -9%;
  }

  /*End Our Trusted Clients */

  /* counter */
  .single-counter .counter-details .title span::after {
    right: -18px;
  }

  .single-counter .counter-details .title.rate span::after {
    right: -25px;
  }

  /* End counter */
  /* testimonal */
  .swiper.mygallery .swiper-button-next{
    right: 0px;
  }
  .swiper.mygallery .swiper-pagination {
    right: 41px;
}
.swiper.mygallery .swiper-button-prev{
  right: 92px;
}
  /* End testimonal */
}

@media only screen and (max-width: 575px) {
  .single-counter.four {
    padding-left: 0px !important;
  }

  .accordion-service-inner .accordion-area .accordion .accordion-item::before {
    display: none;
  }

  .mySwipertestimonial .swiper-button-prev {
    left: 80%;
  }
}

@media only screen and (max-width: 479px) {

  /* About page */
  .global-business-pic {
    width: 350px;
    overflow: hidden;
  }

  .global-business-pic p.xl-business-text span:nth-child(1) {
    font-size: 14px;
  }

  .global-business-pic p.xl-business-text span:last-child {
    font-size: 16px;
  }

  .global-business-pic p.xl-business-text span:nth-last-of-type(3) {
    font-size: 12px;
  }

  .global-business-pic p.xl-business-text span:nth-last-of-type(2) {
    font-size: 20px;
  }

  /* End About page */
  .thumbnail img {
    margin-top: 0px;
  }

  .banner-one-inner {

    padding: 100px 0 100px 0px;
  }

  .banner-one-inner .title span {
    font-size: 36px;
  }

  .rts-banner-one .swiper-wrapper .swiper-slide .banner-one-inner .shape-img.one {
    height: 70px;
    width: 70px;
  }

  /* Our Testimonials  */
  .rts-contact-area.contact-one::after {
    display: none;
  }

  /*End Our Testimonials  */
  .rts-business-goal .single-goal img {
    margin-right: 15px;
  }

  .mySwipertestimonial .swiper-button-prev {
    left: 74%;
  }

  .rts-title-area.business::after {
    left: 50%;
  }

}

@media only screen and (max-width: 350px) {

  /* About page */
  .global-business-pic {
    margin-left: -18px;
  }

  .mySwipertestimonial .swiper-button-prev,
  .mySwipertestimonial .swiper-button-next {
    display: none;
  }

  .rts-test-one-image-inner::after {
    background-image: none;
  }
}

.cursor-auto {
  cursor:auto;
}
.swiper-backface-hidden .swiper-slide {
  transform: none !important;
}